import React, { useEffect, useState } from 'react'
import * as Api from 'types/api'
import * as Store from 'types/store'
import * as api from 'api/assets'
import ModelField from 'components/StrategyViewer/forms/AssetForm/ModelField'
import { jsonToModelClass } from 'services/store/mapService'
import { FormInstance } from 'antd/lib/form'
import { isApiError } from 'core'

const getModelClasses = async (): Promise<Store.ModelClass[]> => {
  const response = await api.getModelClasses()
  if (isApiError(response)) {
    return null
  }

  const masterSecurityResponse = response as Api.ModelClassResponse

  return jsonToModelClass(masterSecurityResponse.data.application_model_class)
}

interface Props {
  form: FormInstance
  model: number
}

const ModelFieldContainer: React.FC<Props> = ({ form, model }) => {
  const [modelClasses, setModelClasses] = useState<Store.ModelClass[]>([])
  const [modelClass, setModelClass] = useState<Store.ModelClass>(null)

  const onSelect = (id: number, modelClasses: Store.ModelClass[]) => {
    if (id) {
      const selected = modelClasses.find((item) => item.id === id)
      setModelClass(selected)
      form.setFieldsValue({ ...selected, model: id })
    } else {
      form.setFieldsValue({
        model: null,
        modelType: null,
        version: null,
        modelName: null,
        modelLanguage: null,
        modelDescription: null,
      })
      setModelClass(null)
    }
  }

  useEffect(() => {
    setModelClass({} as Store.ModelClass)
    const getData = async () => {
      const modelClasses = await getModelClasses()
      setModelClasses(modelClasses)
      onSelect(model, modelClasses)
    }
    getData()
  }, [model])

  return (
    <ModelField
      data={modelClasses}
      modelClass={modelClass}
      onSelect={(id: number) => onSelect(id, modelClasses)}
    />
  )
}

export default ModelFieldContainer
