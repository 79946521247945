import React from 'react'
import { ExtendedDataNode, NodeType, TransferType } from 'types/ui'
import AddStrategies from './items/AddStrategies'
import AddStrategy from './items/AddStrategy'
import AddPortfolio from './items/AddPortfolio'
import RemovePortfolio from './items/RemovePortfolio'
import RemoveStrategy from './items/RemoveStrategy'
import { TransferContext } from 'types/enums'
import AddAsset from './items/AddAsset'
import RemoveAll from './items/RemoveAll'
import ReadItem from './items/ReadItem'

interface Props {
  node: ExtendedDataNode
  type: TransferType
  context: TransferContext
  onClick: (node: ExtendedDataNode, type: TransferType) => void
}

const ContextItem: React.FC<Props> = ({ node, type, onClick, context }) => {
  const UndefinedItem = <span>{node.title}</span>

  const getItemAdd = () => {
    switch (node.type) {
      case NodeType.AllStrategies:
        return <AddStrategies node={node} onClick={onClick} context={context} />
      case NodeType.Strategy:
        return <AddStrategy node={node} onClick={onClick} context={context} />
      case NodeType.Portfolio:
        return <AddPortfolio node={node} onClick={onClick} context={context} />
      case NodeType.Asset:
        return <AddAsset node={node} onClick={onClick} context={context} />
      default:
        return UndefinedItem
    }
  }

  const getItemRemove = () => {
    switch (node.type) {
      case NodeType.AllStrategies:
        return <RemoveAll node={node} onClick={onClick} context={context} />
      case NodeType.Strategy:
        return <RemoveStrategy node={node} onClick={onClick} context={context} />
      case NodeType.Portfolio:
        return <RemovePortfolio node={node} onClick={onClick} context={context} />
      case NodeType.Asset:
        return <AddAsset node={node} onClick={onClick} context={context} />
      default:
        return UndefinedItem
    }
  }
  if (type === TransferType.Disabled) {
    return <ReadItem node={node} />
  }
  return type === TransferType.Add ? getItemAdd() : getItemRemove()
}

export default ContextItem
