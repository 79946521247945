import * as K from './constants'
import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import Debug from 'debug'
import * as Api from 'types/api'
import * as api from 'api/portfolios'
import { CrudOperation, ErrorCode, LoadingState } from 'types/enums'
import { ApplicationError, isApiError } from 'core'
import * as Store from 'types/store'
import { setUserAction } from 'store/pages/strategy/actions'
import { ExtendedDataNode, NodeType } from 'types/ui'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

export const getPortfolios = (state) => state.root.resources.portfolios

export function* createPortfolio(action: AnyAction) {
  debug('create Portfolio')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Creating })
    yield put({ type: K.SET_ERROR, payload: null })
    const postData = action.payload as Api.PortfolioRest

    const response = yield call(api.createPortfolio, postData)

    if (isApiError(response as ApiError)) {
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'create strategy failed')
    }

    const { content } = response as Api.PortfolioCreateResponse

    const portfolio: Store.Portfolio = {
      ...postData,
      id: content.portfolioId,
      creationDate: null,
      creationUserId: null,
      lastModDate: null,
    }

    //Put new portfolio in the store
    yield put({ type: K.PORTFOLIO_CREATE_SUCCESS, payload: portfolio })

    //New portfolio just created: select it
    const node: ExtendedDataNode = {
      key: 'new portfolio',
      resource: portfolio,
      type: NodeType.Portfolio,
      isDeletable: true,
    }
    yield put(setUserAction(node, CrudOperation.Read))

    yield put({ type: K.SET_STATUS, payload: LoadingState.Created })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.CreateFailure })
    debug('create Strategy error', error)
  }
}

export function* deletePortfolio(action: AnyAction) {
  debug('Delete Portfolio')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleting })
    yield put({ type: K.SET_ERROR, payload: null })
    const id = action.payload as number

    const response = yield call(api.deletePortfolio, id)

    if (isApiError(response as ApiError)) {
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'delete PORTFOLIO_DELETE failed')
    }

    yield put({ type: K.PORTFOLIO_DELETE_SUCCESS, payload: id })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Deleted })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.DeleteFailed })
    debug('delete PORTFOLIO_DELETE error', error)
  }
}

export function* updatePortfolio(action: AnyAction) {
  debug('Update Portfolio')
  try {
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updating })
    yield put({ type: K.SET_ERROR, payload: null })
    const postData = action.payload as Api.PortfolioRest

    const response = yield call(api.updatePortfolio, postData.id, postData)

    if (isApiError(response as ApiError)) {
      yield put({ type: K.SET_ERROR, payload: (response.data as Api.RESTResponse)?.result?.responseEnum })
      throw new ApplicationError(ErrorCode.Api, 'updatePortfolio failed')
    }

    const portfolios: Store.PortfolioState = yield select(getPortfolios)
    const portfolio = portfolios.data.find((item) => item.id === postData.id)
    const updated = {
      ...portfolio,
      ...{
        maxLong: postData.maxLong,
        maxNetHedging: postData.maxNetHedging,
        minNetHedging: postData.minNetHedging,
        maxShort: postData.maxShort,
        name: postData.name,
        strategyId: postData.strategyId,
      },
    }

    yield put({ type: K.PORTFOLIO_UPDATE_SUCCESS, payload: updated })
    yield put({ type: K.SET_STATUS, payload: LoadingState.Updated })
  } catch (error) {
    yield put({ type: K.SET_STATUS, payload: LoadingState.UpdateFailure })
    debug('updatePortfolio error', error)
  }
}

function* watch() {
  yield all([
    takeLatest(K.PORTFOLIO_UPDATE, updatePortfolio),
    takeLatest(K.PORTFOLIO_CREATE, createPortfolio),
    takeLatest(K.PORTFOLIO_DELETE, deletePortfolio),
  ])
}

export default watch
