import React, { useState } from 'react'
import * as api from 'api/assets'
import * as Api from 'types/api'
import SymbolField from 'components/StrategyViewer/forms/AssetForm/SymbolField'
import { jsonToMasterSecurity } from 'services/store/mapService'
import { isApiError } from 'core'
import * as Store from 'types/store'
import { FormInstance } from 'antd'
import { CrudOperation } from 'types/enums'

const getMasterSecurities = async (symbol: string) => {
  const response = await api.getSymbols(symbol)
  if (isApiError(response)) {
    return null
  }

  const masterSecurityResponse = response as Api.MasterSecurityResponse

  return masterSecurityResponse.data.application_master_security
}

interface Props {
  form: FormInstance
  mode: CrudOperation
}

const SymbolFieldContainer: React.FC<Props> = ({ form, mode }) => {
  const [masterSecurities, setMasterSecurities] = useState<Store.MasterSecurity[]>([])

  const filterMasterSecurities = async (symbol: string) => {
    const masterSecurities = await getMasterSecurities(symbol)
    if (masterSecurities) {
      setMasterSecurities(jsonToMasterSecurity(masterSecurities))
    }
  }

  const onSymbolChange = () => {
    form.resetFields(['mic', 'market', 'currency', 'bankAccount', 'broker'])
  }

  return (
    <SymbolField
      data={masterSecurities}
      filter={filterMasterSecurities}
      onSymbolChange={onSymbolChange}
      mode={mode}
    />
  )
}

export default SymbolFieldContainer
