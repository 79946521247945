// source: portfolio_return.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.portfolios.return.alive', null, global);
goog.exportSymbol('proto.portfolios.return.decimal', null, global);
goog.exportSymbol('proto.portfolios.return.end_snapshot', null, global);
goog.exportSymbol('proto.portfolios.return.feed_event', null, global);
goog.exportSymbol('proto.portfolios.return.feed_event.EventTypeCase', null, global);
goog.exportSymbol('proto.portfolios.return.prt_all_ret_bc', null, global);
goog.exportSymbol('proto.portfolios.return.prt_all_ret_bc_end', null, global);
goog.exportSymbol('proto.portfolios.return.prt_closed_candle', null, global);
goog.exportSymbol('proto.portfolios.return.prt_exch_ret_bc', null, global);
goog.exportSymbol('proto.portfolios.return.prt_open_candle', null, global);
goog.exportSymbol('proto.portfolios.return.prt_trad_ret_bc', null, global);
goog.exportSymbol('proto.portfolios.return.prt_trad_ret_bc_end', null, global);
goog.exportSymbol('proto.portfolios.return.svc_status', null, global);
goog.exportSymbol('proto.portfolios.return.tstamp', null, global);
goog.exportSymbol('proto.portfolios.return.update_payload', null, global);
goog.exportSymbol('proto.portfolios.return.update_snapshot', null, global);
goog.exportSymbol('proto.portfolios.return.update_ticker', null, global);
goog.exportSymbol('proto.portfolios.return.update_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.tstamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.tstamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.tstamp.displayName = 'proto.portfolios.return.tstamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.decimal.displayName = 'proto.portfolios.return.decimal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.prt_trad_ret_bc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.prt_trad_ret_bc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.prt_trad_ret_bc.displayName = 'proto.portfolios.return.prt_trad_ret_bc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.prt_all_ret_bc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.prt_all_ret_bc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.prt_all_ret_bc.displayName = 'proto.portfolios.return.prt_all_ret_bc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.prt_exch_ret_bc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.prt_exch_ret_bc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.prt_exch_ret_bc.displayName = 'proto.portfolios.return.prt_exch_ret_bc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.prt_open_candle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.prt_open_candle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.prt_open_candle.displayName = 'proto.portfolios.return.prt_open_candle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.prt_closed_candle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.prt_closed_candle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.prt_closed_candle.displayName = 'proto.portfolios.return.prt_closed_candle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.prt_trad_ret_bc_end = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.prt_trad_ret_bc_end, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.prt_trad_ret_bc_end.displayName = 'proto.portfolios.return.prt_trad_ret_bc_end';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.prt_all_ret_bc_end = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.prt_all_ret_bc_end, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.prt_all_ret_bc_end.displayName = 'proto.portfolios.return.prt_all_ret_bc_end';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.update_payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.portfolios.return.update_payload.repeatedFields_, null);
};
goog.inherits(proto.portfolios.return.update_payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.update_payload.displayName = 'proto.portfolios.return.update_payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.update_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.update_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.update_snapshot.displayName = 'proto.portfolios.return.update_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.end_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.end_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.end_snapshot.displayName = 'proto.portfolios.return.end_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.update_ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.update_ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.update_ticker.displayName = 'proto.portfolios.return.update_ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.portfolios.return.alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.alive.displayName = 'proto.portfolios.return.alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.portfolios.return.feed_event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.portfolios.return.feed_event.oneofGroups_);
};
goog.inherits(proto.portfolios.return.feed_event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.portfolios.return.feed_event.displayName = 'proto.portfolios.return.feed_event';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.tstamp.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.tstamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.tstamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.tstamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    offset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.tstamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.tstamp;
  return proto.portfolios.return.tstamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.tstamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.tstamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.tstamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.tstamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.tstamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.tstamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * required sint64 time = 1;
 * @return {number}
 */
proto.portfolios.return.tstamp.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.tstamp} returns this
 */
proto.portfolios.return.tstamp.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.tstamp} returns this
 */
proto.portfolios.return.tstamp.prototype.clearTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.tstamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 offset = 2;
 * @return {number}
 */
proto.portfolios.return.tstamp.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.tstamp} returns this
 */
proto.portfolios.return.tstamp.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.tstamp} returns this
 */
proto.portfolios.return.tstamp.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.tstamp.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required uint32 code = 3;
 * @return {number}
 */
proto.portfolios.return.tstamp.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.tstamp} returns this
 */
proto.portfolios.return.tstamp.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.tstamp} returns this
 */
proto.portfolios.return.tstamp.prototype.clearCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.tstamp.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
    coeff: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.decimal}
 */
proto.portfolios.return.decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.decimal;
  return proto.portfolios.return.decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.decimal}
 */
proto.portfolios.return.decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCoeff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * required sint64 coeff = 1;
 * @return {number}
 */
proto.portfolios.return.decimal.prototype.getCoeff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.decimal} returns this
 */
proto.portfolios.return.decimal.prototype.setCoeff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.decimal} returns this
 */
proto.portfolios.return.decimal.prototype.clearCoeff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.decimal.prototype.hasCoeff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 exp = 2;
 * @return {number}
 */
proto.portfolios.return.decimal.prototype.getExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.decimal} returns this
 */
proto.portfolios.return.decimal.prototype.setExp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.decimal} returns this
 */
proto.portfolios.return.decimal.prototype.clearExp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.decimal.prototype.hasExp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.prt_trad_ret_bc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.prt_trad_ret_bc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_trad_ret_bc.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    tradingRetBc: (f = msg.getTradingRetBc()) && proto.portfolios.return.decimal.toObject(includeInstance, f),
    lastEvtTs: (f = msg.getLastEvtTs()) && proto.portfolios.return.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.prt_trad_ret_bc}
 */
proto.portfolios.return.prt_trad_ret_bc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.prt_trad_ret_bc;
  return proto.portfolios.return.prt_trad_ret_bc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.prt_trad_ret_bc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.prt_trad_ret_bc}
 */
proto.portfolios.return.prt_trad_ret_bc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setTradingRetBc(value);
      break;
    case 3:
      var value = new proto.portfolios.return.tstamp;
      reader.readMessage(value,proto.portfolios.return.tstamp.deserializeBinaryFromReader);
      msg.setLastEvtTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.prt_trad_ret_bc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.prt_trad_ret_bc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_trad_ret_bc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getLastEvtTs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.portfolios.return.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.return.prt_trad_ret_bc} returns this
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_trad_ret_bc} returns this
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal trading_ret_bc = 2;
 * @return {?proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.getTradingRetBc = function() {
  return /** @type{?proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 2));
};


/**
 * @param {?proto.portfolios.return.decimal|undefined} value
 * @return {!proto.portfolios.return.prt_trad_ret_bc} returns this
*/
proto.portfolios.return.prt_trad_ret_bc.prototype.setTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.prt_trad_ret_bc} returns this
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.clearTradingRetBc = function() {
  return this.setTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.hasTradingRetBc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required tstamp last_evt_ts = 3;
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.getLastEvtTs = function() {
  return /** @type{!proto.portfolios.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.tstamp, 3, 1));
};


/**
 * @param {!proto.portfolios.return.tstamp} value
 * @return {!proto.portfolios.return.prt_trad_ret_bc} returns this
*/
proto.portfolios.return.prt_trad_ret_bc.prototype.setLastEvtTs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_trad_ret_bc} returns this
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.clearLastEvtTs = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_trad_ret_bc.prototype.hasLastEvtTs = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.prt_all_ret_bc.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.prt_all_ret_bc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.prt_all_ret_bc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_all_ret_bc.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    allRetBc: (f = msg.getAllRetBc()) && proto.portfolios.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.prt_all_ret_bc}
 */
proto.portfolios.return.prt_all_ret_bc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.prt_all_ret_bc;
  return proto.portfolios.return.prt_all_ret_bc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.prt_all_ret_bc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.prt_all_ret_bc}
 */
proto.portfolios.return.prt_all_ret_bc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setAllRetBc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.prt_all_ret_bc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.prt_all_ret_bc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.prt_all_ret_bc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_all_ret_bc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllRetBc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.portfolios.return.prt_all_ret_bc.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.return.prt_all_ret_bc} returns this
 */
proto.portfolios.return.prt_all_ret_bc.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_all_ret_bc} returns this
 */
proto.portfolios.return.prt_all_ret_bc.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_all_ret_bc.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal all_ret_bc = 2;
 * @return {?proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_all_ret_bc.prototype.getAllRetBc = function() {
  return /** @type{?proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 2));
};


/**
 * @param {?proto.portfolios.return.decimal|undefined} value
 * @return {!proto.portfolios.return.prt_all_ret_bc} returns this
*/
proto.portfolios.return.prt_all_ret_bc.prototype.setAllRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.prt_all_ret_bc} returns this
 */
proto.portfolios.return.prt_all_ret_bc.prototype.clearAllRetBc = function() {
  return this.setAllRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_all_ret_bc.prototype.hasAllRetBc = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.prt_exch_ret_bc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.prt_exch_ret_bc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_exch_ret_bc.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exchRetBc: (f = msg.getExchRetBc()) && proto.portfolios.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.prt_exch_ret_bc}
 */
proto.portfolios.return.prt_exch_ret_bc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.prt_exch_ret_bc;
  return proto.portfolios.return.prt_exch_ret_bc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.prt_exch_ret_bc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.prt_exch_ret_bc}
 */
proto.portfolios.return.prt_exch_ret_bc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setExchRetBc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.prt_exch_ret_bc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.prt_exch_ret_bc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_exch_ret_bc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExchRetBc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.return.prt_exch_ret_bc} returns this
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_exch_ret_bc} returns this
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal exch_ret_bc = 2;
 * @return {?proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.getExchRetBc = function() {
  return /** @type{?proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 2));
};


/**
 * @param {?proto.portfolios.return.decimal|undefined} value
 * @return {!proto.portfolios.return.prt_exch_ret_bc} returns this
*/
proto.portfolios.return.prt_exch_ret_bc.prototype.setExchRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.prt_exch_ret_bc} returns this
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.clearExchRetBc = function() {
  return this.setExchRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_exch_ret_bc.prototype.hasExchRetBc = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.prt_open_candle.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.prt_open_candle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.prt_open_candle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_open_candle.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTs: (f = msg.getStartTs()) && proto.portfolios.return.tstamp.toObject(includeInstance, f),
    maxTdyTradingRetBc: (f = msg.getMaxTdyTradingRetBc()) && proto.portfolios.return.decimal.toObject(includeInstance, f),
    minTdyTradingRetBc: (f = msg.getMinTdyTradingRetBc()) && proto.portfolios.return.decimal.toObject(includeInstance, f),
    endTs: (f = msg.getEndTs()) && proto.portfolios.return.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.prt_open_candle}
 */
proto.portfolios.return.prt_open_candle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.prt_open_candle;
  return proto.portfolios.return.prt_open_candle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.prt_open_candle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.prt_open_candle}
 */
proto.portfolios.return.prt_open_candle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.portfolios.return.tstamp;
      reader.readMessage(value,proto.portfolios.return.tstamp.deserializeBinaryFromReader);
      msg.setStartTs(value);
      break;
    case 2:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setMaxTdyTradingRetBc(value);
      break;
    case 3:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setMinTdyTradingRetBc(value);
      break;
    case 4:
      var value = new proto.portfolios.return.tstamp;
      reader.readMessage(value,proto.portfolios.return.tstamp.deserializeBinaryFromReader);
      msg.setEndTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.prt_open_candle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.prt_open_candle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.prt_open_candle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_open_candle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.portfolios.return.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getMaxTdyTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getMinTdyTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getEndTs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.portfolios.return.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required tstamp start_ts = 1;
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.prt_open_candle.prototype.getStartTs = function() {
  return /** @type{!proto.portfolios.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.tstamp, 1, 1));
};


/**
 * @param {!proto.portfolios.return.tstamp} value
 * @return {!proto.portfolios.return.prt_open_candle} returns this
*/
proto.portfolios.return.prt_open_candle.prototype.setStartTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_open_candle} returns this
 */
proto.portfolios.return.prt_open_candle.prototype.clearStartTs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_open_candle.prototype.hasStartTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required decimal max_tdy_trading_ret_bc = 2;
 * @return {!proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_open_candle.prototype.getMaxTdyTradingRetBc = function() {
  return /** @type{!proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 2, 1));
};


/**
 * @param {!proto.portfolios.return.decimal} value
 * @return {!proto.portfolios.return.prt_open_candle} returns this
*/
proto.portfolios.return.prt_open_candle.prototype.setMaxTdyTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_open_candle} returns this
 */
proto.portfolios.return.prt_open_candle.prototype.clearMaxTdyTradingRetBc = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_open_candle.prototype.hasMaxTdyTradingRetBc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal min_tdy_trading_ret_bc = 3;
 * @return {!proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_open_candle.prototype.getMinTdyTradingRetBc = function() {
  return /** @type{!proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 3, 1));
};


/**
 * @param {!proto.portfolios.return.decimal} value
 * @return {!proto.portfolios.return.prt_open_candle} returns this
*/
proto.portfolios.return.prt_open_candle.prototype.setMinTdyTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_open_candle} returns this
 */
proto.portfolios.return.prt_open_candle.prototype.clearMinTdyTradingRetBc = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_open_candle.prototype.hasMinTdyTradingRetBc = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required tstamp end_ts = 4;
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.prt_open_candle.prototype.getEndTs = function() {
  return /** @type{!proto.portfolios.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.tstamp, 4, 1));
};


/**
 * @param {!proto.portfolios.return.tstamp} value
 * @return {!proto.portfolios.return.prt_open_candle} returns this
*/
proto.portfolios.return.prt_open_candle.prototype.setEndTs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_open_candle} returns this
 */
proto.portfolios.return.prt_open_candle.prototype.clearEndTs = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_open_candle.prototype.hasEndTs = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.prt_closed_candle.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.prt_closed_candle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.prt_closed_candle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_closed_candle.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTs: (f = msg.getStartTs()) && proto.portfolios.return.tstamp.toObject(includeInstance, f),
    maxTdyTradingRetBc: (f = msg.getMaxTdyTradingRetBc()) && proto.portfolios.return.decimal.toObject(includeInstance, f),
    minTdyTradingRetBc: (f = msg.getMinTdyTradingRetBc()) && proto.portfolios.return.decimal.toObject(includeInstance, f),
    endTs: (f = msg.getEndTs()) && proto.portfolios.return.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.prt_closed_candle}
 */
proto.portfolios.return.prt_closed_candle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.prt_closed_candle;
  return proto.portfolios.return.prt_closed_candle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.prt_closed_candle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.prt_closed_candle}
 */
proto.portfolios.return.prt_closed_candle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.portfolios.return.tstamp;
      reader.readMessage(value,proto.portfolios.return.tstamp.deserializeBinaryFromReader);
      msg.setStartTs(value);
      break;
    case 2:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setMaxTdyTradingRetBc(value);
      break;
    case 3:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setMinTdyTradingRetBc(value);
      break;
    case 4:
      var value = new proto.portfolios.return.tstamp;
      reader.readMessage(value,proto.portfolios.return.tstamp.deserializeBinaryFromReader);
      msg.setEndTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.prt_closed_candle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.prt_closed_candle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.prt_closed_candle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_closed_candle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.portfolios.return.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getMaxTdyTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getMinTdyTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getEndTs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.portfolios.return.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required tstamp start_ts = 1;
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.prt_closed_candle.prototype.getStartTs = function() {
  return /** @type{!proto.portfolios.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.tstamp, 1, 1));
};


/**
 * @param {!proto.portfolios.return.tstamp} value
 * @return {!proto.portfolios.return.prt_closed_candle} returns this
*/
proto.portfolios.return.prt_closed_candle.prototype.setStartTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_closed_candle} returns this
 */
proto.portfolios.return.prt_closed_candle.prototype.clearStartTs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_closed_candle.prototype.hasStartTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required decimal max_tdy_trading_ret_bc = 2;
 * @return {!proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_closed_candle.prototype.getMaxTdyTradingRetBc = function() {
  return /** @type{!proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 2, 1));
};


/**
 * @param {!proto.portfolios.return.decimal} value
 * @return {!proto.portfolios.return.prt_closed_candle} returns this
*/
proto.portfolios.return.prt_closed_candle.prototype.setMaxTdyTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_closed_candle} returns this
 */
proto.portfolios.return.prt_closed_candle.prototype.clearMaxTdyTradingRetBc = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_closed_candle.prototype.hasMaxTdyTradingRetBc = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal min_tdy_trading_ret_bc = 3;
 * @return {!proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_closed_candle.prototype.getMinTdyTradingRetBc = function() {
  return /** @type{!proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 3, 1));
};


/**
 * @param {!proto.portfolios.return.decimal} value
 * @return {!proto.portfolios.return.prt_closed_candle} returns this
*/
proto.portfolios.return.prt_closed_candle.prototype.setMinTdyTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_closed_candle} returns this
 */
proto.portfolios.return.prt_closed_candle.prototype.clearMinTdyTradingRetBc = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_closed_candle.prototype.hasMinTdyTradingRetBc = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required tstamp end_ts = 4;
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.prt_closed_candle.prototype.getEndTs = function() {
  return /** @type{!proto.portfolios.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.tstamp, 4, 1));
};


/**
 * @param {!proto.portfolios.return.tstamp} value
 * @return {!proto.portfolios.return.prt_closed_candle} returns this
*/
proto.portfolios.return.prt_closed_candle.prototype.setEndTs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.prt_closed_candle} returns this
 */
proto.portfolios.return.prt_closed_candle.prototype.clearEndTs = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_closed_candle.prototype.hasEndTs = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.prt_trad_ret_bc_end.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.prt_trad_ret_bc_end.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.prt_trad_ret_bc_end} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_trad_ret_bc_end.toObject = function(includeInstance, msg) {
  var f, obj = {
    ytdTradingRetBcEnd: (f = msg.getYtdTradingRetBcEnd()) && proto.portfolios.return.decimal.toObject(includeInstance, f),
    totTradingRetBcEnd: (f = msg.getTotTradingRetBcEnd()) && proto.portfolios.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.prt_trad_ret_bc_end}
 */
proto.portfolios.return.prt_trad_ret_bc_end.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.prt_trad_ret_bc_end;
  return proto.portfolios.return.prt_trad_ret_bc_end.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.prt_trad_ret_bc_end} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.prt_trad_ret_bc_end}
 */
proto.portfolios.return.prt_trad_ret_bc_end.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setYtdTradingRetBcEnd(value);
      break;
    case 2:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setTotTradingRetBcEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.prt_trad_ret_bc_end.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.prt_trad_ret_bc_end.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.prt_trad_ret_bc_end} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_trad_ret_bc_end.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYtdTradingRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTotTradingRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal ytd_trading_ret_bc_end = 1;
 * @return {?proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_trad_ret_bc_end.prototype.getYtdTradingRetBcEnd = function() {
  return /** @type{?proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 1));
};


/**
 * @param {?proto.portfolios.return.decimal|undefined} value
 * @return {!proto.portfolios.return.prt_trad_ret_bc_end} returns this
*/
proto.portfolios.return.prt_trad_ret_bc_end.prototype.setYtdTradingRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.prt_trad_ret_bc_end} returns this
 */
proto.portfolios.return.prt_trad_ret_bc_end.prototype.clearYtdTradingRetBcEnd = function() {
  return this.setYtdTradingRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_trad_ret_bc_end.prototype.hasYtdTradingRetBcEnd = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal tot_trading_ret_bc_end = 2;
 * @return {?proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_trad_ret_bc_end.prototype.getTotTradingRetBcEnd = function() {
  return /** @type{?proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 2));
};


/**
 * @param {?proto.portfolios.return.decimal|undefined} value
 * @return {!proto.portfolios.return.prt_trad_ret_bc_end} returns this
*/
proto.portfolios.return.prt_trad_ret_bc_end.prototype.setTotTradingRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.prt_trad_ret_bc_end} returns this
 */
proto.portfolios.return.prt_trad_ret_bc_end.prototype.clearTotTradingRetBcEnd = function() {
  return this.setTotTradingRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_trad_ret_bc_end.prototype.hasTotTradingRetBcEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.prt_all_ret_bc_end.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.prt_all_ret_bc_end.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.prt_all_ret_bc_end} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_all_ret_bc_end.toObject = function(includeInstance, msg) {
  var f, obj = {
    ytdAllRetBcEnd: (f = msg.getYtdAllRetBcEnd()) && proto.portfolios.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.prt_all_ret_bc_end}
 */
proto.portfolios.return.prt_all_ret_bc_end.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.prt_all_ret_bc_end;
  return proto.portfolios.return.prt_all_ret_bc_end.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.prt_all_ret_bc_end} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.prt_all_ret_bc_end}
 */
proto.portfolios.return.prt_all_ret_bc_end.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.portfolios.return.decimal;
      reader.readMessage(value,proto.portfolios.return.decimal.deserializeBinaryFromReader);
      msg.setYtdAllRetBcEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.prt_all_ret_bc_end.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.prt_all_ret_bc_end.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.prt_all_ret_bc_end} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.prt_all_ret_bc_end.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYtdAllRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.portfolios.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal ytd_all_ret_bc_end = 1;
 * @return {?proto.portfolios.return.decimal}
 */
proto.portfolios.return.prt_all_ret_bc_end.prototype.getYtdAllRetBcEnd = function() {
  return /** @type{?proto.portfolios.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.decimal, 1));
};


/**
 * @param {?proto.portfolios.return.decimal|undefined} value
 * @return {!proto.portfolios.return.prt_all_ret_bc_end} returns this
*/
proto.portfolios.return.prt_all_ret_bc_end.prototype.setYtdAllRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.prt_all_ret_bc_end} returns this
 */
proto.portfolios.return.prt_all_ret_bc_end.prototype.clearYtdAllRetBcEnd = function() {
  return this.setYtdAllRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.prt_all_ret_bc_end.prototype.hasYtdAllRetBcEnd = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.portfolios.return.update_payload.repeatedFields_ = [49,50,51];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.update_payload.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.update_payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.update_payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.update_payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    prtTradRetBcList: jspb.Message.toObjectList(msg.getPrtTradRetBcList(),
    proto.portfolios.return.prt_trad_ret_bc.toObject, includeInstance),
    prtAllRetBcList: jspb.Message.toObjectList(msg.getPrtAllRetBcList(),
    proto.portfolios.return.prt_all_ret_bc.toObject, includeInstance),
    prtExchRetBcList: jspb.Message.toObjectList(msg.getPrtExchRetBcList(),
    proto.portfolios.return.prt_exch_ret_bc.toObject, includeInstance),
    prtOpenCandle: (f = msg.getPrtOpenCandle()) && proto.portfolios.return.prt_open_candle.toObject(includeInstance, f),
    prtClosedCandle: (f = msg.getPrtClosedCandle()) && proto.portfolios.return.prt_closed_candle.toObject(includeInstance, f),
    prtTradRetBcEnd: (f = msg.getPrtTradRetBcEnd()) && proto.portfolios.return.prt_trad_ret_bc_end.toObject(includeInstance, f),
    prtAllRetBcEnd: (f = msg.getPrtAllRetBcEnd()) && proto.portfolios.return.prt_all_ret_bc_end.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.update_payload}
 */
proto.portfolios.return.update_payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.update_payload;
  return proto.portfolios.return.update_payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.update_payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.update_payload}
 */
proto.portfolios.return.update_payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 49:
      var value = new proto.portfolios.return.prt_trad_ret_bc;
      reader.readMessage(value,proto.portfolios.return.prt_trad_ret_bc.deserializeBinaryFromReader);
      msg.addPrtTradRetBc(value);
      break;
    case 50:
      var value = new proto.portfolios.return.prt_all_ret_bc;
      reader.readMessage(value,proto.portfolios.return.prt_all_ret_bc.deserializeBinaryFromReader);
      msg.addPrtAllRetBc(value);
      break;
    case 51:
      var value = new proto.portfolios.return.prt_exch_ret_bc;
      reader.readMessage(value,proto.portfolios.return.prt_exch_ret_bc.deserializeBinaryFromReader);
      msg.addPrtExchRetBc(value);
      break;
    case 52:
      var value = new proto.portfolios.return.prt_open_candle;
      reader.readMessage(value,proto.portfolios.return.prt_open_candle.deserializeBinaryFromReader);
      msg.setPrtOpenCandle(value);
      break;
    case 53:
      var value = new proto.portfolios.return.prt_closed_candle;
      reader.readMessage(value,proto.portfolios.return.prt_closed_candle.deserializeBinaryFromReader);
      msg.setPrtClosedCandle(value);
      break;
    case 54:
      var value = new proto.portfolios.return.prt_trad_ret_bc_end;
      reader.readMessage(value,proto.portfolios.return.prt_trad_ret_bc_end.deserializeBinaryFromReader);
      msg.setPrtTradRetBcEnd(value);
      break;
    case 55:
      var value = new proto.portfolios.return.prt_all_ret_bc_end;
      reader.readMessage(value,proto.portfolios.return.prt_all_ret_bc_end.deserializeBinaryFromReader);
      msg.setPrtAllRetBcEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.update_payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.update_payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.update_payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.update_payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrtTradRetBcList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      49,
      f,
      proto.portfolios.return.prt_trad_ret_bc.serializeBinaryToWriter
    );
  }
  f = message.getPrtAllRetBcList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      50,
      f,
      proto.portfolios.return.prt_all_ret_bc.serializeBinaryToWriter
    );
  }
  f = message.getPrtExchRetBcList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      51,
      f,
      proto.portfolios.return.prt_exch_ret_bc.serializeBinaryToWriter
    );
  }
  f = message.getPrtOpenCandle();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.portfolios.return.prt_open_candle.serializeBinaryToWriter
    );
  }
  f = message.getPrtClosedCandle();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.portfolios.return.prt_closed_candle.serializeBinaryToWriter
    );
  }
  f = message.getPrtTradRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.portfolios.return.prt_trad_ret_bc_end.serializeBinaryToWriter
    );
  }
  f = message.getPrtAllRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.portfolios.return.prt_all_ret_bc_end.serializeBinaryToWriter
    );
  }
};


/**
 * repeated prt_trad_ret_bc prt_trad_ret_bc_ = 49;
 * @return {!Array<!proto.portfolios.return.prt_trad_ret_bc>}
 */
proto.portfolios.return.update_payload.prototype.getPrtTradRetBcList = function() {
  return /** @type{!Array<!proto.portfolios.return.prt_trad_ret_bc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.portfolios.return.prt_trad_ret_bc, 49));
};


/**
 * @param {!Array<!proto.portfolios.return.prt_trad_ret_bc>} value
 * @return {!proto.portfolios.return.update_payload} returns this
*/
proto.portfolios.return.update_payload.prototype.setPrtTradRetBcList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 49, value);
};


/**
 * @param {!proto.portfolios.return.prt_trad_ret_bc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.portfolios.return.prt_trad_ret_bc}
 */
proto.portfolios.return.update_payload.prototype.addPrtTradRetBc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 49, opt_value, proto.portfolios.return.prt_trad_ret_bc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.portfolios.return.update_payload} returns this
 */
proto.portfolios.return.update_payload.prototype.clearPrtTradRetBcList = function() {
  return this.setPrtTradRetBcList([]);
};


/**
 * repeated prt_all_ret_bc prt_all_ret_bc_ = 50;
 * @return {!Array<!proto.portfolios.return.prt_all_ret_bc>}
 */
proto.portfolios.return.update_payload.prototype.getPrtAllRetBcList = function() {
  return /** @type{!Array<!proto.portfolios.return.prt_all_ret_bc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.portfolios.return.prt_all_ret_bc, 50));
};


/**
 * @param {!Array<!proto.portfolios.return.prt_all_ret_bc>} value
 * @return {!proto.portfolios.return.update_payload} returns this
*/
proto.portfolios.return.update_payload.prototype.setPrtAllRetBcList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 50, value);
};


/**
 * @param {!proto.portfolios.return.prt_all_ret_bc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.portfolios.return.prt_all_ret_bc}
 */
proto.portfolios.return.update_payload.prototype.addPrtAllRetBc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 50, opt_value, proto.portfolios.return.prt_all_ret_bc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.portfolios.return.update_payload} returns this
 */
proto.portfolios.return.update_payload.prototype.clearPrtAllRetBcList = function() {
  return this.setPrtAllRetBcList([]);
};


/**
 * repeated prt_exch_ret_bc prt_exch_ret_bc_ = 51;
 * @return {!Array<!proto.portfolios.return.prt_exch_ret_bc>}
 */
proto.portfolios.return.update_payload.prototype.getPrtExchRetBcList = function() {
  return /** @type{!Array<!proto.portfolios.return.prt_exch_ret_bc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.portfolios.return.prt_exch_ret_bc, 51));
};


/**
 * @param {!Array<!proto.portfolios.return.prt_exch_ret_bc>} value
 * @return {!proto.portfolios.return.update_payload} returns this
*/
proto.portfolios.return.update_payload.prototype.setPrtExchRetBcList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 51, value);
};


/**
 * @param {!proto.portfolios.return.prt_exch_ret_bc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.portfolios.return.prt_exch_ret_bc}
 */
proto.portfolios.return.update_payload.prototype.addPrtExchRetBc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 51, opt_value, proto.portfolios.return.prt_exch_ret_bc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.portfolios.return.update_payload} returns this
 */
proto.portfolios.return.update_payload.prototype.clearPrtExchRetBcList = function() {
  return this.setPrtExchRetBcList([]);
};


/**
 * optional prt_open_candle prt_open_candle_ = 52;
 * @return {?proto.portfolios.return.prt_open_candle}
 */
proto.portfolios.return.update_payload.prototype.getPrtOpenCandle = function() {
  return /** @type{?proto.portfolios.return.prt_open_candle} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.prt_open_candle, 52));
};


/**
 * @param {?proto.portfolios.return.prt_open_candle|undefined} value
 * @return {!proto.portfolios.return.update_payload} returns this
*/
proto.portfolios.return.update_payload.prototype.setPrtOpenCandle = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.update_payload} returns this
 */
proto.portfolios.return.update_payload.prototype.clearPrtOpenCandle = function() {
  return this.setPrtOpenCandle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_payload.prototype.hasPrtOpenCandle = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional prt_closed_candle prt_closed_candle_ = 53;
 * @return {?proto.portfolios.return.prt_closed_candle}
 */
proto.portfolios.return.update_payload.prototype.getPrtClosedCandle = function() {
  return /** @type{?proto.portfolios.return.prt_closed_candle} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.prt_closed_candle, 53));
};


/**
 * @param {?proto.portfolios.return.prt_closed_candle|undefined} value
 * @return {!proto.portfolios.return.update_payload} returns this
*/
proto.portfolios.return.update_payload.prototype.setPrtClosedCandle = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.update_payload} returns this
 */
proto.portfolios.return.update_payload.prototype.clearPrtClosedCandle = function() {
  return this.setPrtClosedCandle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_payload.prototype.hasPrtClosedCandle = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional prt_trad_ret_bc_end prt_trad_ret_bc_end_ = 54;
 * @return {?proto.portfolios.return.prt_trad_ret_bc_end}
 */
proto.portfolios.return.update_payload.prototype.getPrtTradRetBcEnd = function() {
  return /** @type{?proto.portfolios.return.prt_trad_ret_bc_end} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.prt_trad_ret_bc_end, 54));
};


/**
 * @param {?proto.portfolios.return.prt_trad_ret_bc_end|undefined} value
 * @return {!proto.portfolios.return.update_payload} returns this
*/
proto.portfolios.return.update_payload.prototype.setPrtTradRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.update_payload} returns this
 */
proto.portfolios.return.update_payload.prototype.clearPrtTradRetBcEnd = function() {
  return this.setPrtTradRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_payload.prototype.hasPrtTradRetBcEnd = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional prt_all_ret_bc_end prt_all_ret_bc_end_ = 55;
 * @return {?proto.portfolios.return.prt_all_ret_bc_end}
 */
proto.portfolios.return.update_payload.prototype.getPrtAllRetBcEnd = function() {
  return /** @type{?proto.portfolios.return.prt_all_ret_bc_end} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.prt_all_ret_bc_end, 55));
};


/**
 * @param {?proto.portfolios.return.prt_all_ret_bc_end|undefined} value
 * @return {!proto.portfolios.return.update_payload} returns this
*/
proto.portfolios.return.update_payload.prototype.setPrtAllRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.update_payload} returns this
 */
proto.portfolios.return.update_payload.prototype.clearPrtAllRetBcEnd = function() {
  return this.setPrtAllRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_payload.prototype.hasPrtAllRetBcEnd = function() {
  return jspb.Message.getField(this, 55) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.update_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.update_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.update_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.update_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.portfolios.return.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.portfolios.return.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.update_snapshot}
 */
proto.portfolios.return.update_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.update_snapshot;
  return proto.portfolios.return.update_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.update_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.update_snapshot}
 */
proto.portfolios.return.update_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.portfolios.return.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.portfolios.return.tstamp;
      reader.readMessage(value,proto.portfolios.return.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 6:
      var value = new proto.portfolios.return.update_payload;
      reader.readMessage(value,proto.portfolios.return.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.update_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.update_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.update_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.update_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.portfolios.return.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.portfolios.return.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.portfolios.return.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.portfolios.return.update_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.portfolios.return.update_type}
 */
proto.portfolios.return.update_snapshot.prototype.getUpdateType = function() {
  return /** @type {!proto.portfolios.return.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.portfolios.return.update_type} value
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_snapshot.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.portfolios.return.update_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string key = 4;
 * @return {string}
 */
proto.portfolios.return.update_snapshot.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.clearKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_snapshot.prototype.hasKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required tstamp event_ts = 5;
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.update_snapshot.prototype.getEventTs = function() {
  return /** @type{!proto.portfolios.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.tstamp, 5, 1));
};


/**
 * @param {!proto.portfolios.return.tstamp} value
 * @return {!proto.portfolios.return.update_snapshot} returns this
*/
proto.portfolios.return.update_snapshot.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_snapshot.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional update_payload sigmas = 6;
 * @return {?proto.portfolios.return.update_payload}
 */
proto.portfolios.return.update_snapshot.prototype.getSigmas = function() {
  return /** @type{?proto.portfolios.return.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.update_payload, 6));
};


/**
 * @param {?proto.portfolios.return.update_payload|undefined} value
 * @return {!proto.portfolios.return.update_snapshot} returns this
*/
proto.portfolios.return.update_snapshot.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.update_snapshot} returns this
 */
proto.portfolios.return.update_snapshot.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_snapshot.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.end_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.end_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.end_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.end_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.end_snapshot}
 */
proto.portfolios.return.end_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.end_snapshot;
  return proto.portfolios.return.end_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.end_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.end_snapshot}
 */
proto.portfolios.return.end_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.end_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.end_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.end_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.end_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.portfolios.return.end_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.end_snapshot} returns this
 */
proto.portfolios.return.end_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.end_snapshot} returns this
 */
proto.portfolios.return.end_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.end_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string service = 2;
 * @return {string}
 */
proto.portfolios.return.end_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.return.end_snapshot} returns this
 */
proto.portfolios.return.end_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.end_snapshot} returns this
 */
proto.portfolios.return.end_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.end_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.update_ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.update_ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.update_ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.update_ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    sequence: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.portfolios.return.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.portfolios.return.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.update_ticker}
 */
proto.portfolios.return.update_ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.update_ticker;
  return proto.portfolios.return.update_ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.update_ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.update_ticker}
 */
proto.portfolios.return.update_ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.portfolios.return.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSequence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = new proto.portfolios.return.tstamp;
      reader.readMessage(value,proto.portfolios.return.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 7:
      var value = new proto.portfolios.return.update_payload;
      reader.readMessage(value,proto.portfolios.return.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.update_ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.update_ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.update_ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.update_ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.portfolios.return.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.portfolios.return.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.portfolios.return.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.portfolios.return.update_ticker.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_ticker.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.portfolios.return.update_type}
 */
proto.portfolios.return.update_ticker.prototype.getUpdateType = function() {
  return /** @type {!proto.portfolios.return.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.portfolios.return.update_type} value
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_ticker.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.portfolios.return.update_ticker.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_ticker.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required sint64 sequence = 4;
 * @return {number}
 */
proto.portfolios.return.update_ticker.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.setSequence = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.clearSequence = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_ticker.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string key = 5;
 * @return {string}
 */
proto.portfolios.return.update_ticker.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.clearKey = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_ticker.prototype.hasKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp event_ts = 6;
 * @return {!proto.portfolios.return.tstamp}
 */
proto.portfolios.return.update_ticker.prototype.getEventTs = function() {
  return /** @type{!proto.portfolios.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.tstamp, 6, 1));
};


/**
 * @param {!proto.portfolios.return.tstamp} value
 * @return {!proto.portfolios.return.update_ticker} returns this
*/
proto.portfolios.return.update_ticker.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_ticker.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional update_payload sigmas = 7;
 * @return {?proto.portfolios.return.update_payload}
 */
proto.portfolios.return.update_ticker.prototype.getSigmas = function() {
  return /** @type{?proto.portfolios.return.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.update_payload, 7));
};


/**
 * @param {?proto.portfolios.return.update_payload|undefined} value
 * @return {!proto.portfolios.return.update_ticker} returns this
*/
proto.portfolios.return.update_ticker.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.update_ticker} returns this
 */
proto.portfolios.return.update_ticker.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.update_ticker.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.alive.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.alive.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliveId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.alive}
 */
proto.portfolios.return.alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.alive;
  return proto.portfolios.return.alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.alive}
 */
proto.portfolios.return.alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAliveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
};


/**
 * required sint64 alive_id = 1;
 * @return {number}
 */
proto.portfolios.return.alive.prototype.getAliveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.portfolios.return.alive} returns this
 */
proto.portfolios.return.alive.prototype.setAliveId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.portfolios.return.alive} returns this
 */
proto.portfolios.return.alive.prototype.clearAliveId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.alive.prototype.hasAliveId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.portfolios.return.feed_event.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.portfolios.return.feed_event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  SNAPSHOT: 1,
  TICKER: 2,
  END_SNAPSHOT: 3,
  ALIVE: 4
};

/**
 * @return {proto.portfolios.return.feed_event.EventTypeCase}
 */
proto.portfolios.return.feed_event.prototype.getEventTypeCase = function() {
  return /** @type {proto.portfolios.return.feed_event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.portfolios.return.feed_event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.portfolios.return.feed_event.prototype.toObject = function(opt_includeInstance) {
  return proto.portfolios.return.feed_event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.portfolios.return.feed_event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.feed_event.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.portfolios.return.update_snapshot.toObject(includeInstance, f),
    ticker: (f = msg.getTicker()) && proto.portfolios.return.update_ticker.toObject(includeInstance, f),
    endSnapshot: (f = msg.getEndSnapshot()) && proto.portfolios.return.end_snapshot.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.portfolios.return.alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.portfolios.return.feed_event}
 */
proto.portfolios.return.feed_event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.portfolios.return.feed_event;
  return proto.portfolios.return.feed_event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.portfolios.return.feed_event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.portfolios.return.feed_event}
 */
proto.portfolios.return.feed_event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.portfolios.return.update_snapshot;
      reader.readMessage(value,proto.portfolios.return.update_snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 2:
      var value = new proto.portfolios.return.update_ticker;
      reader.readMessage(value,proto.portfolios.return.update_ticker.deserializeBinaryFromReader);
      msg.setTicker(value);
      break;
    case 3:
      var value = new proto.portfolios.return.end_snapshot;
      reader.readMessage(value,proto.portfolios.return.end_snapshot.deserializeBinaryFromReader);
      msg.setEndSnapshot(value);
      break;
    case 4:
      var value = new proto.portfolios.return.alive;
      reader.readMessage(value,proto.portfolios.return.alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.portfolios.return.feed_event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.portfolios.return.feed_event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.portfolios.return.feed_event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.portfolios.return.feed_event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.portfolios.return.update_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getTicker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.portfolios.return.update_ticker.serializeBinaryToWriter
    );
  }
  f = message.getEndSnapshot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.portfolios.return.end_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.portfolios.return.alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional update_snapshot snapshot = 1;
 * @return {?proto.portfolios.return.update_snapshot}
 */
proto.portfolios.return.feed_event.prototype.getSnapshot = function() {
  return /** @type{?proto.portfolios.return.update_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.update_snapshot, 1));
};


/**
 * @param {?proto.portfolios.return.update_snapshot|undefined} value
 * @return {!proto.portfolios.return.feed_event} returns this
*/
proto.portfolios.return.feed_event.prototype.setSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.portfolios.return.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.feed_event} returns this
 */
proto.portfolios.return.feed_event.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.feed_event.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional update_ticker ticker = 2;
 * @return {?proto.portfolios.return.update_ticker}
 */
proto.portfolios.return.feed_event.prototype.getTicker = function() {
  return /** @type{?proto.portfolios.return.update_ticker} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.update_ticker, 2));
};


/**
 * @param {?proto.portfolios.return.update_ticker|undefined} value
 * @return {!proto.portfolios.return.feed_event} returns this
*/
proto.portfolios.return.feed_event.prototype.setTicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.portfolios.return.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.feed_event} returns this
 */
proto.portfolios.return.feed_event.prototype.clearTicker = function() {
  return this.setTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.feed_event.prototype.hasTicker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional end_snapshot end_snapshot = 3;
 * @return {?proto.portfolios.return.end_snapshot}
 */
proto.portfolios.return.feed_event.prototype.getEndSnapshot = function() {
  return /** @type{?proto.portfolios.return.end_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.end_snapshot, 3));
};


/**
 * @param {?proto.portfolios.return.end_snapshot|undefined} value
 * @return {!proto.portfolios.return.feed_event} returns this
*/
proto.portfolios.return.feed_event.prototype.setEndSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.portfolios.return.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.feed_event} returns this
 */
proto.portfolios.return.feed_event.prototype.clearEndSnapshot = function() {
  return this.setEndSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.feed_event.prototype.hasEndSnapshot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional alive alive = 4;
 * @return {?proto.portfolios.return.alive}
 */
proto.portfolios.return.feed_event.prototype.getAlive = function() {
  return /** @type{?proto.portfolios.return.alive} */ (
    jspb.Message.getWrapperField(this, proto.portfolios.return.alive, 4));
};


/**
 * @param {?proto.portfolios.return.alive|undefined} value
 * @return {!proto.portfolios.return.feed_event} returns this
*/
proto.portfolios.return.feed_event.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.portfolios.return.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.portfolios.return.feed_event} returns this
 */
proto.portfolios.return.feed_event.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.portfolios.return.feed_event.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.portfolios.return.update_type = {
  SNAPSHOT: 1,
  VARIATIONS: 2
};

/**
 * @enum {number}
 */
proto.portfolios.return.svc_status = {
  ONLINE: 0,
  OFFLINE: 1,
  BROKEN: 2
};

goog.object.extend(exports, proto.portfolios.return);
