import React from 'react'
import * as Store from 'types/store'

import { Table } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { getCheckBoxFilter, getColumnSearchProps, sortNumber } from 'helpers/tableHelpers'
import { formatNumber } from 'core/formats'
import { useTranslation } from 'react-i18next'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'

interface Props {
  assets: Store.AssetMetric[]
  setFilteredAssets: (assets: Store.AssetMetric[]) => void
  filteredValues: Json
  setFilteredValues: (values: Json) => void
}

const AnalysisModelTable: React.FC<Props> = ({
  assets,
  setFilteredAssets,
  filteredValues,
  setFilteredValues,
}) => {
  const { t } = useTranslation()

  const hasMultipleItems = assets.length > 1

  const getFiltersIfRequired = (filters: Json) => {
    return hasMultipleItems ? filters : {}
  }

  const findFilter = (dataIndex: string): string[] => {
    if (!filteredValues) {
      return null
    }

    return filteredValues[dataIndex]
  }

  const columns: ColumnsType<Store.AssetMetric> = [
    {
      title: t('table.column.assetName'),
      dataIndex: 'assetName',
      width: 250,
      fixed: 'left',
      filteredValue: findFilter('assetName'),
      ...getFiltersIfRequired(getColumnSearchProps('assetName')),
    },
    {
      title: t('table.column.analysisModelName'),
      dataIndex: 'analysisModelName',
      width: 200,
      filteredValue: findFilter('analysisModelName'),
      ...getFiltersIfRequired(getColumnSearchProps('analysisModelName')),
    },
    {
      title: t('table.column.analysisModelVersion'),
      dataIndex: 'analysisModelVersion',
      width: 200,
      filteredValue: findFilter('analysisModelVersion'),
      ...getFiltersIfRequired(getColumnSearchProps('analysisModelVersion')),
    },
    {
      title: t('table.column.portfolioName'),
      dataIndex: 'portfolioName',
      width: 250,
      filteredValue: findFilter('portfolioName'),
      ...getFiltersIfRequired(getColumnSearchProps('portfolioName')),
    },
    {
      title: t('table.column.strategyName'),
      dataIndex: 'strategyName',
      width: 250,
      filteredValue: findFilter('strategyName'),
      ...getFiltersIfRequired(getColumnSearchProps('strategyName')),
    },
    {
      title: t('table.column.status'),
      dataIndex: 'status',
      width: 150,
      filteredValue: findFilter('status'),
      ...getFiltersIfRequired(getCheckBoxFilter('status', assets, (label) => label)),
    },
    {
      title: t('table.column.ram'),
      dataIndex: 'ram',
      width: 150,
      render: (value) => {
        return formatNumber(value) + ' mb'
      },
      filteredValue: findFilter('ram'),
      ...getFiltersIfRequired({
        sorter: (a, b) => sortNumber(a.ram, b.ram),
      }),
    },
    {
      title: t('table.column.cpu'),
      dataIndex: 'cpu',
      width: 150,
      render: (value) => {
        return formatNumber(value) + ' %'
      },
      filteredValue: findFilter('cpu'),
      ...getFiltersIfRequired({
        sorter: (a, b) => sortNumber(a.cpu, b.cpu),
      }),
    },
  ]

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Store.AssetMetric> | SorterResult<Store.AssetMetric>[],
    extra: TableCurrentDataSource<Store.AssetMetric>,
  ) => {
    setFilteredAssets(extra.currentDataSource)
    setFilteredValues(filters)
  }

  return (
    <div className="analysis-table nonDraggable">
      <Table
        rowKey={obj => obj.assetName}
        dataSource={assets}
        columns={columns}
        pagination={false}
        scroll={{ x: 250, y: 73 }}
        onChange={onChange}
        getPopupContainer={() => document.querySelector('.analysis-table')}
      />
    </div>
  )
}

export default AnalysisModelTable
