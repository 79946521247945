import React, { useState } from 'react'
import { Dropdown, Menu, Popover } from 'antd'
import { ExtendedDataNode, TransferType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { getMainItem, getAllPortfolios, getAllAssets } from 'helpers/transfer/itemAddHelper'
import { AssetStatus, TransferContext } from 'types/enums'
import styles from '../styles.module.scss'
import * as Store from 'types/store'
import colors from 'config/colors'

interface Props {
  node: ExtendedDataNode
  onClick: (node: ExtendedDataNode, type: TransferType) => void
  context: TransferContext
}

const AddStrategy: React.FC<Props> = ({ node, onClick, context }) => {
  const { t } = useTranslation()

  const getItems = () => {
    if (node.children.length === 0) {
      return []
    }
    switch (context) {
      case TransferContext.All:
        return [getMainItem(node), getAllPortfolios(node), getAllAssets(node)]
      case TransferContext.AtBacktest:
        return [getMainItem(node)]
      default:
        return [getAllAssets(node)]
    }
  }

  const everyAssetsSelected =
    node.children.length > 0 &&
    node.children.every(
      (portfolio) => portfolio.children.length > 0 && portfolio.children.every((asset) => asset.selected),
    )

  const someAssetsSelected =
    node.children.length > 0 &&
    node.children.some(
      (portfolio) => portfolio.children.length > 0 && portfolio.children.some((asset) => asset.selected),
    )

  const isBroken =
    node.children.length > 0 &&
    node.children.some(
      (portfolio) =>
        portfolio.children.length > 0 &&
        portfolio.children.some((asset) => (asset.resource as Store.Asset).status === AssetStatus.Broken),
    )

  const somePortfoliosSeleted = node.children.some((portfolio) => portfolio.selected)

  const trigger: any =
    context === TransferContext.AtSessionLaunch ? ['contextMenu'] : ['click', 'contextMenu']

  const [menu, setMenu] = useState([])

  const getErrorMessage = () => {
    return someAssetsSelected ? t('error.selectedBrokenAsset') : t('error.nonSelectedBrokenStrategy')
  }

  const onVisibleChange = (visible: boolean) => {
    if (visible) {
      setMenu(getItems())
    } else {
      setMenu([])
    }
  }

  const DropdownMenu = (
    <Menu>
      {menu.map((item) => (
        <Menu.Item
          key={item.key}
          onClick={() => {
            onClick(node, item.transferType)
          }}
        >
          {t(item.label)}
        </Menu.Item>
      ))}
    </Menu>
  )

  const getStatusIcon = () => {
    if (everyAssetsSelected) {
      return <CheckCircleFilled className={styles['menu-icon-full-selected']} />
    }

    if (someAssetsSelected || somePortfoliosSeleted) {
      return <CheckCircleFilled className={styles['menu-icon-partial-selected']} />
    }

    return null
  }

  return (
    <Dropdown
      overlay={DropdownMenu}
      trigger={trigger}
      getPopupContainer={(trigger) => trigger.parentElement}
      onVisibleChange={onVisibleChange}
    >
      <span className={node.selected && 'selected-item'}>
        {node.selected && <CheckOutlined style={{ marginRight: 5 }} />}
        {getStatusIcon()}
        {isBroken && (
          <Popover content={getErrorMessage()} trigger="hover">
            <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
          </Popover>
        )}
        {node.title}
      </span>
    </Dropdown>
  )
}

export default AddStrategy
