import React, { useEffect, useMemo, useState } from 'react'
import StrategyTransfer from 'components/StrategyTransfer'
import { ExtendedDataNode } from 'types/ui'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { generateResourceTree } from 'services/treeService'
import { isFilteredLayout } from 'services/dashboardService'
import { SessionType, TransferContext } from 'types/enums'
import { selectResources } from 'store/resources/selectors'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import { selectDashboardEntityFilter } from 'store/dashboard/selectors'

interface Props {
  defaultKeys: string[]
  onDataChanged: (selectedItems: Store.SelectedResource[]) => void
}

const StrategyTransferContainer: React.FC<Props> = ({ defaultKeys, onDataChanged }) => {
  const { t } = useTranslation()
  const session = useSelector(selectOperativeSessionSession)
  const { assets, portfolios, strategies } = useSelector(selectResources)
  const [tree, setTree] = useState<Array<ExtendedDataNode>>()
  const [filter, setFilter] = useState<string>(null)
  const sessionAssets = useSelector(selectOperativeSessionAssets)

  const filtered = isFilteredLayout(useSelector(selectDashboardEntityFilter), session.type)
  const { type: sessionType } = useSelector(selectOperativeSessionSession)
  const context = sessionType === SessionType.BackTest ? TransferContext.AtBacktest : TransferContext.All

  const userAssets = useMemo(
    () =>
      assets.data.filter((asset) => !asset.associationUserId || asset.associationUserId === session?.ownerId),
    [session, assets],
  )

  const combinedAssets = userAssets.map((asset) => {
    const status =
      (sessionAssets && sessionAssets.find((sessionAsset) => asset.id === sessionAsset.id)?.status) || null

    return {
      ...asset,
      status,
    }
  })

  const filteredAssets = filtered ? sessionAssets : combinedAssets

  const filteredPortfolios = portfolios.data.filter((portfolio) => {
    return filteredAssets.some((asset) => portfolio.id === asset.portfolioId)
  })

  const filteredStrategies = strategies.data.filter((strategy) => {
    return filteredPortfolios.some((portfolio) => portfolio.strategyId === strategy.id)
  })

  useEffect(() => {
    const tree = generateResourceTree(
      filteredStrategies,
      filteredPortfolios,
      filteredAssets,
      t('transfer.allStrategies'),
      filter,
    )

    setTree(tree)
  }, [filter, filtered, sessionAssets])

  const getSelectedItems = (data: ExtendedDataNode[], selectedItems: Store.SelectedResource[]) => {
    return data.reduce((array, item) => {
      if (item.selected) {
        array = [...array, { resource: item.resource, type: item.type }]
      }
      if (item.children) {
        array = getSelectedItems(item.children, array)
      }

      return array
    }, selectedItems)
  }

  const onDataChangedInternal = (data: ExtendedDataNode[]) => {
    const selectedItems = getSelectedItems(data, [])
    onDataChanged(selectedItems)
  }

  if (!tree) {
    return null
  }

  const onFilterChange = (newFilter: string) => {
    setFilter(newFilter)
  }

  return (
    <StrategyTransfer
      treeData={tree}
      onDataChanged={onDataChangedInternal}
      context={context}
      onFilterChange={onFilterChange}
      defaultSelected={defaultKeys}
    />
  )
}

export default StrategyTransferContainer
