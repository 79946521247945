import * as Api from 'types/api'
import ReadPortfolio from 'components/StrategyViewer/ReadPortfolio'
import { selectUsedResources } from 'store/pages/selectors'
import { selectAssets, selectPortfolios, selectPortfoliosState } from 'store/resources/selectors'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updatePortfolio } from 'store/resources/portfolios/actions'
import { CrudOperation } from 'types/enums'
import * as Store from 'types/store'
import { setUserAction } from 'store/pages/strategy/actions'
import { NodeType } from 'types/ui'
import DeleteAsset from './modals/DeleteAsset'
import { ResourceAction } from 'types/store'
import NoItemSelected from 'components/StrategyViewer/NoItemSelected'

interface Props {
  userAction: Store.UserAction
  isReadOnly: boolean
}

const ReadPortfolioContainer: React.FC<Props> = ({ userAction, isReadOnly }) => {
  const dispatch = useDispatch()
  const assets = useSelector(selectAssets)
  const portfolios = useSelector(selectPortfolios)
  const { assets: usedAssets } = useSelector(selectUsedResources)

  const loadingState = useSelector(selectPortfoliosState)

  const { operation, node } = userAction
  const { type, resource } = node
  const [resourceAction, setResourceAction] = useState<ResourceAction>(null)

  let asset: Store.Asset
  let portfolio: Store.Portfolio

  switch (type) {
    case NodeType.Asset:
      asset = assets.find((asset) => asset.id === resource.id)
      if (asset) {
        portfolio = portfolios.find((item) => item.id === asset.portfolioId)
      }
      break
    case NodeType.Portfolio:
      asset = null
      portfolio = portfolios.find((item) => item.id === resource.id)
      break
    default:
      break
  }

  if (!portfolio) {
    return <NoItemSelected noStrategies={false} />
  }

  const filteredAssets = assets.filter((asset) => asset.portfolioId === portfolio.id)

  const onCancel = () => {
    dispatch(setUserAction(userAction.node, CrudOperation.Read))
  }

  const onFinish = (data: Json) => {
    const postData: Api.PortfolioRest = {
      id: portfolio.id,
      strategyId: portfolio.strategyId,
      name: data.name,
      maxLong: data.maxLong,
      maxNetHedging: data.maxNetHedging,
      maxShort: data.maxShort,
      minNetHedging: data.minNetHedging,
    }

    dispatch(updatePortfolio(postData))
  }

  const onItemClicked = (asset: Store.Asset, operation: CrudOperation) => {
    if (operation === CrudOperation.Delete) {
      setResourceAction({ resource: asset, type: NodeType.Asset, operation })
    } else {
      // Nothing to do at the moment
    }
  }

  return (
    <>
      {resourceAction && resourceAction.operation === CrudOperation.Delete && (
        <DeleteAsset asset={resourceAction.resource as Store.Asset} onClose={() => setResourceAction(null)} />
      )}
      <ReadPortfolio
        assets={filteredAssets}
        asset={asset}
        portfolio={portfolio}
        onFinish={onFinish}
        loadingState={loadingState}
        isEditing={operation === CrudOperation.Update}
        isReadOnly={isReadOnly}
        onCancel={onCancel}
        onItemClicked={onItemClicked}
        usedAssets={usedAssets}
        key={portfolio.id}
      />
    </>
  )
}

export default ReadPortfolioContainer
