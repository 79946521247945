import models from 'sockets/assets/asset_models_pb'
import positionAnalysis from 'sockets/assets/asset_position_analysis_pb'
import returns from 'sockets/assets/asset_return_pb'
import securityData from 'sockets/assets/asset_security_data_pb'
import analysisStatistics from 'sockets/assets/asset_analysis_statistics_pb'
import operations from 'sockets/assets/asset_operations_pb'
import principal from 'sockets/assets/asset_principal_pb'
import * as Store from 'types/store'
import { AssetFeed } from 'types/enums.api'
import {
  mapAssetAnalysisStatisticsSigmas,
  mapAssetModelsSigmas,
  mapAssetOperationsSigmas,
  mapAssetPositionAnalysisSigmas,
  mapAssetPrincipalSigmas,
  mapAssetReturnSigmas,
  mapAssetSecurityDataSigmas,
} from 'services/protoMappers/asset'

export const deserializeAssetBuffer = (
  buffer: ArrayBuffer,
  service: AssetFeed,
  subscriptionType: Store.SubscriptionType,
): [string, Store.AssetSigmas] => {
  const bytes = new Uint8Array(buffer)

  switch (service) {
    case AssetFeed.Operations: {
      const { feed_event } = operations
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapAssetOperationsSigmas(data)

      return [data.key, sigmas]
    }

    case AssetFeed.Models: {
      const { feed_event } = models
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapAssetModelsSigmas(data)
      return [data.key, sigmas]
    }

    case AssetFeed.PositionAnalysis: {
      const { feed_event } = positionAnalysis
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapAssetPositionAnalysisSigmas(data)
      return [data.key, sigmas]
    }

    case AssetFeed.Principal: {
      const { feed_event } = principal
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      // Incomplete We don't use these
      const sigmas = mapAssetPrincipalSigmas(data)
      return [data.key, sigmas]
    }

    case AssetFeed.Return: {
      const { feed_event } = returns
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }

      const sigmas = mapAssetReturnSigmas(data)
      return [data.key, sigmas]
    }

    case AssetFeed.SecurityData: {
      const { feed_event } = securityData
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapAssetSecurityDataSigmas(feed[subscriptionType])
      return [data.key, sigmas]
    }

    case AssetFeed.AnalysisStatistics: {
      const { feed_event } = analysisStatistics as any
      const deserializeData = feed_event.deserializeBinary(bytes)
      const feed = feed_event.toObject(false, deserializeData)
      const data = feed[subscriptionType]
      if (feed.alive || !data) {
        return
      }
      const sigmas = mapAssetAnalysisStatisticsSigmas(feed[subscriptionType])
      return [data.key, sigmas]
    }
  }
}
