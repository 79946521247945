// source: asset_principal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.assets.principal.alive', null, global);
goog.exportSymbol('proto.assets.principal.asset_avg_princ', null, global);
goog.exportSymbol('proto.assets.principal.asset_avg_princ_close', null, global);
goog.exportSymbol('proto.assets.principal.asset_cum_wdt_princ_op', null, global);
goog.exportSymbol('proto.assets.principal.asset_dt_princ_close_op', null, global);
goog.exportSymbol('proto.assets.principal.asset_wdt_princ_close_op', null, global);
goog.exportSymbol('proto.assets.principal.decimal', null, global);
goog.exportSymbol('proto.assets.principal.end_snapshot', null, global);
goog.exportSymbol('proto.assets.principal.feed_event', null, global);
goog.exportSymbol('proto.assets.principal.feed_event.EventTypeCase', null, global);
goog.exportSymbol('proto.assets.principal.svc_status', null, global);
goog.exportSymbol('proto.assets.principal.tstamp', null, global);
goog.exportSymbol('proto.assets.principal.update_payload', null, global);
goog.exportSymbol('proto.assets.principal.update_snapshot', null, global);
goog.exportSymbol('proto.assets.principal.update_ticker', null, global);
goog.exportSymbol('proto.assets.principal.update_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.tstamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.tstamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.tstamp.displayName = 'proto.assets.principal.tstamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.decimal.displayName = 'proto.assets.principal.decimal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.asset_cum_wdt_princ_op = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.asset_cum_wdt_princ_op, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.asset_cum_wdt_princ_op.displayName = 'proto.assets.principal.asset_cum_wdt_princ_op';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.asset_wdt_princ_close_op = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.asset_wdt_princ_close_op, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.asset_wdt_princ_close_op.displayName = 'proto.assets.principal.asset_wdt_princ_close_op';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.asset_avg_princ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.asset_avg_princ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.asset_avg_princ.displayName = 'proto.assets.principal.asset_avg_princ';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.asset_avg_princ_close = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.asset_avg_princ_close, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.asset_avg_princ_close.displayName = 'proto.assets.principal.asset_avg_princ_close';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.asset_dt_princ_close_op = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.asset_dt_princ_close_op, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.asset_dt_princ_close_op.displayName = 'proto.assets.principal.asset_dt_princ_close_op';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.update_payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.update_payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.update_payload.displayName = 'proto.assets.principal.update_payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.update_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.update_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.update_snapshot.displayName = 'proto.assets.principal.update_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.end_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.end_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.end_snapshot.displayName = 'proto.assets.principal.end_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.update_ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.update_ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.update_ticker.displayName = 'proto.assets.principal.update_ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.principal.alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.alive.displayName = 'proto.assets.principal.alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.principal.feed_event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.assets.principal.feed_event.oneofGroups_);
};
goog.inherits(proto.assets.principal.feed_event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.principal.feed_event.displayName = 'proto.assets.principal.feed_event';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.tstamp.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.tstamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.tstamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.tstamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    offset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.tstamp}
 */
proto.assets.principal.tstamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.tstamp;
  return proto.assets.principal.tstamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.tstamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.tstamp}
 */
proto.assets.principal.tstamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.tstamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.tstamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.tstamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.tstamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * required sint64 time = 1;
 * @return {number}
 */
proto.assets.principal.tstamp.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.tstamp} returns this
 */
proto.assets.principal.tstamp.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.tstamp} returns this
 */
proto.assets.principal.tstamp.prototype.clearTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.tstamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 offset = 2;
 * @return {number}
 */
proto.assets.principal.tstamp.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.tstamp} returns this
 */
proto.assets.principal.tstamp.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.tstamp} returns this
 */
proto.assets.principal.tstamp.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.tstamp.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required uint32 code = 3;
 * @return {number}
 */
proto.assets.principal.tstamp.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.tstamp} returns this
 */
proto.assets.principal.tstamp.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.tstamp} returns this
 */
proto.assets.principal.tstamp.prototype.clearCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.tstamp.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
    coeff: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.decimal}
 */
proto.assets.principal.decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.decimal;
  return proto.assets.principal.decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.decimal}
 */
proto.assets.principal.decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCoeff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * required sint64 coeff = 1;
 * @return {number}
 */
proto.assets.principal.decimal.prototype.getCoeff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.decimal} returns this
 */
proto.assets.principal.decimal.prototype.setCoeff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.decimal} returns this
 */
proto.assets.principal.decimal.prototype.clearCoeff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.decimal.prototype.hasCoeff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 exp = 2;
 * @return {number}
 */
proto.assets.principal.decimal.prototype.getExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.decimal} returns this
 */
proto.assets.principal.decimal.prototype.setExp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.decimal} returns this
 */
proto.assets.principal.decimal.prototype.clearExp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.decimal.prototype.hasExp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.asset_cum_wdt_princ_op.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.asset_cum_wdt_princ_op} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_cum_wdt_princ_op.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyCumPrincWdtLngPos: (f = msg.getAssetTdyCumPrincWdtLngPos()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyCumPrincWdtShrPos: (f = msg.getAssetTdyCumPrincWdtShrPos()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyCumPrincWdtLngNeg: (f = msg.getAssetTdyCumPrincWdtLngNeg()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyCumPrincWdtShrNeg: (f = msg.getAssetTdyCumPrincWdtShrNeg()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyCumPrincWdt: (f = msg.getAssetTdyCumPrincWdt()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetYtdCumPrincWdt: (f = msg.getAssetYtdCumPrincWdt()) && proto.assets.principal.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op}
 */
proto.assets.principal.asset_cum_wdt_princ_op.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.asset_cum_wdt_princ_op;
  return proto.assets.principal.asset_cum_wdt_princ_op.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.asset_cum_wdt_princ_op} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op}
 */
proto.assets.principal.asset_cum_wdt_princ_op.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPrincWdtLngPos(value);
      break;
    case 2:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPrincWdtShrPos(value);
      break;
    case 3:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPrincWdtLngNeg(value);
      break;
    case 4:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPrincWdtShrNeg(value);
      break;
    case 5:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPrincWdt(value);
      break;
    case 6:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCumPrincWdt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.asset_cum_wdt_princ_op.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.asset_cum_wdt_princ_op} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_cum_wdt_princ_op.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyCumPrincWdtLngPos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPrincWdtShrPos();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPrincWdtLngNeg();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPrincWdtShrNeg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPrincWdt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdCumPrincWdt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_cum_princ_wdt_lng_pos = 1;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.getAssetTdyCumPrincWdtLngPos = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 1));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
*/
proto.assets.principal.asset_cum_wdt_princ_op.prototype.setAssetTdyCumPrincWdtLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.clearAssetTdyCumPrincWdtLngPos = function() {
  return this.setAssetTdyCumPrincWdtLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.hasAssetTdyCumPrincWdtLngPos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_tdy_cum_princ_wdt_shr_pos = 2;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.getAssetTdyCumPrincWdtShrPos = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 2));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
*/
proto.assets.principal.asset_cum_wdt_princ_op.prototype.setAssetTdyCumPrincWdtShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.clearAssetTdyCumPrincWdtShrPos = function() {
  return this.setAssetTdyCumPrincWdtShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.hasAssetTdyCumPrincWdtShrPos = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_tdy_cum_princ_wdt_lng_neg = 3;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.getAssetTdyCumPrincWdtLngNeg = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 3));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
*/
proto.assets.principal.asset_cum_wdt_princ_op.prototype.setAssetTdyCumPrincWdtLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.clearAssetTdyCumPrincWdtLngNeg = function() {
  return this.setAssetTdyCumPrincWdtLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.hasAssetTdyCumPrincWdtLngNeg = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal asset_tdy_cum_princ_wdt_shr_neg = 4;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.getAssetTdyCumPrincWdtShrNeg = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 4));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
*/
proto.assets.principal.asset_cum_wdt_princ_op.prototype.setAssetTdyCumPrincWdtShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.clearAssetTdyCumPrincWdtShrNeg = function() {
  return this.setAssetTdyCumPrincWdtShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.hasAssetTdyCumPrincWdtShrNeg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal asset_tdy_cum_princ_wdt = 5;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.getAssetTdyCumPrincWdt = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 5));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
*/
proto.assets.principal.asset_cum_wdt_princ_op.prototype.setAssetTdyCumPrincWdt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.clearAssetTdyCumPrincWdt = function() {
  return this.setAssetTdyCumPrincWdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.hasAssetTdyCumPrincWdt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal asset_ytd_cum_princ_wdt = 6;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.getAssetYtdCumPrincWdt = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 6));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
*/
proto.assets.principal.asset_cum_wdt_princ_op.prototype.setAssetYtdCumPrincWdt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_cum_wdt_princ_op} returns this
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.clearAssetYtdCumPrincWdt = function() {
  return this.setAssetYtdCumPrincWdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_cum_wdt_princ_op.prototype.hasAssetYtdCumPrincWdt = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.asset_wdt_princ_close_op.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.asset_wdt_princ_close_op} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_wdt_princ_close_op.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyClosedCumPrincWdtLngPos: (f = msg.getAssetTdyClosedCumPrincWdtLngPos()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPrincWdtShrPos: (f = msg.getAssetTdyClosedCumPrincWdtShrPos()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPrincWdtLngNeg: (f = msg.getAssetTdyClosedCumPrincWdtLngNeg()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyClosedCumPrincWdtShrNeg: (f = msg.getAssetTdyClosedCumPrincWdtShrNeg()) && proto.assets.principal.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.asset_wdt_princ_close_op}
 */
proto.assets.principal.asset_wdt_princ_close_op.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.asset_wdt_princ_close_op;
  return proto.assets.principal.asset_wdt_princ_close_op.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.asset_wdt_princ_close_op} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.asset_wdt_princ_close_op}
 */
proto.assets.principal.asset_wdt_princ_close_op.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPrincWdtLngPos(value);
      break;
    case 2:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPrincWdtShrPos(value);
      break;
    case 3:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPrincWdtLngNeg(value);
      break;
    case 4:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedCumPrincWdtShrNeg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.asset_wdt_princ_close_op.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.asset_wdt_princ_close_op} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_wdt_princ_close_op.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyClosedCumPrincWdtLngPos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPrincWdtShrPos();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPrincWdtLngNeg();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedCumPrincWdtShrNeg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_closed_cum_princ_wdt_lng_pos = 1;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.getAssetTdyClosedCumPrincWdtLngPos = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 1));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_wdt_princ_close_op} returns this
*/
proto.assets.principal.asset_wdt_princ_close_op.prototype.setAssetTdyClosedCumPrincWdtLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_wdt_princ_close_op} returns this
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.clearAssetTdyClosedCumPrincWdtLngPos = function() {
  return this.setAssetTdyClosedCumPrincWdtLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.hasAssetTdyClosedCumPrincWdtLngPos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_princ_wdt_shr_pos = 2;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.getAssetTdyClosedCumPrincWdtShrPos = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 2));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_wdt_princ_close_op} returns this
*/
proto.assets.principal.asset_wdt_princ_close_op.prototype.setAssetTdyClosedCumPrincWdtShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_wdt_princ_close_op} returns this
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.clearAssetTdyClosedCumPrincWdtShrPos = function() {
  return this.setAssetTdyClosedCumPrincWdtShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.hasAssetTdyClosedCumPrincWdtShrPos = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_princ_wdt_lng_neg = 3;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.getAssetTdyClosedCumPrincWdtLngNeg = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 3));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_wdt_princ_close_op} returns this
*/
proto.assets.principal.asset_wdt_princ_close_op.prototype.setAssetTdyClosedCumPrincWdtLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_wdt_princ_close_op} returns this
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.clearAssetTdyClosedCumPrincWdtLngNeg = function() {
  return this.setAssetTdyClosedCumPrincWdtLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.hasAssetTdyClosedCumPrincWdtLngNeg = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal asset_tdy_closed_cum_princ_wdt_shr_neg = 4;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.getAssetTdyClosedCumPrincWdtShrNeg = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 4));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_wdt_princ_close_op} returns this
*/
proto.assets.principal.asset_wdt_princ_close_op.prototype.setAssetTdyClosedCumPrincWdtShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_wdt_princ_close_op} returns this
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.clearAssetTdyClosedCumPrincWdtShrNeg = function() {
  return this.setAssetTdyClosedCumPrincWdtShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_wdt_princ_close_op.prototype.hasAssetTdyClosedCumPrincWdtShrNeg = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.asset_avg_princ.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.asset_avg_princ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.asset_avg_princ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_avg_princ.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetYtdCumPrincWdtEnd: (f = msg.getAssetYtdCumPrincWdtEnd()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetYtdDtPrincEnd: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    assetYtdCumPrincWdtBcEnd: (f = msg.getAssetYtdCumPrincWdtBcEnd()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTotDtPrinc: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    assetTotCumPrincWdt: (f = msg.getAssetTotCumPrincWdt()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTotAvgPrinc: (f = msg.getAssetTotAvgPrinc()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetYtdDtPrinc: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    assetYtdAvgPrinc: (f = msg.getAssetYtdAvgPrinc()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyCumPrincWdtBc: (f = msg.getAssetTdyCumPrincWdtBc()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyAvgPrincBc: (f = msg.getAssetTdyAvgPrincBc()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTotCumPrincWdtBc: (f = msg.getAssetTotCumPrincWdtBc()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTotAvgPrincBc: (f = msg.getAssetTotAvgPrincBc()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetYtdCumPrincWdtBc: (f = msg.getAssetYtdCumPrincWdtBc()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetYtdAvgPrincBc: (f = msg.getAssetYtdAvgPrincBc()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTdyDtPrinc: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.asset_avg_princ}
 */
proto.assets.principal.asset_avg_princ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.asset_avg_princ;
  return proto.assets.principal.asset_avg_princ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.asset_avg_princ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.asset_avg_princ}
 */
proto.assets.principal.asset_avg_princ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCumPrincWdtEnd(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetYtdDtPrincEnd(value);
      break;
    case 3:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCumPrincWdtBcEnd(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTotDtPrinc(value);
      break;
    case 5:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPrincWdt(value);
      break;
    case 6:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTotAvgPrinc(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetYtdDtPrinc(value);
      break;
    case 8:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdAvgPrinc(value);
      break;
    case 9:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPrincWdtBc(value);
      break;
    case 10:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincBc(value);
      break;
    case 11:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPrincWdtBc(value);
      break;
    case 12:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTotAvgPrincBc(value);
      break;
    case 13:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdCumPrincWdtBc(value);
      break;
    case 14:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdAvgPrincBc(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyDtPrinc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.asset_avg_princ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.asset_avg_princ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.asset_avg_princ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_avg_princ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetYtdCumPrincWdtEnd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getAssetYtdCumPrincWdtBcEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getAssetTotCumPrincWdt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotAvgPrinc();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getAssetYtdAvgPrinc();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyCumPrincWdtBc();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrincBc();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPrincWdtBc();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotAvgPrincBc();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdCumPrincWdtBc();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdAvgPrincBc();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeUint64(
      15,
      f
    );
  }
};


/**
 * optional decimal asset_ytd_cum_princ_wdt_end = 1;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetYtdCumPrincWdtEnd = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 1));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetYtdCumPrincWdtEnd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetYtdCumPrincWdtEnd = function() {
  return this.setAssetYtdCumPrincWdtEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetYtdCumPrincWdtEnd = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 asset_ytd_dt_princ_end = 2;
 * @return {number}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetYtdDtPrincEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.setAssetYtdDtPrincEnd = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetYtdDtPrincEnd = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetYtdDtPrincEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_ytd_cum_princ_wdt_bc_end = 3;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetYtdCumPrincWdtBcEnd = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 3));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetYtdCumPrincWdtBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetYtdCumPrincWdtBcEnd = function() {
  return this.setAssetYtdCumPrincWdtBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetYtdCumPrincWdtBcEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 asset_tot_dt_princ = 4;
 * @return {number}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetTotDtPrinc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.setAssetTotDtPrinc = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetTotDtPrinc = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetTotDtPrinc = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal asset_tot_cum_princ_wdt = 5;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetTotCumPrincWdt = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 5));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetTotCumPrincWdt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetTotCumPrincWdt = function() {
  return this.setAssetTotCumPrincWdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetTotCumPrincWdt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal asset_tot_avg_princ = 6;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetTotAvgPrinc = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 6));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetTotAvgPrinc = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetTotAvgPrinc = function() {
  return this.setAssetTotAvgPrinc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetTotAvgPrinc = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 asset_ytd_dt_princ = 7;
 * @return {number}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetYtdDtPrinc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.setAssetYtdDtPrinc = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetYtdDtPrinc = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetYtdDtPrinc = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal asset_ytd_avg_princ = 8;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetYtdAvgPrinc = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 8));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetYtdAvgPrinc = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetYtdAvgPrinc = function() {
  return this.setAssetYtdAvgPrinc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetYtdAvgPrinc = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal asset_tdy_cum_princ_wdt_bc = 9;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetTdyCumPrincWdtBc = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 9));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetTdyCumPrincWdtBc = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetTdyCumPrincWdtBc = function() {
  return this.setAssetTdyCumPrincWdtBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetTdyCumPrincWdtBc = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_bc = 10;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetTdyAvgPrincBc = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 10));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetTdyAvgPrincBc = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetTdyAvgPrincBc = function() {
  return this.setAssetTdyAvgPrincBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetTdyAvgPrincBc = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal asset_tot_cum_princ_wdt_bc = 11;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetTotCumPrincWdtBc = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 11));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetTotCumPrincWdtBc = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetTotCumPrincWdtBc = function() {
  return this.setAssetTotCumPrincWdtBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetTotCumPrincWdtBc = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal asset_tot_avg_princ_bc = 12;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetTotAvgPrincBc = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 12));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetTotAvgPrincBc = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetTotAvgPrincBc = function() {
  return this.setAssetTotAvgPrincBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetTotAvgPrincBc = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal asset_ytd_cum_princ_wdt_bc = 13;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetYtdCumPrincWdtBc = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 13));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetYtdCumPrincWdtBc = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetYtdCumPrincWdtBc = function() {
  return this.setAssetYtdCumPrincWdtBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetYtdCumPrincWdtBc = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal asset_ytd_avg_princ_bc = 14;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetYtdAvgPrincBc = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 14));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
*/
proto.assets.principal.asset_avg_princ.prototype.setAssetYtdAvgPrincBc = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetYtdAvgPrincBc = function() {
  return this.setAssetYtdAvgPrincBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetYtdAvgPrincBc = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional uint64 asset_tdy_dt_princ = 15;
 * @return {number}
 */
proto.assets.principal.asset_avg_princ.prototype.getAssetTdyDtPrinc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.setAssetTdyDtPrinc = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ} returns this
 */
proto.assets.principal.asset_avg_princ.prototype.clearAssetTdyDtPrinc = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ.prototype.hasAssetTdyDtPrinc = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.asset_avg_princ_close.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.asset_avg_princ_close.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.asset_avg_princ_close} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_avg_princ_close.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyCumPrincWdtBcClose: (f = msg.getAssetTdyCumPrincWdtBcClose()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTotCumPrincWdtClose: (f = msg.getAssetTotCumPrincWdtClose()) && proto.assets.principal.decimal.toObject(includeInstance, f),
    assetTotCumPrincWdtBcClose: (f = msg.getAssetTotCumPrincWdtBcClose()) && proto.assets.principal.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.asset_avg_princ_close}
 */
proto.assets.principal.asset_avg_princ_close.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.asset_avg_princ_close;
  return proto.assets.principal.asset_avg_princ_close.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.asset_avg_princ_close} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.asset_avg_princ_close}
 */
proto.assets.principal.asset_avg_princ_close.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyCumPrincWdtBcClose(value);
      break;
    case 2:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPrincWdtClose(value);
      break;
    case 3:
      var value = new proto.assets.principal.decimal;
      reader.readMessage(value,proto.assets.principal.decimal.deserializeBinaryFromReader);
      msg.setAssetTotCumPrincWdtBcClose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.asset_avg_princ_close.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.asset_avg_princ_close.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.asset_avg_princ_close} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_avg_princ_close.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyCumPrincWdtBcClose();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPrincWdtClose();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotCumPrincWdtBcClose();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.principal.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_cum_princ_wdt_bc_close = 1;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ_close.prototype.getAssetTdyCumPrincWdtBcClose = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 1));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ_close} returns this
*/
proto.assets.principal.asset_avg_princ_close.prototype.setAssetTdyCumPrincWdtBcClose = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ_close} returns this
 */
proto.assets.principal.asset_avg_princ_close.prototype.clearAssetTdyCumPrincWdtBcClose = function() {
  return this.setAssetTdyCumPrincWdtBcClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ_close.prototype.hasAssetTdyCumPrincWdtBcClose = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_tot_cum_princ_wdt_close = 2;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ_close.prototype.getAssetTotCumPrincWdtClose = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 2));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ_close} returns this
*/
proto.assets.principal.asset_avg_princ_close.prototype.setAssetTotCumPrincWdtClose = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ_close} returns this
 */
proto.assets.principal.asset_avg_princ_close.prototype.clearAssetTotCumPrincWdtClose = function() {
  return this.setAssetTotCumPrincWdtClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ_close.prototype.hasAssetTotCumPrincWdtClose = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_tot_cum_princ_wdt_bc_close = 3;
 * @return {?proto.assets.principal.decimal}
 */
proto.assets.principal.asset_avg_princ_close.prototype.getAssetTotCumPrincWdtBcClose = function() {
  return /** @type{?proto.assets.principal.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.decimal, 3));
};


/**
 * @param {?proto.assets.principal.decimal|undefined} value
 * @return {!proto.assets.principal.asset_avg_princ_close} returns this
*/
proto.assets.principal.asset_avg_princ_close.prototype.setAssetTotCumPrincWdtBcClose = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.asset_avg_princ_close} returns this
 */
proto.assets.principal.asset_avg_princ_close.prototype.clearAssetTotCumPrincWdtBcClose = function() {
  return this.setAssetTotCumPrincWdtBcClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_avg_princ_close.prototype.hasAssetTotCumPrincWdtBcClose = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.asset_dt_princ_close_op.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.asset_dt_princ_close_op} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_dt_princ_close_op.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyClosedDtPrincLngPos: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    assetTdyClosedDtPrincShrPos: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    assetTdyClosedDtPrincLngNeg: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    assetTdyClosedDtPrincShrNeg: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    assetTotDtPrincClose: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.asset_dt_princ_close_op}
 */
proto.assets.principal.asset_dt_princ_close_op.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.asset_dt_princ_close_op;
  return proto.assets.principal.asset_dt_princ_close_op.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.asset_dt_princ_close_op} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.asset_dt_princ_close_op}
 */
proto.assets.principal.asset_dt_princ_close_op.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyClosedDtPrincLngPos(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyClosedDtPrincShrPos(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyClosedDtPrincLngNeg(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyClosedDtPrincShrNeg(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTotDtPrincClose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.asset_dt_princ_close_op.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.asset_dt_princ_close_op} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.asset_dt_princ_close_op.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional uint64 asset_tdy_closed_dt_princ_lng_pos = 1;
 * @return {number}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.getAssetTdyClosedDtPrincLngPos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.setAssetTdyClosedDtPrincLngPos = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.clearAssetTdyClosedDtPrincLngPos = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.hasAssetTdyClosedDtPrincLngPos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 asset_tdy_closed_dt_princ_shr_pos = 2;
 * @return {number}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.getAssetTdyClosedDtPrincShrPos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.setAssetTdyClosedDtPrincShrPos = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.clearAssetTdyClosedDtPrincShrPos = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.hasAssetTdyClosedDtPrincShrPos = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 asset_tdy_closed_dt_princ_lng_neg = 3;
 * @return {number}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.getAssetTdyClosedDtPrincLngNeg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.setAssetTdyClosedDtPrincLngNeg = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.clearAssetTdyClosedDtPrincLngNeg = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.hasAssetTdyClosedDtPrincLngNeg = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 asset_tdy_closed_dt_princ_shr_neg = 4;
 * @return {number}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.getAssetTdyClosedDtPrincShrNeg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.setAssetTdyClosedDtPrincShrNeg = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.clearAssetTdyClosedDtPrincShrNeg = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.hasAssetTdyClosedDtPrincShrNeg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 asset_tot_dt_princ_close = 5;
 * @return {number}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.getAssetTotDtPrincClose = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.setAssetTotDtPrincClose = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.asset_dt_princ_close_op} returns this
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.clearAssetTotDtPrincClose = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.asset_dt_princ_close_op.prototype.hasAssetTotDtPrincClose = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.update_payload.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.update_payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.update_payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.update_payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetCumWdtPrincOp: (f = msg.getAssetCumWdtPrincOp()) && proto.assets.principal.asset_cum_wdt_princ_op.toObject(includeInstance, f),
    assetWdtPrincCloseOp: (f = msg.getAssetWdtPrincCloseOp()) && proto.assets.principal.asset_wdt_princ_close_op.toObject(includeInstance, f),
    assetAvgPrinc: (f = msg.getAssetAvgPrinc()) && proto.assets.principal.asset_avg_princ.toObject(includeInstance, f),
    assetAvgPrincClose: (f = msg.getAssetAvgPrincClose()) && proto.assets.principal.asset_avg_princ_close.toObject(includeInstance, f),
    assetDtPrincCloseOp: (f = msg.getAssetDtPrincCloseOp()) && proto.assets.principal.asset_dt_princ_close_op.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.update_payload}
 */
proto.assets.principal.update_payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.update_payload;
  return proto.assets.principal.update_payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.update_payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.update_payload}
 */
proto.assets.principal.update_payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 25:
      var value = new proto.assets.principal.asset_cum_wdt_princ_op;
      reader.readMessage(value,proto.assets.principal.asset_cum_wdt_princ_op.deserializeBinaryFromReader);
      msg.setAssetCumWdtPrincOp(value);
      break;
    case 26:
      var value = new proto.assets.principal.asset_wdt_princ_close_op;
      reader.readMessage(value,proto.assets.principal.asset_wdt_princ_close_op.deserializeBinaryFromReader);
      msg.setAssetWdtPrincCloseOp(value);
      break;
    case 27:
      var value = new proto.assets.principal.asset_avg_princ;
      reader.readMessage(value,proto.assets.principal.asset_avg_princ.deserializeBinaryFromReader);
      msg.setAssetAvgPrinc(value);
      break;
    case 28:
      var value = new proto.assets.principal.asset_avg_princ_close;
      reader.readMessage(value,proto.assets.principal.asset_avg_princ_close.deserializeBinaryFromReader);
      msg.setAssetAvgPrincClose(value);
      break;
    case 29:
      var value = new proto.assets.principal.asset_dt_princ_close_op;
      reader.readMessage(value,proto.assets.principal.asset_dt_princ_close_op.deserializeBinaryFromReader);
      msg.setAssetDtPrincCloseOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.update_payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.update_payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.update_payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.update_payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetCumWdtPrincOp();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.assets.principal.asset_cum_wdt_princ_op.serializeBinaryToWriter
    );
  }
  f = message.getAssetWdtPrincCloseOp();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.assets.principal.asset_wdt_princ_close_op.serializeBinaryToWriter
    );
  }
  f = message.getAssetAvgPrinc();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.assets.principal.asset_avg_princ.serializeBinaryToWriter
    );
  }
  f = message.getAssetAvgPrincClose();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.assets.principal.asset_avg_princ_close.serializeBinaryToWriter
    );
  }
  f = message.getAssetDtPrincCloseOp();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.assets.principal.asset_dt_princ_close_op.serializeBinaryToWriter
    );
  }
};


/**
 * optional asset_cum_wdt_princ_op asset_cum_wdt_princ_op_ = 25;
 * @return {?proto.assets.principal.asset_cum_wdt_princ_op}
 */
proto.assets.principal.update_payload.prototype.getAssetCumWdtPrincOp = function() {
  return /** @type{?proto.assets.principal.asset_cum_wdt_princ_op} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.asset_cum_wdt_princ_op, 25));
};


/**
 * @param {?proto.assets.principal.asset_cum_wdt_princ_op|undefined} value
 * @return {!proto.assets.principal.update_payload} returns this
*/
proto.assets.principal.update_payload.prototype.setAssetCumWdtPrincOp = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.update_payload} returns this
 */
proto.assets.principal.update_payload.prototype.clearAssetCumWdtPrincOp = function() {
  return this.setAssetCumWdtPrincOp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_payload.prototype.hasAssetCumWdtPrincOp = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional asset_wdt_princ_close_op asset_wdt_princ_close_op_ = 26;
 * @return {?proto.assets.principal.asset_wdt_princ_close_op}
 */
proto.assets.principal.update_payload.prototype.getAssetWdtPrincCloseOp = function() {
  return /** @type{?proto.assets.principal.asset_wdt_princ_close_op} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.asset_wdt_princ_close_op, 26));
};


/**
 * @param {?proto.assets.principal.asset_wdt_princ_close_op|undefined} value
 * @return {!proto.assets.principal.update_payload} returns this
*/
proto.assets.principal.update_payload.prototype.setAssetWdtPrincCloseOp = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.update_payload} returns this
 */
proto.assets.principal.update_payload.prototype.clearAssetWdtPrincCloseOp = function() {
  return this.setAssetWdtPrincCloseOp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_payload.prototype.hasAssetWdtPrincCloseOp = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional asset_avg_princ asset_avg_princ_ = 27;
 * @return {?proto.assets.principal.asset_avg_princ}
 */
proto.assets.principal.update_payload.prototype.getAssetAvgPrinc = function() {
  return /** @type{?proto.assets.principal.asset_avg_princ} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.asset_avg_princ, 27));
};


/**
 * @param {?proto.assets.principal.asset_avg_princ|undefined} value
 * @return {!proto.assets.principal.update_payload} returns this
*/
proto.assets.principal.update_payload.prototype.setAssetAvgPrinc = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.update_payload} returns this
 */
proto.assets.principal.update_payload.prototype.clearAssetAvgPrinc = function() {
  return this.setAssetAvgPrinc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_payload.prototype.hasAssetAvgPrinc = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional asset_avg_princ_close asset_avg_princ_close_ = 28;
 * @return {?proto.assets.principal.asset_avg_princ_close}
 */
proto.assets.principal.update_payload.prototype.getAssetAvgPrincClose = function() {
  return /** @type{?proto.assets.principal.asset_avg_princ_close} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.asset_avg_princ_close, 28));
};


/**
 * @param {?proto.assets.principal.asset_avg_princ_close|undefined} value
 * @return {!proto.assets.principal.update_payload} returns this
*/
proto.assets.principal.update_payload.prototype.setAssetAvgPrincClose = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.update_payload} returns this
 */
proto.assets.principal.update_payload.prototype.clearAssetAvgPrincClose = function() {
  return this.setAssetAvgPrincClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_payload.prototype.hasAssetAvgPrincClose = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional asset_dt_princ_close_op asset_dt_princ_close_op_ = 29;
 * @return {?proto.assets.principal.asset_dt_princ_close_op}
 */
proto.assets.principal.update_payload.prototype.getAssetDtPrincCloseOp = function() {
  return /** @type{?proto.assets.principal.asset_dt_princ_close_op} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.asset_dt_princ_close_op, 29));
};


/**
 * @param {?proto.assets.principal.asset_dt_princ_close_op|undefined} value
 * @return {!proto.assets.principal.update_payload} returns this
*/
proto.assets.principal.update_payload.prototype.setAssetDtPrincCloseOp = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.update_payload} returns this
 */
proto.assets.principal.update_payload.prototype.clearAssetDtPrincCloseOp = function() {
  return this.setAssetDtPrincCloseOp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_payload.prototype.hasAssetDtPrincCloseOp = function() {
  return jspb.Message.getField(this, 29) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.update_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.update_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.update_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.update_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.principal.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.principal.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.update_snapshot}
 */
proto.assets.principal.update_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.update_snapshot;
  return proto.assets.principal.update_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.update_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.update_snapshot}
 */
proto.assets.principal.update_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.principal.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.assets.principal.tstamp;
      reader.readMessage(value,proto.assets.principal.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 6:
      var value = new proto.assets.principal.update_payload;
      reader.readMessage(value,proto.assets.principal.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.update_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.update_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.update_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.update_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.principal.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.principal.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.principal.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.principal.update_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.principal.update_type}
 */
proto.assets.principal.update_snapshot.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.principal.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.principal.update_type} value
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_snapshot.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.principal.update_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string key = 4;
 * @return {string}
 */
proto.assets.principal.update_snapshot.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.clearKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_snapshot.prototype.hasKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required tstamp event_ts = 5;
 * @return {!proto.assets.principal.tstamp}
 */
proto.assets.principal.update_snapshot.prototype.getEventTs = function() {
  return /** @type{!proto.assets.principal.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.tstamp, 5, 1));
};


/**
 * @param {!proto.assets.principal.tstamp} value
 * @return {!proto.assets.principal.update_snapshot} returns this
*/
proto.assets.principal.update_snapshot.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_snapshot.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional update_payload sigmas = 6;
 * @return {?proto.assets.principal.update_payload}
 */
proto.assets.principal.update_snapshot.prototype.getSigmas = function() {
  return /** @type{?proto.assets.principal.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.update_payload, 6));
};


/**
 * @param {?proto.assets.principal.update_payload|undefined} value
 * @return {!proto.assets.principal.update_snapshot} returns this
*/
proto.assets.principal.update_snapshot.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.update_snapshot} returns this
 */
proto.assets.principal.update_snapshot.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_snapshot.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.end_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.end_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.end_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.end_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.end_snapshot}
 */
proto.assets.principal.end_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.end_snapshot;
  return proto.assets.principal.end_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.end_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.end_snapshot}
 */
proto.assets.principal.end_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.end_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.end_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.end_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.end_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.principal.end_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.end_snapshot} returns this
 */
proto.assets.principal.end_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.end_snapshot} returns this
 */
proto.assets.principal.end_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.end_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string service = 2;
 * @return {string}
 */
proto.assets.principal.end_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.principal.end_snapshot} returns this
 */
proto.assets.principal.end_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.end_snapshot} returns this
 */
proto.assets.principal.end_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.end_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.update_ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.update_ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.update_ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.update_ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    sequence: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.principal.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.principal.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.update_ticker}
 */
proto.assets.principal.update_ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.update_ticker;
  return proto.assets.principal.update_ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.update_ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.update_ticker}
 */
proto.assets.principal.update_ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.principal.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSequence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = new proto.assets.principal.tstamp;
      reader.readMessage(value,proto.assets.principal.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 7:
      var value = new proto.assets.principal.update_payload;
      reader.readMessage(value,proto.assets.principal.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.update_ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.update_ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.update_ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.update_ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.principal.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.principal.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.principal.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.principal.update_ticker.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_ticker.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.principal.update_type}
 */
proto.assets.principal.update_ticker.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.principal.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.principal.update_type} value
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_ticker.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.principal.update_ticker.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_ticker.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required sint64 sequence = 4;
 * @return {number}
 */
proto.assets.principal.update_ticker.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.setSequence = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.clearSequence = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_ticker.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string key = 5;
 * @return {string}
 */
proto.assets.principal.update_ticker.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.clearKey = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_ticker.prototype.hasKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp event_ts = 6;
 * @return {!proto.assets.principal.tstamp}
 */
proto.assets.principal.update_ticker.prototype.getEventTs = function() {
  return /** @type{!proto.assets.principal.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.tstamp, 6, 1));
};


/**
 * @param {!proto.assets.principal.tstamp} value
 * @return {!proto.assets.principal.update_ticker} returns this
*/
proto.assets.principal.update_ticker.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_ticker.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional update_payload sigmas = 7;
 * @return {?proto.assets.principal.update_payload}
 */
proto.assets.principal.update_ticker.prototype.getSigmas = function() {
  return /** @type{?proto.assets.principal.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.update_payload, 7));
};


/**
 * @param {?proto.assets.principal.update_payload|undefined} value
 * @return {!proto.assets.principal.update_ticker} returns this
*/
proto.assets.principal.update_ticker.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.update_ticker} returns this
 */
proto.assets.principal.update_ticker.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.update_ticker.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.alive.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.alive.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliveId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.alive}
 */
proto.assets.principal.alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.alive;
  return proto.assets.principal.alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.alive}
 */
proto.assets.principal.alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAliveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
};


/**
 * required sint64 alive_id = 1;
 * @return {number}
 */
proto.assets.principal.alive.prototype.getAliveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.principal.alive} returns this
 */
proto.assets.principal.alive.prototype.setAliveId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.principal.alive} returns this
 */
proto.assets.principal.alive.prototype.clearAliveId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.alive.prototype.hasAliveId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.assets.principal.feed_event.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.assets.principal.feed_event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  SNAPSHOT: 1,
  TICKER: 2,
  END_SNAPSHOT: 3,
  ALIVE: 4
};

/**
 * @return {proto.assets.principal.feed_event.EventTypeCase}
 */
proto.assets.principal.feed_event.prototype.getEventTypeCase = function() {
  return /** @type {proto.assets.principal.feed_event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.assets.principal.feed_event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.principal.feed_event.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.principal.feed_event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.principal.feed_event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.feed_event.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.assets.principal.update_snapshot.toObject(includeInstance, f),
    ticker: (f = msg.getTicker()) && proto.assets.principal.update_ticker.toObject(includeInstance, f),
    endSnapshot: (f = msg.getEndSnapshot()) && proto.assets.principal.end_snapshot.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.assets.principal.alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.principal.feed_event}
 */
proto.assets.principal.feed_event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.principal.feed_event;
  return proto.assets.principal.feed_event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.principal.feed_event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.principal.feed_event}
 */
proto.assets.principal.feed_event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.principal.update_snapshot;
      reader.readMessage(value,proto.assets.principal.update_snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 2:
      var value = new proto.assets.principal.update_ticker;
      reader.readMessage(value,proto.assets.principal.update_ticker.deserializeBinaryFromReader);
      msg.setTicker(value);
      break;
    case 3:
      var value = new proto.assets.principal.end_snapshot;
      reader.readMessage(value,proto.assets.principal.end_snapshot.deserializeBinaryFromReader);
      msg.setEndSnapshot(value);
      break;
    case 4:
      var value = new proto.assets.principal.alive;
      reader.readMessage(value,proto.assets.principal.alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.principal.feed_event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.principal.feed_event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.principal.feed_event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.principal.feed_event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.principal.update_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getTicker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.principal.update_ticker.serializeBinaryToWriter
    );
  }
  f = message.getEndSnapshot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.principal.end_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.principal.alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional update_snapshot snapshot = 1;
 * @return {?proto.assets.principal.update_snapshot}
 */
proto.assets.principal.feed_event.prototype.getSnapshot = function() {
  return /** @type{?proto.assets.principal.update_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.update_snapshot, 1));
};


/**
 * @param {?proto.assets.principal.update_snapshot|undefined} value
 * @return {!proto.assets.principal.feed_event} returns this
*/
proto.assets.principal.feed_event.prototype.setSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.assets.principal.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.feed_event} returns this
 */
proto.assets.principal.feed_event.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.feed_event.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional update_ticker ticker = 2;
 * @return {?proto.assets.principal.update_ticker}
 */
proto.assets.principal.feed_event.prototype.getTicker = function() {
  return /** @type{?proto.assets.principal.update_ticker} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.update_ticker, 2));
};


/**
 * @param {?proto.assets.principal.update_ticker|undefined} value
 * @return {!proto.assets.principal.feed_event} returns this
*/
proto.assets.principal.feed_event.prototype.setTicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.assets.principal.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.feed_event} returns this
 */
proto.assets.principal.feed_event.prototype.clearTicker = function() {
  return this.setTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.feed_event.prototype.hasTicker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional end_snapshot end_snapshot = 3;
 * @return {?proto.assets.principal.end_snapshot}
 */
proto.assets.principal.feed_event.prototype.getEndSnapshot = function() {
  return /** @type{?proto.assets.principal.end_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.end_snapshot, 3));
};


/**
 * @param {?proto.assets.principal.end_snapshot|undefined} value
 * @return {!proto.assets.principal.feed_event} returns this
*/
proto.assets.principal.feed_event.prototype.setEndSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.assets.principal.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.feed_event} returns this
 */
proto.assets.principal.feed_event.prototype.clearEndSnapshot = function() {
  return this.setEndSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.feed_event.prototype.hasEndSnapshot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional alive alive = 4;
 * @return {?proto.assets.principal.alive}
 */
proto.assets.principal.feed_event.prototype.getAlive = function() {
  return /** @type{?proto.assets.principal.alive} */ (
    jspb.Message.getWrapperField(this, proto.assets.principal.alive, 4));
};


/**
 * @param {?proto.assets.principal.alive|undefined} value
 * @return {!proto.assets.principal.feed_event} returns this
*/
proto.assets.principal.feed_event.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.assets.principal.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.principal.feed_event} returns this
 */
proto.assets.principal.feed_event.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.principal.feed_event.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.assets.principal.update_type = {
  SNAPSHOT: 1,
  VARIATIONS: 2
};

/**
 * @enum {number}
 */
proto.assets.principal.svc_status = {
  ONLINE: 0,
  OFFLINE: 1,
  BROKEN: 2
};

goog.object.extend(exports, proto.assets.principal);
