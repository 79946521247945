import produce from 'immer'
import * as K from './constants'
import * as Store from 'types/store'
import { AnyAction } from 'redux'

// The initial state of the Reducer
export const initialState: Store.StrategyPageState = {
  currentAction: { node: null, operation: null },
  usedResources: {
    assets: [],
    portfolios: [],
    strategies: [],
  },
}

const reducer = (state = initialState, action: AnyAction): Store.StrategyPageState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.SET_CURRENT:
        draft.currentAction = action.payload as Store.UserAction
        break
      case K.RESET_CURRENT:
        draft.currentAction = { node: null, operation: null }
        break
      case K.FETCH_RUNNING_ASSETS_SUCCESS:
        draft.usedResources = action.payload as any
        break
    }
  })
}

export default reducer
