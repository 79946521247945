import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import moment from 'moment'
const axios = getAxios()
const DATE_FORMAT = 'YYYY-MM-DD'

const assetFields = `{
    e_long_short {
      code
      description
    }
    name
    bank_account
    broker_account
    id
    association_userid
    min_order_countervalue
    min_order_qty
    name
    qty_f_countervalue
    ref_countervalue
    ref_countervalue_max
    ref_countervalue_min
    ref_qty
    ref_qty_max
    ref_qty_min
    security_id
    size_variable
    portfolio_id
    model_class_id
    master_security {
      symbol
      trading_currency
      description
      lot_multiplier
      contract_size
      instrument_code
      exchangeByExchange {
        mic
        custom_code
      }
      e_instrument_class {
        description
      }
    }
    model_class {
      model_name
      model_type
      model_language
      model_description
      version_number
    }
    model_asset_parameters {
      type
      value
      name
    }
    brokerAccountByBrokerAccount {
      broker_name
    }
    portfolio {
      strategy {
        base_currency
      }
    }
  }
}`

export async function getAssets(): Promise<Api.AssetsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      application_asset(where: {deletion_timestamp: {_is_null: true}}) ${assetFields}`,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getAsset(id: number): Promise<Api.AssetsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    _id: id,
  }
  const data: GraphQlQuery = {
    query: `query ($_id: Int) {
      application_asset(where: {id: {_eq: $_id}}) ${assetFields}`,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function createAsset(data: Api.AssetRest): Promise<Api.AssetCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .post(config.api.createAsset(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function updateAsset(data: Api.AssetRest): Promise<Api.AssetCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.updateAsset(data.id), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function deleteAsset(id: number): Promise<Api.AssetCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .delete(config.api.deleteAsset(id), header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getSymbols(symbol: string): Promise<Api.MasterSecurityResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    symbol: `${symbol.toUpperCase()}%`,
    date: moment().format(DATE_FORMAT),
  }
  const data: GraphQlQuery = {
    query: `query ($symbol: String, $date: date) {
      application_master_security(
        distinct_on: symbol,
        where: {
          _and: {
            symbol: {_similar: $symbol}, 
            _or: [
              {end_trading_date: {_is_null: true}}, 
              {end_trading_date: {_gte: $date}}
            ]
          }}) {
        symbol
      }
    }`,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getMICs(symbol: string): Promise<Api.ExchangeResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    symbol: symbol.toUpperCase(),
    date: moment().format(DATE_FORMAT),
  }

  const data: GraphQlQuery = {
    query: `query ($symbol: String, $date: date) {
      application_exchange(
        where: {
          master_securities: {
            symbol: {_eq: $symbol },
            _or: [
              {end_trading_date: {_is_null: true}}, 
              {end_trading_date: {_gte: $date}}
            ]
            }
            }) {
      mic
      }
    }`,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getMarket(symbol: string): Promise<Api.ExchangeResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    symbol,
    date: moment().format(DATE_FORMAT),
  }
  const data: GraphQlQuery = {
    query: `query ($date: date,  $symbol: String) {
      application_exchange(where: {
        master_securities: {
          symbol: {_eq: $symbol},
          _or: [
            {end_trading_date: {_is_null: true}}, 
            {end_trading_date: {_gte: $date}}
          ]
        }}) {
        code
        custom_code
      }
    }
    `,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getSecurityId(
  mic: string,
  symbol: string,
  currency: string,
): Promise<Api.MasterSecurityResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    mic: mic.toUpperCase(),
    symbol: symbol.toUpperCase(),
    currency: currency.toUpperCase(),
  }

  const data: GraphQlQuery = {
    query: `query (
      $symbol:String,
      $currency:String,
      $mic:String
    ) {
      application_master_security(
        where: {
          symbol: {_eq: $symbol},
          currency: {code: {_eq: $currency}}
          exchangeByExchange:{
            mic:{_eq: $mic},
          }
        }) {
        id
      }
    }`,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getCurrencies(
  mic: string,
  symbol: string,
): Promise<Api.MasterSecurityResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    mic: mic.toUpperCase(),
    symbol: symbol.toUpperCase(),
  }

  const data: GraphQlQuery = {
    query: `query ($mic: String, $symbol: String) {
      application_master_security(where: {
        symbol: {_eq: $symbol},
        _and: {exchangeByExchange: {mic: {_eq: $mic}}}
      }) {
        trading_currency
      }
    }
    `,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getModelParameters(id: number): Promise<Api.ModelParametersResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    id,
  }

  const data: GraphQlQuery = {
    query: `query ($id: Int) {
      application_model_class_parameter(where: {model_class_id: {_eq: $id}}) {
        name
        type
        default_value
      }
    }`,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getHistoricalModelParameters(
  id: number,
): Promise<Api.ModelParametersHistoricalResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    id,
  }

  const data: GraphQlQuery = {
    query: `query ($id: Int) {
      historical_data_model_asset_parameter_v(where: {operative_session_id: {_eq: $id}}) {
        name
        type
        value
      }
    }`,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getDescription(
  mic: string,
  symbol: string,
  currency: string,
): Promise<Api.MasterSecurityResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    mic: mic.toUpperCase(),
    symbol: symbol.toUpperCase(),
    currency: currency.toUpperCase(),
  }

  const data: GraphQlQuery = {
    query: `query ($mic: String, $symbol: String, $currency: String) {
      application_master_security(
        where: {symbol: {_eq: $symbol},
        _and: {trading_currency: {_eq: $currency},
        _and: {exchangeByExchange: {mic: {_eq: $mic}}}}})
      {
        id
        e_instrument_class {
          description
        }
        description
        lot_multiplier
        contract_size
      }
    }
    `,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getModelClasses(): Promise<Api.ModelClassResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      application_model_class {
        id
        model_description
        model_language
        model_name
        model_type
        commit_timestamp
        creation_username
        version_number
      }
    }
    `,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getTypeOfPosition(): Promise<Api.TypeOfPostionsEnabledResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `{
      application_e_long_short {
        code
        description
      }
    }`,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getBrokerSecurities(
  symbol: string,
  mic: string,
  currency: string,
): Promise<Api.BrokerSecurityResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    symbol,
    mic,
    currency,
  }

  const data: GraphQlQuery = {
    query: `query ($symbol: String, $mic: String, $currency: String) {
      application_broker_security(
        where: {
          master_security: {
            symbol: {_eq: $symbol}, currency: {code: {_eq: $currency}}, exchangeByExchange: {mic: {_eq: $mic}}}}) {
        broker_name
      }
    }
    `,
    variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getBrokerAccounts(broker: string): Promise<Api.BrokerSecurityResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    broker,
  }
  const data: GraphQlQuery = {
    query: `query ($broker:String) {
      application_broker_account(where:
        {broker_name: {_eq: $broker}}) {
      account
      }
    }`,
    variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getBankAccounts(
  currency: string,
  mic: string,
  symbol: string,
  market: string,
): Promise<Api.ExchangeResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const variables = {
    symbol,
    mic,
    market,
    currency,
  }
  const data: GraphQlQuery = {
    query: `query ($currency: String , $symbol: String , $mic: String, $market: String) {
      application_bank_account_security(
        distinct_on: bank_account,
        where: {master_security:
          {
            symbol: {_eq: $symbol},
            exchangeByExchange: {
              mic: {_eq: $mic},
              custom_code: {_eq: $market}
            },
            trading_currency: {_eq: $currency}
          }
        }) {
        bank_account
        master_security {
          id
          exchangeByExchange {
            code
            custom_code
            mic
          }
          exchange
        }
      }
    }
    `,
    variables: variables,
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
