import { Button, Col, Form, Input, InputNumber, Row, Space, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import AppContentCard from 'components/AppContentCard'
import { withCurrencyFormat } from 'components/hoc'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography
interface Props {
  form: FormInstance
  onFinish: (data: Json) => void
  onCancel: () => void
  loading: boolean
}

const onlyPositive = (value: number) => (value >= 0 ? value : 0)

const PortfolioForm: React.FC<Props> = ({ form, loading, onFinish, onCancel }) => {
  const { t } = useTranslation()
  const CurrencyInput = withCurrencyFormat(InputNumber)

  return (
    <AppContentCard fullHeight>
      <Form form={form} onFinish={onFinish}>
        <Row align="middle" className="col-title">
          <Col span={12}>
            <Title>{t('general.portfolio')}</Title>
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Space>
                <Form.Item className="mb-0">
                  <Button type="dashed" loading={loading} disabled={loading} onClick={onCancel}>
                    {t('general.cancel')}
                  </Button>
                </Form.Item>

                <Form.Item className="mb-0">
                  <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                    {t('general.save')}
                  </Button>
                </Form.Item>
              </Space>
            </Row>
          </Col>
        </Row>

        <Title level={2} className="primary-color mb-10">
          {t('general.description')}
        </Title>
        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>
            <Paragraph>{t('general.name')}</Paragraph>
          </Col>
          <Col span={12}>
            <Form.Item name="name" rules={[{ required: true, min: 0, message: t('error.requiredField') }]}>
              <Input placeholder={t('general.name')} />
            </Form.Item>
          </Col>
        </Row>

        <Title level={2} className="primary-color mt-24 mb-10">
          {t('general.management')}
        </Title>
        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>
            <Paragraph>{t('portfolios.maxLong')}</Paragraph>
          </Col>
          <Col span={12}>
            <Form.Item
              name="maxLong"
              rules={[{ required: true, min: 0, type: 'number', message: t('error.requiredField') }]}
              normalize={onlyPositive}
            >
              <CurrencyInput placeholder={t('portfolios.maxLong')} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Typography.Text className="field-description">
              {t('portfolios.maxLongDescription')}
            </Typography.Text>
          </Col>
        </Row>

        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>
            <Paragraph>{t('portfolios.maxShort')}</Paragraph>
          </Col>
          <Col span={12}>
            <Form.Item
              name="maxShort"
              rules={[{ required: true, min: 0, type: 'number', message: t('error.requiredField') }]}
              normalize={onlyPositive}
            >
              <CurrencyInput placeholder={t('portfolios.maxShort')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Typography.Text className="field-description">
              {t('portfolios.maxShortDescription')}
            </Typography.Text>
          </Col>
        </Row>

        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>
            <Paragraph>{t('portfolios.maxNetHedging')}</Paragraph>
          </Col>
          <Col span={12}>
            <Form.Item
              name="maxNetHedging"
              rules={[{ required: true, min: 0, type: 'number', message: t('error.requiredField') }]}
              normalize={onlyPositive}
            >
              <CurrencyInput placeholder={t('portfolios.maxNetHedging')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Typography.Text className="field-description">
              {t('portfolios.maxNetHedgingDescription')}
            </Typography.Text>
          </Col>
        </Row>

        <Row align="middle" gutter={[5, 0]}>
          <Col span={12}>
            <Paragraph>{t('portfolios.minNetHedging')}</Paragraph>
          </Col>
          <Col span={12}>
            <Form.Item name="minNetHedging" rules={[{ required: true, message: t('error.requiredField') }]}>
              <CurrencyInput placeholder={t('portfolios.minNetHedging')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Typography.Text className="field-description">
              {t('portfolios.minNetHedgingDescription')}
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    </AppContentCard>
  )
}

export default PortfolioForm
