import React, { useCallback, useContext, useState } from 'react'

import * as Store from 'types/store'
import * as Api from 'types/api'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'

import { useSelector } from 'react-redux'
import { AssetFeed, Feeds, Sigma } from 'types/enums.api'
import CandleStick from 'v2/components/CandleStick'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { filterEmptyData, getDateLimits, hasEmptyData } from 'core/helpers'
import { TimeFrame, TSInterval } from 'types/enums'
import Spinner from 'components/Spinner'
import CandlestickWrapper from 'v2/components/CandlestickWrapper'
import { jsonToCandleStick, jsonToClosedOperation, jsonToUnitPerformance } from 'services/store/mapTimeSeries'
import Debug from 'debug'
import { isApiError } from 'core'
import * as api from 'api/candleStick'
import * as historicalApi from 'api/historicalResources'
import {
  mapHistoricalAssetOpenCandle,
  mapHistoricalOpenOperation,
  mapHistoricalOpenReturn,
} from 'services/store/mapHistoricals'
import { getExtendedDates, getInterval } from 'services/chartsService'
import sigmaServices from 'config/sigmaServices'

const debug = Debug('sockets')

const {
  SecurityData: { AssetClosedCandle },
  Return: { AssetClosedUnitRet },
  Operations: { ClosedOperation },
} = sigmaServices.Assets

type OpenData = {
  candles: Store.CandleStick[]
  lines: Store.UnitPerformance[]
  operations: Store.Operation[]
}
interface Props {
  asset: Store.Asset
  width: number
  height: number
}

const mapTimeSeries = (
  data: Api.DataPoint[],
  sigma: Sigma,
): Store.UnitPerformance[] | Store.Operation[] | Store.CandleStick[] => {
  if (sigma === AssetClosedCandle) {
    return jsonToCandleStick(data)
  }
  if (sigma === AssetClosedUnitRet) {
    return jsonToUnitPerformance(data)
  }
  if (sigma === ClosedOperation) {
    return jsonToClosedOperation(data)
  }
}

const getTimeSeries = async (
  id: number,
  sigma: Sigma,
  interval: TSInterval,
  from: Date,
  to: Date,
  sessionId: number,
  feed: Feeds,
) => {
  const response = await api.getDataPoints(id, sigma, feed, interval, from, to, sessionId)

  if (isApiError(response)) {
    debug('ERROR getDataPoints')
    return []
  }

  const data = response as Api.DataPointFeed

  const mappedData = mapTimeSeries(data.datapoints, sigma)

  return mappedData
}

const getOpenChartData = async (id: number, sessionId: number, interval: TSInterval): Promise<OpenData> => {
  const response = await historicalApi.getAssetOpenChartData(id, sessionId, interval)

  if (isApiError(response)) {
    debug('ERROR getDataPoints')
    return null
  }

  const data = response as Api.HistoricalAssetOpenChartDataResponse
  const openCandleStick = data.data.historical_data_asset_open_candle_v.map(mapHistoricalAssetOpenCandle)
  const openLine = data.data.historical_data_asset_open_unit_ret_v.map(mapHistoricalOpenReturn)
  const openOperations = data.data.historical_data_open_operation_v.map(mapHistoricalOpenOperation)

  return { candles: openCandleStick, lines: openLine, operations: openOperations }
}

const AssetCandleStickContainer: React.FC<Props> = ({ asset, width, height }) => {
  const context = useContext(CandleStickFrameContext)
  const { timeFrame } = context
  const { id } = asset

  const session = useSelector(selectOperativeSessionSession)

  const sessionId = session.id
  const [openData, setOpenData] = useState<OpenData>({
    candles: [],
    lines: [],
    operations: [],
  })
  const [candleStickData, setCandleStickData] = useState<Store.CandleStick[]>([])
  const [lineData, setLineData] = useState<Store.UnitPerformance[]>([])
  const [operationsData, setOperationsData] = useState<Store.Operation[]>([])

  const getData = useCallback(
    async (
      id: number,
      interval: TSInterval,
      from: Date,
      to: Date,
      sessionId: number,
      sigma: Sigma,
      feed: Feeds,
    ) => {
      const data = await getTimeSeries(id, sigma, interval, from, to, sessionId, feed)
      const returnData = data.length > 0 ? data : ([{ empty: true }] as Store.ChartData[])
      switch (sigma) {
        case AssetClosedCandle:
          setCandleStickData(returnData as Store.CandleStick[])
          break
        case AssetClosedUnitRet:
          setLineData(returnData as Store.UnitPerformance[])
          break
        case ClosedOperation:
          setOperationsData(returnData as Store.Operation[])
          break
      }

      const openData = await getOpenChartData(id, sessionId, interval)
      if (openData) {
        setOpenData(openData)
      }
    },
    [],
  )

  const getFromTo = async (from: Date, to: Date) => {
    const [startTime, endTime] = getDateLimits(timeFrame, session)

    if (timeFrame === TimeFrame.TOT) {
      const interval = TSInterval.Day
      getData(id, interval, startTime, endTime, sessionId, AssetClosedCandle, AssetFeed.SecurityData)
      getData(id, interval, startTime, endTime, sessionId, AssetClosedUnitRet, AssetFeed.Return)
    } else {
      const [startFrom, endTo] = getExtendedDates(from, to, startTime, endTime)

      const interval = timeFrame === TimeFrame.TDY ? getInterval(from, to, null) : TSInterval.Day

      getData(id, interval, startFrom, endTo, sessionId, AssetClosedCandle, AssetFeed.SecurityData)
      getData(id, interval, startFrom, endTo, sessionId, AssetClosedUnitRet, AssetFeed.Return)
      if (timeFrame === TimeFrame.TDY) {
        getData(id, interval, startFrom, endTo, sessionId, ClosedOperation, AssetFeed.Operations)
      } else {
        setOperationsData([])
      }
    }
  }

  const noData = () => {
    return !hasEmptyData(candleStickData)
  }

  const hasSize = width > 0 && height > 0

  return (
    <CandlestickWrapper>
      {noData() && <Spinner />}
      {hasSize && (
        <CandleStick
          loading={noData()}
          timeFrame={timeFrame}
          session={session}
          candleStickData={filterEmptyData([...candleStickData, ...openData.candles]) as Store.CandleStick[]}
          lineChartData={filterEmptyData([...lineData, ...openData.lines]) as Store.UnitPerformance[]}
          operationsChartData={
            filterEmptyData([...operationsData, ...openData.operations]) as Store.Operation[]
          }
          currency={asset.currency}
          getFromTo={getFromTo}
          getXDomain={() => getDateLimits(timeFrame, session)}
          width={width}
          height={height}
          asset={asset}
        />
      )}
    </CandlestickWrapper>
  )
}

export default AssetCandleStickContainer
