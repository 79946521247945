import React from 'react'
import { ExtendedDataNode, TransferType } from 'types/ui'

import * as Store from 'types/store'
import classNames from 'classnames'
import { AssetStatus, TransferContext } from 'types/enums'
import { Typography } from 'antd'

import { getAssetStatusIcon } from 'helpers/transfer/core'

interface Props {
  node: ExtendedDataNode
  onClick: (node: ExtendedDataNode, type: TransferType) => void
  context: TransferContext
}

const AddAsset: React.FC<Props> = ({ node, onClick, context }) => {
  const transferType = node.selected ? TransferType.Remove : TransferType.Add
  const asset = node.resource as Store.Asset

  const disabled =
    context === TransferContext.AtSessionLaunch &&
    (!asset.modelClassId || asset.status === AssetStatus.Broken)

  const onEvent = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!disabled) {
      onClick(node, transferType)
    }
  }

  return (
    <span
      className={classNames('treenode-item', { 'selected-item': node.selected, 'disabled-item': disabled })}
      onClick={onEvent}
      onContextMenu={onEvent}
    >
      {getAssetStatusIcon(asset.status)}
      <Typography.Text ellipsis={{ tooltip: node.title }}>{node.title}</Typography.Text>
    </span>
  )
}

export default AddAsset
