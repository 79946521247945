import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { Row, Col, Typography, Button, Space, Menu, Dropdown, Table, Divider, Avatar, Popover } from 'antd'
const { Title, Paragraph } = Typography

import AppContentCard from 'components/AppContentCard'

import { CrudOperation, LoadingState } from 'types/enums'
import { useForm } from 'antd/lib/form/Form'
import PortfolioForm from './forms/PortfolioForm'
import { DownOutlined } from '@ant-design/icons'
import ReadAsset from './ReadAsset'
import AssetForm from 'containers/StrategyViewer/AssetForm'
import { ColumnsType } from 'antd/lib/table'
import * as Store from 'types/store'
import { formatNumber } from 'core/formats'
import CsvModal from 'containers/StrategyViewer/CsvModal'
import { ResourceAction } from 'types/store'
import { NodeType } from 'types/ui'
import EditIcon from 'assets/icon/edit-icon.svg'
import TrashIcon from 'assets/icon/trash-icon.svg'
import Toggle from 'assets/icon/menu-toggle.svg'
import classNames from 'classnames'

const WIDTH_FOR_EDITASSET = 1920

interface Props {
  portfolio: Store.Portfolio
  onFinish: (data: Json) => void
  assets: Array<Store.Asset>
  loadingState: LoadingState
  isEditing: boolean
  isReadOnly: boolean
  onCancel: () => void
  asset: Store.Asset
  usedAssets: number[]
  onItemClicked: (asset: Store.Asset, type: CrudOperation) => void
}

const ReadPortfolio: React.FC<Props> = ({
  assets,
  portfolio,
  loadingState,
  onFinish,
  isEditing,
  isReadOnly,
  asset,
  onCancel,
  onItemClicked,
  usedAssets,
}) => {
  const { t } = useTranslation()

  const [showPortfolio, setShowPortfolio] = useState(true)
  const [resourceAction, setResourceAction] = useState<ResourceAction>()
  const [selectedAsset, setSelectedAsset] = useState(-1)
  const [csvModalVisible, setCsvModalVisible] = useState(false)
  const { width } = useWindowSize()

  const isFullEditAsset = width < WIDTH_FOR_EDITASSET

  const [form] = useForm()

  useEffect(() => {
    form.setFieldsValue(portfolio)
    setResourceAction(null)
  }, [portfolio, isEditing])

  useEffect(() => {
    if (asset) {
      setResourceAction({ resource: asset, operation: CrudOperation.Read, type: NodeType.Asset })
      setSelectedAsset(asset.id)
    }
  }, [asset])

  const onAssetClick = (option) => {
    if (option.key === 'add') {
      setResourceAction({ resource: null, operation: CrudOperation.Create, type: NodeType.Asset })
    } else {
      setCsvModalVisible(true)
    }
  }

  const editAsset = (asset: Store.Asset) => {
    setResourceAction({ resource: asset, operation: CrudOperation.Update, type: NodeType.Asset })
  }

  const createAssetTitle = (name, asset: Store.Asset): ReactNode => {
    const assetClass = classNames({
      selected: selectedAsset === asset.id,
      'has-model': asset.modelClass,
    })

    if (!asset.modelClass)
      return (
        <Title level={3} className={assetClass}>
          {name}
        </Title>
      )

    const modelName = asset.modelClass.name
    const { version } = asset.modelClass

    const popover = (
      <>
        <div>{t('assets.analysisModel', { modelName, version })}</div>
      </>
    )

    return (
      <Popover
        overlayClassName="assets-popover"
        placement="top"
        content={popover}
        trigger="hover"
        zIndex={50}
      >
        <Title level={3} className={assetClass}>
          <span className="text">{name}</span>&nbsp;<span className="info-icon"></span>
        </Title>
      </Popover>
    )
  }

  const menu = (
    <Menu onClick={onAssetClick}>
      <Menu.Item key="add">{t('assets.addNew')}</Menu.Item>
      <Menu.Item key="csv">{t('assets.uploadCsv')}</Menu.Item>
    </Menu>
  )

  /* eslint-disable */
  const assetColumns: ColumnsType<Store.Asset> = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (name, asset: Store.Asset) => {
        const click = () => {
          setResourceAction({ resource: asset, operation: CrudOperation.Read, type: NodeType.Asset })
          setSelectedAsset(asset.id)
        }

        return <div onClick={click}>{createAssetTitle(name, asset)}</div>
      },
    },
    {
      dataIndex: 'id',
      key: 'id',
      className: 'align-right',
      render: (id, asset: Store.Asset) => {
        if (isReadOnly) {
          return null
        }
        if (usedAssets.includes(id)) {
          return null
        }

        return (
          <section>
            <Space direction="horizontal">
              <Button type="ghost" key="copy-action" className="icon-action" onClick={() => editAsset(asset)}>
                <Avatar shape="square" src={EditIcon} size={16} />
              </Button>

              <Button
                type="ghost"
                key="delete-action"
                className="icon-action"
                onClick={() => onItemClicked(asset, CrudOperation.Delete)}
              >
                <Avatar shape="square" src={TrashIcon} size={16} />
              </Button>
            </Space>
          </section>
        )
      },
    },
  ]
  /* eslint-enable */

  const onAssetClose = () => {
    setResourceAction(null)
    //Scroll to the top
    window.scrollTo(0, 0)
  }

  const onAssetCancel = (asset: Store.Asset) => {
    setResourceAction({ resource: asset, operation: CrudOperation.Read, type: NodeType.Asset })
  }

  const renderAsset = () => {
    if (!resourceAction) {
      return null
    }

    const { operation } = resourceAction

    switch (operation) {
      case CrudOperation.Create:
        return (
          <AssetForm
            portfolioId={portfolio.id}
            asset={null}
            operation={CrudOperation.Create}
            onClose={onAssetClose}
          />
        )
      case CrudOperation.Update: {
        const asset = resourceAction.resource as Store.Asset
        return (
          <AssetForm
            portfolioId={asset.portfolioId}
            asset={asset}
            operation={CrudOperation.Update}
            onClose={onAssetCancel}
          />
        )
      }

      default: {
        const asset = resourceAction.resource as Store.Asset
        const realAsset = assets.find(({ id }) => id === asset.id)
        return <ReadAsset asset={realAsset} />
      }
    }
  }

  const renderPortfolioAndAssets = (): ReactNode => {
    return (
      <>
        {isEditing && (
          <Col span={8} className="col-visible">
            <PortfolioForm
              form={form}
              loading={loadingState === LoadingState.Updating}
              onFinish={onFinish}
              onCancel={onCancel}
            />
          </Col>
        )}

        {!isEditing && (
          <Col span={8} className={showPortfolio ? 'col-visible full-height' : 'col-hidden'}>
            <AppContentCard fullHeight>
              <Row align="middle" className="col-title">
                <Col span={20} className="title">
                  <Button type="ghost" size="small" className="col-trigger">
                    <Title>{t('general.portfolio')}</Title>
                  </Button>
                </Col>
              </Row>

              <Button className="menu-toggle" type="ghost" onClick={() => setShowPortfolio(!showPortfolio)}>
                <img src={Toggle} width={32} height={32} alt="Toggle" />
              </Button>

              <section className="inner-content">
                <Title level={2} className="primary-color mb-10">
                  {t('general.description')}
                </Title>
                <Title level={3} className="mb-16">
                  {portfolio.name}
                </Title>
                <Title level={2} className="primary-color mt-24 mb-10">
                  {t('general.management')}
                </Title>

                <Row>
                  <Col span={9}>
                    <Paragraph>{t('portfolios.maxLong')}</Paragraph>
                  </Col>
                  <Col span={15} className="align-right">
                    <Paragraph>{formatNumber(portfolio.maxLong)}</Paragraph>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                <Row>
                  <Col span={9}>
                    <Paragraph>{t('portfolios.maxShort')}</Paragraph>
                  </Col>
                  <Col span={15} className="align-right">
                    <Paragraph>{formatNumber(portfolio.maxShort)}</Paragraph>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                <Row>
                  <Col span={9}>
                    <Paragraph>{t('portfolios.maxNetHedging')}</Paragraph>
                  </Col>
                  <Col span={15} className="align-right">
                    <Paragraph>{formatNumber(portfolio.maxNetHedging)}</Paragraph>
                  </Col>
                </Row>
                <Divider type="horizontal" />

                <Row>
                  <Col span={9}>
                    <Paragraph>{t('portfolios.minNetHedging')}</Paragraph>
                  </Col>
                  <Col span={15} className="align-right">
                    <Paragraph>{formatNumber(portfolio.minNetHedging)}</Paragraph>
                  </Col>
                </Row>
                <Divider type="horizontal" />
              </section>
            </AppContentCard>
          </Col>
        )}

        <Col span={16} className={`full-height ${showPortfolio || isEditing ? 'col-smaller' : 'col-bigger'}`}>
          <Row gutter={[16, 0]} className="full-height">
            <Col span={showPortfolio ? 12 : 8} className="col--assets full-height">
              <AppContentCard fullHeight>
                <Row align="middle" className="col-title">
                  <Col span={14}>
                    <Title>{t('assets.title')}</Title>
                  </Col>
                  <Col span={10} className="align-right">
                    {!isReadOnly && (
                      <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        <Button type="dashed">
                          {t('assets.addNew')} <DownOutlined />
                        </Button>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
                <Table
                  rowKey="id"
                  size="small"
                  columns={assetColumns}
                  dataSource={assets}
                  pagination={false}
                  className="table-assets"
                />
              </AppContentCard>
            </Col>
            <Col span={showPortfolio ? 12 : 16} className={'col-visible full-height'}>
              {renderAsset()}
            </Col>
          </Row>
        </Col>
      </>
    )
  }

  const renderCols = () => {
    if (isFullEditAsset) {
      if (!resourceAction) {
        return renderPortfolioAndAssets()
      }

      const { operation } = resourceAction
      const asset = resourceAction.resource as Store.Asset

      switch (operation) {
        case CrudOperation.Create:
          return (
            <Col span={24} className={'col-visible full-height'}>
              <AssetForm
                portfolioId={portfolio.id}
                asset={null}
                operation={CrudOperation.Create}
                onClose={onAssetClose}
              />
            </Col>
          )
        case CrudOperation.Update:
          return (
            <Col span={24} className={'col-visible full-height'}>
              <AssetForm
                portfolioId={asset.portfolioId}
                asset={asset}
                operation={CrudOperation.Update}
                onClose={onAssetCancel}
              />
            </Col>
          )
        default:
          return renderPortfolioAndAssets()
      }
    }

    return renderPortfolioAndAssets()
  }

  const onCsvModalClose = () => setCsvModalVisible(false)

  return (
    <section className="full-height">
      <CsvModal visible={csvModalVisible} onClose={onCsvModalClose} />
      <Row gutter={[16, 0]} className="full-height">
        {renderCols()}
      </Row>
    </section>
  )
}

export default ReadPortfolio
