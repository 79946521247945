import { all } from 'redux-saga/effects'
import strategies from 'store/resources/strategies/sagas'
import portfolios from 'store/resources/portfolios/sagas'
import assets from 'store/resources/assets/sagas'
import historicalStrategies from 'store/resources/strategies/helpers.historical'
import historicalPortfolios from 'store/resources/portfolios/helpers.historical'
import historicalAssets from 'store/resources/assets/helpers.historical'
import layouts from 'store/layouts/sagas'
import sessions from 'store/sessions/sagas'
import operativeSession from 'store/pages/operative-session/sagas'
import setupSession from 'store/pages/setup-session/sagas'
import admin from 'store/pages/admin/sagas'
import strategy from 'store/pages/strategy/sagas'
import app from 'store/App/sagas'
import user from 'store/user/sagas'
import dashboard from 'store/dashboard/sagas'

export default function* rootSaga() {
  yield all([
    strategies(),
    portfolios(),
    assets(),
    app(),
    sessions(),
    layouts(),
    operativeSession(),
    setupSession(),
    historicalAssets(),
    historicalStrategies(),
    historicalPortfolios(),
    strategy(),
    admin(),
    user(),
    dashboard(),
  ])
}
