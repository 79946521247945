import React, { useEffect, useState } from 'react'
import PortfolioTable from 'v2/components/frames/PortfolioTable'
import * as Store from 'types/store'
import * as Api from 'types/api'
import { useDispatch, useSelector } from 'react-redux'
import { removeFrame } from 'store/dashboard/actions'
import { selectOperativeSessionSession } from 'store/pages/selectors'
import { GridItemActions } from 'types/ui'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import { selectPortfolios, selectStrategies } from 'store/resources/selectors'
import Unavaliable from 'v2/components/frames/Unavailable/Unavaliable'
import SelectColumnsModal from 'v2/components/settings/modals/SelectColumnsModal'
import * as api from 'api/historicalTables'
import { isApiError, isGraphQlError } from 'core'
import { jsonToAssetTable, jsonToPortfolioTable } from 'services/store/mapHistoricalTables'

const getPortfolioTableData = async (id: number, sessionId: number) => {
  const response = await api.getPortfolioTable(id, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }
  const {
    data: { historical_data_portfolio_table_prt_v },
  } = response as Api.HistoricalPortfolioTableResponse

  const data = jsonToPortfolioTable(historical_data_portfolio_table_prt_v)
  return data[0]
}
const getAssetTableData = async (id: number, sessionId: number) => {
  const response = await api.getAssetsTable(id, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    return null
  }
  const {
    data: { historical_data_portfolio_table_asset_v },
  } = response as Api.HistoricalAssetTableResponse

  const data = jsonToAssetTable(historical_data_portfolio_table_asset_v)
  return data
}

interface Props {
  frame: Store.Frame
}

const PortfolioTableContainer: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()
  const resource = frame.selectedResources[0].resource as Store.Portfolio
  const { columnKeys } = frame.options
  const { id: sessionId } = useSelector(selectOperativeSessionSession)
  const strategies = useSelector(selectStrategies)
  const portfolios = useSelector(selectPortfolios)
  const [isModalVisible, setModalVisible] = useState(false)
  const [isSelectColumnsModalVisible, setSelectColumnsModalVisible] = useState(false)
  const [portfolioTable, setPortfolioTable] = useState<Store.PortfolioTable>()
  const [assetData, setAssetData] = useState<Store.PortfolioListAssetsTable[]>([])

  const getHasMissingResource = () => {
    const portfolio = portfolios.find((portfolio) => portfolio.id === resource.id)
    const strategy = strategies.find((strategy) => strategy.id === portfolio?.strategyId)
    if (!portfolio || !strategy) {
      return true
    }

    return false
  }

  const getData = async () => {
    const portfolioData = await getPortfolioTableData(resource.id, sessionId)
    const assetData = await getAssetTableData(resource.id, sessionId)
    setAssetData(assetData)
    setPortfolioTable(portfolioData)
  }

  useEffect(() => {
    getData()
  }, [])

  const onClose = () => {
    setModalVisible(false)
  }

  const onSelectColumnsModalClose = () => {
    setSelectColumnsModalVisible(false)
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
      case GridItemActions.ColumnSelect:
        setSelectColumnsModalVisible(true)
    }
  }

  if (getHasMissingResource()) {
    return <Unavaliable onItemClick={onItemClick} title={resource.name} />
  }

  return (
    <>
      <PortfolioTable
        portfolioData={portfolioTable}
        assetData={assetData}
        columnKeys={columnKeys}
        onItemClick={onItemClick}
        isOffline={false}
      />
      <AddFrameModalContainer
        initialFrame={frame}
        visible={isModalVisible}
        onClose={onClose}
        initialX={0}
        initialY={0}
      />
      <SelectColumnsModal
        frame={frame}
        visible={isSelectColumnsModalVisible}
        onClose={onSelectColumnsModalClose}
        tableTypes={['portfolio', 'asset']}
      />
    </>
  )
}

export default PortfolioTableContainer
