import i18n from 'i18next'
import { TableType } from 'types/store'

export const getColumns = (tableType: TableType) => {
  switch (tableType) {
    case 'strategy':
      return [
        { title: i18n.t('table.column.baseCurrency'), key: 'baseCurrency' },
        { title: i18n.t('table.column.assets'), key: 'assetsNum' },
        { title: i18n.t('table.column.portfolios'), key: 'portfoliosNum' },
        { title: i18n.t('table.column.countervalue'), key: 'value' },
        { title: i18n.t('table.column.longCountervalue'), key: 'valueLong' },
        { title: i18n.t('table.column.shortCountervalue'), key: 'valueShort' },
        { title: i18n.t('table.column.tdyTradingReturn'), key: 'tdyTradingRetBc' },
        { title: i18n.t('table.column.tdyExchangeRatesReturn'), key: 'tdyExchRetBc' },
        { title: i18n.t('table.column.tdyTotalReturn'), key: 'tdyAllRetBc' },
        { title: i18n.t('table.column.ytdTradingReturn'), key: 'ytdTradingRetBc' },
        { title: i18n.t('table.column.ytdExchangeRatesReturn'), key: 'ytdExchRetBc' },
        { title: i18n.t('table.column.ytdTotalReturn'), key: 'ytdAllRetBc' },
        { title: i18n.t('table.column.tdyPositiveOperations'), key: 'tdyPositiveOp' },
        { title: i18n.t('table.column.tdyNegativeOperations'), key: 'tdyNegativeOp' },
        { title: i18n.t('table.column.ytdTotalOperations'), key: 'tdyTotalOp' },
        { title: i18n.t('table.column.ytdPositiveOperations'), key: 'ytdPositiveOp' },
        { title: i18n.t('table.column.ytdNegativeOperations'), key: 'ytdNegativeOp' },
      ]
    case 'portfolio':
    case 'strategy-portfolios':
      return [
        tableType === 'portfolio' && { title: i18n.t('table.column.strategyName'), key: 'strategyName' },
        tableType === 'portfolio' && { title: i18n.t('table.column.baseCurrency'), key: 'baseCurrency' },
        { title: i18n.t('table.column.assets'), key: 'assetsNum' },
        { title: i18n.t('table.column.countervalue'), key: 'value' },
        { title: i18n.t('table.column.longCountervalue'), key: 'valueLong' },
        { title: i18n.t('table.column.shortCountervalue'), key: 'valueShort' },
        { title: i18n.t('table.column.tdyTradingReturn'), key: 'tdyTradingRetBc' },
        { title: i18n.t('table.column.tdyExchangeRatesReturn'), key: 'tdyExchRetBc' },
        { title: i18n.t('table.column.tdyTotalReturn'), key: 'tdyAllRetBc' },
        { title: i18n.t('table.column.ytdTradingReturn'), key: 'ytdTradingRetBc' },
        { title: i18n.t('table.column.ytdExchangeRatesReturn'), key: 'ytdExchRetBc' },
        { title: i18n.t('table.column.ytdTotalReturn'), key: 'ytdAllRetBc' },
        { title: i18n.t('table.column.tdyPositiveOperations'), key: 'tdyPositiveOp' },
        { title: i18n.t('table.column.tdyNegativeOperations'), key: 'tdyNegativeOp' },
        { title: i18n.t('table.column.ytdTotalOperations'), key: 'tdyTotalOp' },
        { title: i18n.t('table.column.ytdPositiveOperations'), key: 'ytdPositiveOp' },
        { title: i18n.t('table.column.ytdNegativeOperations'), key: 'ytdNegativeOp' },
      ].filter((column) => column)
    case 'asset':
      return [
        { title: i18n.t('table.column.lastPrice'), key: 'lastPrice' },
        { title: i18n.t('table.column.instrumentIsin'), key: 'instrumentIsin' },
        { title: i18n.t('table.column.model'), key: 'modelName' },
        { title: i18n.t('table.column.version'), key: 'modelVersion' },
        { title: i18n.t('table.column.modelStatus'), key: 'modelStatus' },
        { title: i18n.t('table.column.lastPosition'), key: 'lastPosition' },
        { title: i18n.t('table.column.quantity'), key: 'quantity' },
        { title: i18n.t('table.column.countervalue'), key: 'value' },
        { title: i18n.t('table.column.tdyTradingReturn'), key: 'tdyTradingRetBc' },
        { title: i18n.t('table.column.tdyTradingReturnPercentage'), key: 'tdyPercRet' },
        { title: i18n.t('table.column.ytdTradingReturn'), key: 'ytdTradingRetBc' },
        { title: i18n.t('table.column.ytdExchangeRatesReturn'), key: 'ytdExchRetBc' },
        { title: i18n.t('table.column.ytdTotalReturn'), key: 'ytdAllRetBc' },
        { title: i18n.t('table.column.ytdTradingReturnPercentage'), key: 'ytdPercRet' },
        { title: i18n.t('table.column.lastOpenTdyTradingReturn'), key: 'opTdyTradRetBc' },
        { title: i18n.t('table.column.lastOpenTdyReturnPercetage'), key: 'opTdyPercRet' },
        { title: i18n.t('table.column.tdyTotalOperations'), key: 'tdyTotalOp' },
        { title: i18n.t('table.column.tdyPositiveOperations'), key: 'tdyPositiveOp' },
        { title: i18n.t('table.column.tdyNegativeOperations'), key: 'tdyNegativeOp' },
        { title: i18n.t('table.column.ytdTotalOperations'), key: 'ytdTotalOp' },
        { title: i18n.t('table.column.ytdPositiveOperations'), key: 'ytdPositiveOp' },
        { title: i18n.t('table.column.ytdNegativeOperations'), key: 'ytdNegativeOp' },
      ]
  }
}
