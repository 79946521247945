import React, { useEffect, useState } from 'react'
import Debug from 'debug'
// import * as Api from 'types/api'
// import * as api from 'api/candleStick'
import * as socket from 'api/socket'
import { useSelector } from 'react-redux'
import { selectOperativeSessionAssetById, selectOperativeSessionSession } from 'store/pages/selectors'
import { config } from 'core'
import { useTranslation } from 'react-i18next'
import { Button, Skeleton } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
// import { downloadAssetTrace } from '../../../../api/candleStick'

const debug = Debug('trace')

// const getModelInstanceId = async (sessionId: number, assetId: number) => {
//   const response = await api.getAssetInstanceId(sessionId, assetId)

//   if (isApiError(response)) {
//     debug('ERROR getModelInstanceId')
//     return null
//   }

//   const { data } = response as Api.AssetModelInstanceResponse

//   if (!data.application_asset_model_instance.length) {
//     return null
//   }
//   return data.application_asset_model_instance[0].instance_id
// }

interface UnsubscribeObject {
  unsubscribe: () => void
  destroyed: boolean
}
interface Props {
  assetId: number
  visible: boolean
}

const Trace: React.FC<Props> = ({ assetId, visible }) => {
  const [messages, setMessage] = useState([])
  const [noTraceData, setNoTraceData] = useState<boolean>(false)
  const session = useSelector(selectOperativeSessionSession)

  //We need to retrieve asset from redux, to get an updated version. We cannot use the data in the frame
  const asset = useSelector(selectOperativeSessionAssetById(assetId))
  const { t } = useTranslation()

  const onMessage = (message: MessageEvent) => {
    const { data } = message
    setMessage((messages) => {
      const message = data as string
      return [message, ...messages].slice(0, 200)
    })
    debug(data)
  }

  const noTrace = () => {
    return <section className="trace-list-container no-trace">{t('chart.noTrace')}</section>
  }

  const subscribeToSocket = async (unsubscribeObject: UnsubscribeObject) => {
    setMessage([])
    if (asset?.id && !unsubscribeObject.destroyed) {
      setNoTraceData(false)
      unsubscribeObject.unsubscribe = socket.subscribeToTrace(asset?.id, session.type, session.id, onMessage)
    } else {
      setNoTraceData(true)
      setMessage([t('error.noData')])
    }
  }

  useEffect(() => {
    const unsubscribeObject: UnsubscribeObject = {
      unsubscribe: null,
      destroyed: false,
    }
    if (visible) {
      subscribeToSocket(unsubscribeObject)
    }
    return () => {
      unsubscribeObject.destroyed = true
      unsubscribeObject.unsubscribe && unsubscribeObject.unsubscribe()
    }
  }, [asset?.id, asset?.status, visible])

  if (!visible) {
    // TODO: check if this case verifies
    return <Skeleton />
  }

  if (!messages || messages.length === 0) {
    return noTrace()
  }

  return (
    <>
      <section className="trace-list-container">
        {messages.map((message, i) => (
          <p key={i}>{message}</p>
        ))}
      </section>
      <Button
        type="link"
        size="small"
        disabled={noTraceData}
        icon={<DownloadOutlined />}
        className="trace-download-btn"
        href={`${config.apiBaseUrl}/operative-session/${session.type}/${session.id}/analysis-model/${assetId}/trace/download`}
      >
        {/* TODO: understand how to enable new translations in this weird env: see >> REACT_APP_LABELS_SOURCE */}
        {/* {t('chart.downloadTraceBtn')} */}
        Download Trace
      </Button>
    </>
  )
}

export default Trace
