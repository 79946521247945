import React from 'react'
import * as Store from 'types/store'
import { Col, Form, Row, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import DependsOn from 'components/DependsOn'
const { Paragraph } = Typography

interface Props {
  data: Store.BrokerAccount[]
  setBroker: (broker: string) => void
}

const { Option } = Select

const BrokerAccountField: React.FC<Props> = ({ data, setBroker }) => {
  const { t } = useTranslation()

  return (
    <DependsOn fields={['broker']}>
      {({ broker }) => {
        setBroker(broker)
        return (
          <Row align="middle" gutter={[5, 0]}>
            <Col span={12}>
              <Paragraph>{t('assets.brokerAccount')}</Paragraph>
            </Col>
            <Col span={12}>
              <Form.Item name="brokerAccount" rules={[{ required: broker }]}>
                <Select getPopupContainer={(trigger) => trigger.parentElement} disabled={!broker}>
                  {data.map((item) => {
                    return (
                      <Option value={item.account} key={item.account}>
                        {item.account}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )
      }}
    </DependsOn>
  )
}

export default BrokerAccountField
