import React, { useEffect } from 'react'
import Debug from 'debug'
import AssetForm from 'components/StrategyViewer/forms/AssetForm'
import { useDispatch, useSelector } from 'react-redux'
import { selectAssetsState } from 'store/resources/selectors'
import { CrudOperation, LoadingState } from 'types/enums'
import { createAsset, updateAsset } from 'store/resources/assets/actions'
import { mapToAssetRest } from 'services/store/mapService'
import * as Store from 'types/store'

const debug = Debug('Frontend')

interface Params {
  portfolioId: number
  asset: Store.Asset
  operation: CrudOperation
  onClose: (asset: Store.Asset) => void
}

const AssetFormContainer: React.FC<Params> = ({ portfolioId, asset, operation, onClose }) => {
  const dispatch = useDispatch()
  const loadingState = useSelector(selectAssetsState)
  const isLoading = operation === CrudOperation.Update ? LoadingState.Updating : LoadingState.Creating

  const closeEdit = () => onClose(asset)

  useEffect(() => {
    if ([LoadingState.Created, LoadingState.Updated].includes(loadingState)) {
      closeEdit()
    }
  }, [loadingState])

  const onFinish = (data: Store.AssetForm) => {
    const mappedData = mapToAssetRest(data, portfolioId)
    debug('data', mappedData, data)

    if (operation === CrudOperation.Create) {
      dispatch(createAsset(mappedData))
    } else {
      mappedData.id = asset.id
      dispatch(updateAsset(mappedData))
    }
  }

  return (
    <AssetForm
      asset={asset}
      loading={loadingState === isLoading}
      onFinish={onFinish}
      onClose={closeEdit}
      mode={operation}
    />
  )
}

export default AssetFormContainer
