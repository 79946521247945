import { DataNode } from 'antd/lib/tree'
import { CrudOperation, FrameType } from 'types/enums'
import * as Store from './store'

export enum NodeType {
  Strategy = 1,
  Portfolio = 2,
  Asset = 3,
  AllStrategies = 101,
  AllPortfolios = 102,
  AllAssets = 103,
  Undefined = 999,
}

export enum TransferType {
  Add = 1,
  Remove = 2,
  AddStrategies = 3,
  RemoveStrategies = 4,
  AddPortfolios = 5,
  RemovePortfolios = 6,
  AddAssets = 7,
  RemoveAssets = 8,
  RemoveAll = 9,
  Disabled = 10,
}

export interface ExtendedDataNode extends DataNode {
  type: NodeType
  resource?: Store.Resource
  selected?: boolean
  children?: Array<ExtendedDataNode>
  isDeletable: boolean
}

export interface TransferContextData {
  key: string
  label: string
  transferType: TransferType
}

export interface Resolution {
  key: number
  name: string
  width: number
  height: number
  orientation: string
  color: string
  active: boolean
}

export interface LayoutAction {
  resource: Store.Layout
  operation: CrudOperation
}

export interface FrameTemplate {
  frameType: FrameType
  width?: number
  height?: number
  x?: number
  y?: number
}

export enum GridItemActions {
  Close = 'Close',
  Edit = 'Edit',
  Configure = 'Configure',
  FullScreen = 'FullScreen',
  Trace = 'Trace',
  ColumnSelect = 'ColumnSelect',
  Download = 'Download',
}

export enum FrameView {
  Small = 'small',
  SmallVertical = 'small-vertical',
  Medium = 'medium',
  Large = 'large',
  LargeShort = 'large-short',
  LargeMedium = 'large-medium',
}

export enum FrameSize {
  WithCandleStickX = 'with-candlestick-x',
  WithCandleStickY = 'with-candlestick-y',
  Vertical = 'vertical',
  Vertical2 = 'vertical-2',
  Small = 'small',
  Small2 = 'small-2',
  Medium = 'medium',
  Large = 'large',
  Large2 = 'large-2',
}

export enum ViewType {
  Table = 'table',
  Graph = 'graph',
  Details = 'details',
  AnalysisModel = 'analysis-model',
  Session = 'session',
}
