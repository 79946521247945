import React, { useEffect } from 'react'
import Sessions from 'components/Sessions'
import { useDispatch, useSelector } from 'react-redux'
import { cleanUpSession, deleteSession, setLoadingState } from 'store/sessions/actions'
import { LoadingState } from 'types/enums'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { selectSessions, selectSessionsState } from 'store/sessions/selectors'

const SessionsContainer: React.FC = () => {
  const sessions = useSelector(selectSessions)
  const loadingState = useSelector(selectSessionsState)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    switch (loadingState) {
      case LoadingState.Deleted:
        notification.success({
          message: t('notification.type.success'),
          description: t('notification.message.deletionSuccess'),
          duration: 5,
        })
        dispatch(setLoadingState(LoadingState.Loaded))
        break
      case LoadingState.DeleteFailed:
        notification.error({
          message: t('notification.type.error'),
          description: t('notification.message.somethingWentWrong'),
          duration: 5,
        })
        dispatch(setLoadingState(LoadingState.Loaded))
        break
    }
  }, [loadingState])

  const onDelete = (id: number) => {
    dispatch(deleteSession(id))
  }
  const onCleanUp = (id: number) => {
    dispatch(cleanUpSession(id))
  }

  return <Sessions sessions={sessions} onDelete={onDelete} onClean={onCleanUp} />
}

export default SessionsContainer
