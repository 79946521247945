import React from 'react'
import { Dropdown, Menu } from 'antd'
import { ExtendedDataNode, TransferType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { CheckOutlined } from '@ant-design/icons'
import { getRemoveAllNodes } from 'helpers/transfer/itemRemoveHelper'
import { TransferContext } from 'types/enums'

interface Props {
  node: ExtendedDataNode
  onClick: (node: ExtendedDataNode, type: TransferType) => void
  context: TransferContext
}

const RemoveAll: React.FC<Props> = ({ node, onClick, context }) => {
  const { t } = useTranslation()

  const items = [getRemoveAllNodes()]
  const trigger: any =
    context === TransferContext.AtSessionLaunch ? ['contextMenu'] : ['click', 'contextMenu']

  const DropdownMenu = (
    <Menu>
      {items.map((item) => (
        <Menu.Item
          key={item.key}
          onClick={() => {
            onClick(node, item.transferType)
          }}
        >
          {t(item.label)}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={DropdownMenu} trigger={trigger} getPopupContainer={(trigger) => trigger.parentElement}>
      <span>
        {node.selected && <CheckOutlined style={{ marginRight: 5 }} />}
        {node.title}
      </span>
    </Dropdown>
  )
}

export default RemoveAll
