import routes from 'config/routes'
import React, { useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchUser, setLoadingState } from 'store/user/actions'
import { selectLoadingState } from 'store/user/selectors'
import { LoadingState } from 'types/enums'
import { useMount } from 'react-use'
import { bootstrap } from 'store/App/actions'
import { selectApp } from 'store/App/selectors'

const UserProvider: React.FC = memo(({ children }) => {
  const loadingState = useSelector(selectLoadingState)
  const dispatch = useDispatch()
  const isLoading = useSelector(selectApp).bootstrapLoading
  const navigate = useNavigate()

  useMount(() => {
    dispatch(bootstrap())
    dispatch(fetchUser())
  })

  useEffect(() => {
    switch (loadingState) {
      case LoadingState.Loaded:
        dispatch(setLoadingState(LoadingState.Unset))
        break
      case LoadingState.LoadFailure:
        navigate(routes.login.url)
        dispatch(setLoadingState(LoadingState.Unset))
        break
    }
  }, [loadingState])

  if (isLoading) {
    return null
  }

  return <>{children}</>
})

UserProvider.displayName = "UserProvider"

export default UserProvider
