import { put, takeLatest, all, call } from 'redux-saga/effects'
import * as K from './constants'
import { fetchStrategies } from 'store/resources/strategies/helpers'
import { fetchPortfolios } from 'store/resources/portfolios/helpers'
import { fetchAssets } from 'store/resources/assets/helpers'
import { fetchStrategies as fetchHistoricalStrategies } from 'store/resources/strategies/helpers.historical'
import { fetchPortfolios as fetchHistoricalPortfolios } from 'store/resources/portfolios/helpers.historical'
import { fetchAssets as fetchHistoricalAssets } from 'store/resources/assets/helpers.historical'
import { fetchSessions } from 'store/sessions/sagas'
import { fetchLayouts } from 'store/layouts/sagas'
import { AnyAction } from 'redux'
import { LoadingState } from 'types/enums'

export function* bootstrap() {
  yield all([yield call(fetchSessions), yield call(fetchLayouts)])
  yield put({ type: K.APP_BOOTSTRAP_LOADED, payload: null })
}

export function* fetchResources() {
  try {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Loading })

    yield all([yield call(fetchStrategies), yield call(fetchPortfolios), yield call(fetchAssets)])

    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.Loaded })
  } catch (error) {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.LoadFailure })
  }
}

export function* fetchHistoricalResources(action: AnyAction) {
  try {
    yield all([
      yield call(fetchHistoricalStrategies, action),
      yield call(fetchHistoricalPortfolios, action),
      yield call(fetchHistoricalAssets, action),
    ])
  } catch (error) {
    yield put({ type: K.SET_LOADING_STATE, payload: LoadingState.LoadFailure })
  }
}

function* watch() {
  yield all([
    takeLatest(K.APP_BOOTSTRAP, bootstrap),
    takeLatest(K.FETCH_RESOURCES, fetchResources),
    takeLatest(K.FETCH_HISTORICAL_RESOURCES, fetchHistoricalResources),
  ])
}

export default watch
