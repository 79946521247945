import * as K from './constants'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import * as Api from 'types/api'
import * as api from 'api/historicalResources'
import { ErrorCode } from 'types/enums'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
import { jsonToAssets } from 'services/store/mapHistoricals'
import { AnyAction } from 'redux'

const debug = Debug('Frontend')

export function* fetchAssets(action: AnyAction) {
  debug('Saga Fetch Historical Assets')

  const sessionId = action.payload as number
  const response = yield call(api.getAssets, sessionId)
  if (isApiError(response) || isGraphQlError(response)) {
    debug('fetchAssets failed', response, response as ApiError)
    throw new ApplicationError(ErrorCode.Api, 'fetchAssets failed')
  }

  const json = (response as Api.HistoricalAssetsResponse).data.historical_data_asset_v

  const assets = jsonToAssets(json)
  debug(`Loaded ${assets.length} Assets`)

  // save in redux
  yield put({ type: K.ASSETS_FETCH_SUCCESS, payload: assets })
}

function* watch() {
  yield all([takeLatest(K.HISTORICAL_ASSETS_FETCH, fetchAssets)])
}

export default watch
