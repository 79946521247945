import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ExtendedDataNode } from 'types/ui'
import { setCurrentNode } from 'store/pages/setup-session/actions'
import { CrudOperation } from 'types/enums'
import { generateResourceTree } from 'services/treeService'

import { selectResources } from 'store/resources/selectors'
import HistoricalStrategyTreeView from 'components/HistoricalStrategyTreeView'

const HistoricalAssetTreeView: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { portfolios, strategies, assets } = useSelector(selectResources)

  const [tree, setTree] = useState<Array<ExtendedDataNode>>()
  const [filter, setFilter] = useState<string>(null)

  useEffect(() => {
    if (!assets.data.length) {
      return
    }

    const tree = generateResourceTree(
      strategies.data,
      portfolios.data,
      assets.data,
      t('transfer.allStrategies'),
      filter,
    )
    setTree(tree)
  }, [filter, assets])

  if (!tree) {
    return null
  }

  const onNodeClicked = (node: ExtendedDataNode) => {
    if (node.resource) {
      dispatch(setCurrentNode({ node: node, operation: CrudOperation.Read }))
    }
  }

  const onFilterChange = (newFilter: string) => {
    setFilter(newFilter)
  }

  return (
    <HistoricalStrategyTreeView
      treeData={tree}
      onNodeClicked={onNodeClicked}
      onFilterChange={onFilterChange}
    />
  )
}

export default HistoricalAssetTreeView
