import { ExtendedDataNode, NodeType } from 'types/ui'
import * as Store from 'types/store'

export const isActiveElement = (item: ExtendedDataNode, userAction: Store.UserAction) => {
  return (
    userAction.node && userAction.node.type === item.type && userAction.node.resource.id === item.resource.id
  )
}

export function createTreeFromResources(
  strategies: Array<Store.Strategy>,
  portfolios: Array<Store.Portfolio>,
  assets: Array<Store.Asset>,
  usedResources?: Store.UsedResources,
): Array<ExtendedDataNode> {
  return strategies.map((strategy) => ({
    title: strategy.name,
    resource: strategy,
    key: `strategy-${strategy.id}`,
    type: NodeType.Strategy,
    isDeletable: usedResources ? !usedResources.strategies.includes(strategy.id) : true,
    children: portfolios
      .filter((portfolio) => portfolio.strategyId === strategy.id)
      .map((portfolio) => {
        return {
          title: portfolio.name,
          type: NodeType.Portfolio,
          resource: portfolio,
          key: `portfolio-${portfolio.id}`,
          isDeletable: usedResources && !usedResources.portfolios.includes(portfolio.id),
          children: assets
            .filter((asset) => asset.portfolioId === portfolio.id)
            .map((asset) => {
              return {
                title: asset.name,
                key: `asset-${asset.id}`,
                type: NodeType.Asset,
                resource: asset,
                isDeletable: usedResources && !usedResources.assets.includes(asset.id),
              }
            }),
        }
      }),
  }))
}

function createDataTreeFromExpandedDataNode(node: ExtendedDataNode): Store.DataTree | Store.DataLeaf {
  if (node.children) {
    return {
      name: node.resource.name,
      children: node.children.map((item) => createDataTreeFromExpandedDataNode(item) as Store.DataTree),
      resource: node.resource,
      nodeType: node.type,
    }
  }

  return {
    name: node.resource.name,
    value: 1000, // Any number is fine
    status: (node.resource as Store.Asset).status,
    resource: node.resource,
    nodeType: node.type,
  } as Store.DataLeaf
}

export function createDataTreeFromTree(tree: Array<ExtendedDataNode>): Store.DataTree {
  if (!tree.length) {
    return null
  }
  return {
    name: 'DataTree',
    children: tree.map((node) => createDataTreeFromExpandedDataNode(node)) as Array<Store.DataTree>,
    resource: null,
  }
}

export const filterItems = (items: Array<Json>, filter: string) => {
  if (!filter) {
    return items
  }
  return items.filter((item) => item.name.toUpperCase().includes(filter.toUpperCase()))
}

export const generateResourceTree = (
  strategies: Store.Strategy[],
  portfolios: Store.Portfolio[],
  assets: Store.Asset[],
  title: string,
  filter: string,
) => {
  if (!strategies || !portfolios || !assets) {
    return null
  }

  const filteredAssets = filterItems(assets, filter) as Array<Store.Asset>

  const filteredPortfolios = portfolios.filter((portfolio) => {
    return filteredAssets.some((asset) => portfolio.id === asset.portfolioId)
  })

  const filteredStrategies = strategies.filter((strategy) => {
    return filteredPortfolios.some((portfolio) => portfolio.strategyId === strategy.id)
  })

  const tree: Array<ExtendedDataNode> = [
    {
      title: title,
      key: 'strategies',
      type: NodeType.AllStrategies,
      children: createTreeFromResources(filteredStrategies, filteredPortfolios, filteredAssets),
      isDeletable: true,
    },
  ]

  return tree
}
