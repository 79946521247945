import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import moment from 'moment'
import { renderAvailableData } from 'core/helpers'
import colors from 'config/colors'

interface Props {
  trade: Store.SecurityTrade
}

const Trade: React.FC<Props> = ({ trade }) => {
  const { t } = useTranslation()
  const time = moment(trade?.time)

  return (
    <div className="trade-container">
      <div className="trade-chart">
        <svg viewBox="0 0 1300 870">
          <g className="trade-circle" transform="translate(240, 0)">
            <circle
              cx="430"
              cy="430"
              r="410"
              fill={colors.midnight}
              stroke={colors.primary}
              strokeWidth="40"
            ></circle>
          </g>
          <text className="time-text">{t('chart.time')}</text>
          <g transform="translate(20,230)" className="time">
            <g>
              <rect width="110" height="110" rx="5" fill="transparent" />
              <text>{renderAvailableData(trade?.time && time.format('HH'))}</text>
            </g>
            <g transform="translate(0,140)">
              <rect width="110" height="110" rx="5" fill="transparent" />
              <text>{renderAvailableData(trade?.time && time.format('mm'))}</text>
            </g>
            <g transform="translate(0,280)">
              <rect width="110" height="110" rx="5" fill="transparent" />
              <text>{renderAvailableData(trade?.time && time.format('ss'))}</text>
            </g>
          </g>
          <g className="content">
            <text>{t('chart.trade')}</text>
            <text y="165">{renderAvailableData(trade?.price)}</text>
            <text y="330">{renderAvailableData(trade?.size)}</text>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default Trade
