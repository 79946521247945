import React, { useEffect } from 'react'

import AppContent from 'components/AppContent'
import LeftRail from 'components/LeftRail'
import { useDispatch, useSelector } from 'react-redux'
import AssetTransfer from 'containers/transfer/AssetTransfer'
import { useParams } from 'react-router-dom'
import { LoadingState, SessionStatus, SessionType } from 'types/enums'
import { fetchSessionAssets } from 'store/pages/setup-session/actions'
import Breadcrumb from 'containers/Breadcrumb'
import StrategyViewer from 'containers/StrategyViewer'
import SessionHeader from 'components/Sessions/SessionHeader'
import { fetchSessions } from 'store/sessions/actions'
import styles from './styles.module.scss'
import { selectSessions } from 'store/sessions/selectors'
import { selectSetupSessionPage } from 'store/pages/selectors'
import { selectUser } from 'store/user/selectors'

import { useMount } from 'react-use'
import { fetchResources } from 'store/App/actions'
import { selectAppLoadingState } from 'store/App/selectors'
import NoSessions from 'components/Sessions/NoSessions'
import BrokenEntityReporter from 'containers/SessionSetup/BrokenEntityReporter'

const sessionTypeToString = (type: SessionType): string => {
  return (type as string).toLowerCase()
}

const sessionStatusIsValid = (status: SessionStatus): boolean => {
  const invalidStatuses = [SessionStatus.Completed, SessionStatus.Unknown]
  return !invalidStatuses.includes(status)
}

const LaunchPage: React.FC = () => {
  const { type } = useParams()
  const user = useSelector(selectUser)

  const sessions = useSelector(selectSessions)
  const dispatch = useDispatch()
  const page = useSelector(selectSetupSessionPage)
  const { currentNode, loadingState, assets } = page
  const appLoadingState = useSelector(selectAppLoadingState)

  const isEnterEnabled = () => {
    if (assets?.length > 0) {
      return true
    }

    //rif: #718
    if (type === SessionType.Signals || type === SessionType.Trading) {
      return true
    }

    return false
  }

  //Convert type to session id
  const session = sessions.find(
    (s) =>
      sessionTypeToString(s.type) === type &&
      s.ownerEmail === user.email &&
      !s.isHistorical &&
      sessionStatusIsValid(s.status),
  )

  if (!session) {
    return <NoSessions />
  }

  useMount(() => {
    dispatch(fetchResources())
    dispatch(fetchSessions())
  })

  useEffect(() => {
    if (session) {
      dispatch(fetchSessionAssets(session.id))
    }
  }, [session])

  if (appLoadingState === LoadingState.Loading) {
    return null
  }

  return (
    <>
      <LeftRail>
        <AssetTransfer session={session} />
      </LeftRail>

      <AppContent fullHeight isDisplayFlex className={styles['launch-page']}>
        <Breadcrumb node={currentNode && currentNode.node} />
        <SessionHeader session={session} loadingState={loadingState} disabled={!isEnterEnabled()} />
        {currentNode && <StrategyViewer userAction={currentNode} isReadOnly={true} />}
      </AppContent>
      <BrokenEntityReporter session={session} />
    </>
  )
}

export default LaunchPage
