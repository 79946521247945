// source: asset_return.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.assets.return.alive', null, global);
goog.exportSymbol('proto.assets.return.asset_all_ret_bc', null, global);
goog.exportSymbol('proto.assets.return.asset_all_ret_bc_close', null, global);
goog.exportSymbol('proto.assets.return.asset_all_ret_bc_end', null, global);
goog.exportSymbol('proto.assets.return.asset_closed_unit_ret', null, global);
goog.exportSymbol('proto.assets.return.asset_exch_ret_bc', null, global);
goog.exportSymbol('proto.assets.return.asset_open_unit_ret', null, global);
goog.exportSymbol('proto.assets.return.asset_tdy_op_indicators', null, global);
goog.exportSymbol('proto.assets.return.asset_trad_ret', null, global);
goog.exportSymbol('proto.assets.return.asset_trad_ret_close_end', null, global);
goog.exportSymbol('proto.assets.return.decimal', null, global);
goog.exportSymbol('proto.assets.return.end_snapshot', null, global);
goog.exportSymbol('proto.assets.return.feed_event', null, global);
goog.exportSymbol('proto.assets.return.feed_event.EventTypeCase', null, global);
goog.exportSymbol('proto.assets.return.return_analysis', null, global);
goog.exportSymbol('proto.assets.return.svc_status', null, global);
goog.exportSymbol('proto.assets.return.tstamp', null, global);
goog.exportSymbol('proto.assets.return.update_payload', null, global);
goog.exportSymbol('proto.assets.return.update_snapshot', null, global);
goog.exportSymbol('proto.assets.return.update_ticker', null, global);
goog.exportSymbol('proto.assets.return.update_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.tstamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.tstamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.tstamp.displayName = 'proto.assets.return.tstamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.decimal.displayName = 'proto.assets.return.decimal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_tdy_op_indicators = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_tdy_op_indicators, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_tdy_op_indicators.displayName = 'proto.assets.return.asset_tdy_op_indicators';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_trad_ret = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_trad_ret, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_trad_ret.displayName = 'proto.assets.return.asset_trad_ret';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_open_unit_ret = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_open_unit_ret, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_open_unit_ret.displayName = 'proto.assets.return.asset_open_unit_ret';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_closed_unit_ret = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_closed_unit_ret, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_closed_unit_ret.displayName = 'proto.assets.return.asset_closed_unit_ret';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_all_ret_bc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_all_ret_bc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_all_ret_bc.displayName = 'proto.assets.return.asset_all_ret_bc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_exch_ret_bc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_exch_ret_bc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_exch_ret_bc.displayName = 'proto.assets.return.asset_exch_ret_bc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.return_analysis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.return_analysis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.return_analysis.displayName = 'proto.assets.return.return_analysis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_trad_ret_close_end = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_trad_ret_close_end, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_trad_ret_close_end.displayName = 'proto.assets.return.asset_trad_ret_close_end';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_all_ret_bc_close = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_all_ret_bc_close, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_all_ret_bc_close.displayName = 'proto.assets.return.asset_all_ret_bc_close';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.asset_all_ret_bc_end = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.asset_all_ret_bc_end, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.asset_all_ret_bc_end.displayName = 'proto.assets.return.asset_all_ret_bc_end';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.update_payload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.assets.return.update_payload.repeatedFields_, null);
};
goog.inherits(proto.assets.return.update_payload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.update_payload.displayName = 'proto.assets.return.update_payload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.update_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.update_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.update_snapshot.displayName = 'proto.assets.return.update_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.end_snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.end_snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.end_snapshot.displayName = 'proto.assets.return.end_snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.update_ticker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.update_ticker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.update_ticker.displayName = 'proto.assets.return.update_ticker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.alive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.assets.return.alive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.alive.displayName = 'proto.assets.return.alive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.assets.return.feed_event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.assets.return.feed_event.oneofGroups_);
};
goog.inherits(proto.assets.return.feed_event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.assets.return.feed_event.displayName = 'proto.assets.return.feed_event';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.tstamp.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.tstamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.tstamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.tstamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    offset: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    code: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.tstamp}
 */
proto.assets.return.tstamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.tstamp;
  return proto.assets.return.tstamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.tstamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.tstamp}
 */
proto.assets.return.tstamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.tstamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.tstamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.tstamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.tstamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * required sint64 time = 1;
 * @return {number}
 */
proto.assets.return.tstamp.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.tstamp} returns this
 */
proto.assets.return.tstamp.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.tstamp} returns this
 */
proto.assets.return.tstamp.prototype.clearTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.tstamp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 offset = 2;
 * @return {number}
 */
proto.assets.return.tstamp.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.tstamp} returns this
 */
proto.assets.return.tstamp.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.tstamp} returns this
 */
proto.assets.return.tstamp.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.tstamp.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required uint32 code = 3;
 * @return {number}
 */
proto.assets.return.tstamp.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.tstamp} returns this
 */
proto.assets.return.tstamp.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.tstamp} returns this
 */
proto.assets.return.tstamp.prototype.clearCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.tstamp.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
    coeff: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exp: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.decimal}
 */
proto.assets.return.decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.decimal;
  return proto.assets.return.decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.decimal}
 */
proto.assets.return.decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCoeff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * required sint64 coeff = 1;
 * @return {number}
 */
proto.assets.return.decimal.prototype.getCoeff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.decimal} returns this
 */
proto.assets.return.decimal.prototype.setCoeff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.decimal} returns this
 */
proto.assets.return.decimal.prototype.clearCoeff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.decimal.prototype.hasCoeff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required sint32 exp = 2;
 * @return {number}
 */
proto.assets.return.decimal.prototype.getExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.decimal} returns this
 */
proto.assets.return.decimal.prototype.setExp = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.decimal} returns this
 */
proto.assets.return.decimal.prototype.clearExp = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.decimal.prototype.hasExp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_tdy_op_indicators.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_tdy_op_indicators} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_tdy_op_indicators.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyTradRetLngPos: (f = msg.getAssetTdyTradRetLngPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyTradRetShrPos: (f = msg.getAssetTdyTradRetShrPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyTradRetLngNeg: (f = msg.getAssetTdyTradRetLngNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyTradRetShrNeg: (f = msg.getAssetTdyTradRetShrNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyTradRet: (f = msg.getAssetTdyTradRet()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyDtPrincLngPos: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    assetTdyDtPrincShrPos: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    assetTdyDtPrincLngNeg: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    assetTdyDtPrincShrNeg: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    assetTdyDtPrinc: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    assetTdyAvgPrincLngPos: (f = msg.getAssetTdyAvgPrincLngPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyAvgPrincShrPos: (f = msg.getAssetTdyAvgPrincShrPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyAvgPrincLngNeg: (f = msg.getAssetTdyAvgPrincLngNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyAvgPrincShrNeg: (f = msg.getAssetTdyAvgPrincShrNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyAvgPrincLng: (f = msg.getAssetTdyAvgPrincLng()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyAvgPrincShr: (f = msg.getAssetTdyAvgPrincShr()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyAvgPrincPos: (f = msg.getAssetTdyAvgPrincPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyAvgPrincNeg: (f = msg.getAssetTdyAvgPrincNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyAvgPrinc: (f = msg.getAssetTdyAvgPrinc()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanLngPos: (f = msg.getAssetTdyMeanLngPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanShrPos: (f = msg.getAssetTdyMeanShrPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanLngNeg: (f = msg.getAssetTdyMeanLngNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanShrNeg: (f = msg.getAssetTdyMeanShrNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanLng: (f = msg.getAssetTdyMeanLng()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanShr: (f = msg.getAssetTdyMeanShr()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanPos: (f = msg.getAssetTdyMeanPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanNeg: (f = msg.getAssetTdyMeanNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMean: (f = msg.getAssetTdyMean()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanLngPos2: (f = msg.getAssetTdyMeanLngPos2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanShrPos2: (f = msg.getAssetTdyMeanShrPos2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanLngNeg2: (f = msg.getAssetTdyMeanLngNeg2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanShrNeg2: (f = msg.getAssetTdyMeanShrNeg2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanLng2: (f = msg.getAssetTdyMeanLng2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanShr2: (f = msg.getAssetTdyMeanShr2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanPos2: (f = msg.getAssetTdyMeanPos2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMeanNeg2: (f = msg.getAssetTdyMeanNeg2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyMean2: (f = msg.getAssetTdyMean2()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDevLngPos: (f = msg.getAssetTdyStdDevLngPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDevShrPos: (f = msg.getAssetTdyStdDevShrPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDevLngNeg: (f = msg.getAssetTdyStdDevLngNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDevShrNeg: (f = msg.getAssetTdyStdDevShrNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDevLng: (f = msg.getAssetTdyStdDevLng()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDevShr: (f = msg.getAssetTdyStdDevShr()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDevPos: (f = msg.getAssetTdyStdDevPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDevNeg: (f = msg.getAssetTdyStdDevNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyStdDev: (f = msg.getAssetTdyStdDev()) && proto.assets.return.decimal.toObject(includeInstance, f),
    midnightTs: (f = msg.getMidnightTs()) && proto.assets.return.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_tdy_op_indicators}
 */
proto.assets.return.asset_tdy_op_indicators.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_tdy_op_indicators;
  return proto.assets.return.asset_tdy_op_indicators.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_tdy_op_indicators} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_tdy_op_indicators}
 */
proto.assets.return.asset_tdy_op_indicators.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyTradRetLngPos(value);
      break;
    case 2:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyTradRetShrPos(value);
      break;
    case 3:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyTradRetLngNeg(value);
      break;
    case 4:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyTradRetShrNeg(value);
      break;
    case 5:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyTradRet(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyDtPrincLngPos(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyDtPrincShrPos(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyDtPrincLngNeg(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyDtPrincShrNeg(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAssetTdyDtPrinc(value);
      break;
    case 11:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincLngPos(value);
      break;
    case 12:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincShrPos(value);
      break;
    case 13:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincLngNeg(value);
      break;
    case 14:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincShrNeg(value);
      break;
    case 15:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincLng(value);
      break;
    case 16:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincShr(value);
      break;
    case 17:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincPos(value);
      break;
    case 18:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrincNeg(value);
      break;
    case 19:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAvgPrinc(value);
      break;
    case 20:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanLngPos(value);
      break;
    case 21:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanShrPos(value);
      break;
    case 22:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanLngNeg(value);
      break;
    case 23:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanShrNeg(value);
      break;
    case 24:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanLng(value);
      break;
    case 25:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanShr(value);
      break;
    case 26:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanPos(value);
      break;
    case 27:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanNeg(value);
      break;
    case 28:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMean(value);
      break;
    case 29:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanLngPos2(value);
      break;
    case 30:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanShrPos2(value);
      break;
    case 31:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanLngNeg2(value);
      break;
    case 32:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanShrNeg2(value);
      break;
    case 33:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanLng2(value);
      break;
    case 34:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanShr2(value);
      break;
    case 35:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanPos2(value);
      break;
    case 36:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMeanNeg2(value);
      break;
    case 37:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyMean2(value);
      break;
    case 38:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDevLngPos(value);
      break;
    case 39:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDevShrPos(value);
      break;
    case 40:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDevLngNeg(value);
      break;
    case 41:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDevShrNeg(value);
      break;
    case 42:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDevLng(value);
      break;
    case 43:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDevShr(value);
      break;
    case 44:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDevPos(value);
      break;
    case 45:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDevNeg(value);
      break;
    case 46:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyStdDev(value);
      break;
    case 47:
      var value = new proto.assets.return.tstamp;
      reader.readMessage(value,proto.assets.return.tstamp.deserializeBinaryFromReader);
      msg.setMidnightTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_tdy_op_indicators.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_tdy_op_indicators} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_tdy_op_indicators.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyTradRetLngPos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyTradRetShrPos();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyTradRetLngNeg();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyTradRetShrNeg();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyTradRet();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getAssetTdyAvgPrincLngPos();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrincShrPos();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrincLngNeg();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrincShrNeg();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrincLng();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrincShr();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrincPos();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrincNeg();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyAvgPrinc();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanLngPos();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanShrPos();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanLngNeg();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanShrNeg();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanLng();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanShr();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanPos();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanNeg();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMean();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanLngPos2();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanShrPos2();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanLngNeg2();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanShrNeg2();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanLng2();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanShr2();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanPos2();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMeanNeg2();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyMean2();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDevLngPos();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDevShrPos();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDevLngNeg();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDevShrNeg();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDevLng();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDevShr();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDevPos();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDevNeg();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyStdDev();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getMidnightTs();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.assets.return.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_trad_ret_lng_pos = 1;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyTradRetLngPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 1));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyTradRetLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyTradRetLngPos = function() {
  return this.setAssetTdyTradRetLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyTradRetLngPos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_tdy_trad_ret_shr_pos = 2;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyTradRetShrPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 2));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyTradRetShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyTradRetShrPos = function() {
  return this.setAssetTdyTradRetShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyTradRetShrPos = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_tdy_trad_ret_lng_neg = 3;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyTradRetLngNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 3));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyTradRetLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyTradRetLngNeg = function() {
  return this.setAssetTdyTradRetLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyTradRetLngNeg = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal asset_tdy_trad_ret_shr_neg = 4;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyTradRetShrNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 4));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyTradRetShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyTradRetShrNeg = function() {
  return this.setAssetTdyTradRetShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyTradRetShrNeg = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal asset_tdy_trad_ret = 5;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyTradRet = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 5));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyTradRet = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyTradRet = function() {
  return this.setAssetTdyTradRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyTradRet = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 asset_tdy_dt_princ_lng_pos = 6;
 * @return {number}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyDtPrincLngPos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyDtPrincLngPos = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyDtPrincLngPos = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyDtPrincLngPos = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 asset_tdy_dt_princ_shr_pos = 7;
 * @return {number}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyDtPrincShrPos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyDtPrincShrPos = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyDtPrincShrPos = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyDtPrincShrPos = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 asset_tdy_dt_princ_lng_neg = 8;
 * @return {number}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyDtPrincLngNeg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyDtPrincLngNeg = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyDtPrincLngNeg = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyDtPrincLngNeg = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint64 asset_tdy_dt_princ_shr_neg = 9;
 * @return {number}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyDtPrincShrNeg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyDtPrincShrNeg = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyDtPrincShrNeg = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyDtPrincShrNeg = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional uint64 asset_tdy_dt_princ = 10;
 * @return {number}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyDtPrinc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyDtPrinc = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyDtPrinc = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyDtPrinc = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_lng_pos = 11;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrincLngPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 11));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrincLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrincLngPos = function() {
  return this.setAssetTdyAvgPrincLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrincLngPos = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_shr_pos = 12;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrincShrPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 12));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrincShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrincShrPos = function() {
  return this.setAssetTdyAvgPrincShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrincShrPos = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_lng_neg = 13;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrincLngNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 13));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrincLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrincLngNeg = function() {
  return this.setAssetTdyAvgPrincLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrincLngNeg = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_shr_neg = 14;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrincShrNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 14));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrincShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrincShrNeg = function() {
  return this.setAssetTdyAvgPrincShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrincShrNeg = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_lng = 15;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrincLng = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 15));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrincLng = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrincLng = function() {
  return this.setAssetTdyAvgPrincLng(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrincLng = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_shr = 16;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrincShr = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 16));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrincShr = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrincShr = function() {
  return this.setAssetTdyAvgPrincShr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrincShr = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_pos = 17;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrincPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 17));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrincPos = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrincPos = function() {
  return this.setAssetTdyAvgPrincPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrincPos = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional decimal asset_tdy_avg_princ_neg = 18;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrincNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 18));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrincNeg = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrincNeg = function() {
  return this.setAssetTdyAvgPrincNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrincNeg = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional decimal asset_tdy_avg_princ = 19;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyAvgPrinc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 19));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyAvgPrinc = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyAvgPrinc = function() {
  return this.setAssetTdyAvgPrinc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyAvgPrinc = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional decimal asset_tdy_mean_lng_pos = 20;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanLngPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 20));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanLngPos = function() {
  return this.setAssetTdyMeanLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanLngPos = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional decimal asset_tdy_mean_shr_pos = 21;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanShrPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 21));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanShrPos = function() {
  return this.setAssetTdyMeanShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanShrPos = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional decimal asset_tdy_mean_lng_neg = 22;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanLngNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 22));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanLngNeg = function() {
  return this.setAssetTdyMeanLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanLngNeg = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional decimal asset_tdy_mean_shr_neg = 23;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanShrNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 23));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanShrNeg = function() {
  return this.setAssetTdyMeanShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanShrNeg = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional decimal asset_tdy_mean_lng = 24;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanLng = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 24));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanLng = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanLng = function() {
  return this.setAssetTdyMeanLng(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanLng = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional decimal asset_tdy_mean_shr = 25;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanShr = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 25));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanShr = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanShr = function() {
  return this.setAssetTdyMeanShr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanShr = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional decimal asset_tdy_mean_pos = 26;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 26));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanPos = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanPos = function() {
  return this.setAssetTdyMeanPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanPos = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional decimal asset_tdy_mean_neg = 27;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 27));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanNeg = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanNeg = function() {
  return this.setAssetTdyMeanNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanNeg = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional decimal asset_tdy_mean = 28;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMean = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 28));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMean = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMean = function() {
  return this.setAssetTdyMean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMean = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional decimal asset_tdy_mean_lng_pos2 = 29;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanLngPos2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 29));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanLngPos2 = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanLngPos2 = function() {
  return this.setAssetTdyMeanLngPos2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanLngPos2 = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional decimal asset_tdy_mean_shr_pos2 = 30;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanShrPos2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 30));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanShrPos2 = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanShrPos2 = function() {
  return this.setAssetTdyMeanShrPos2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanShrPos2 = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional decimal asset_tdy_mean_lng_neg2 = 31;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanLngNeg2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 31));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanLngNeg2 = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanLngNeg2 = function() {
  return this.setAssetTdyMeanLngNeg2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanLngNeg2 = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional decimal asset_tdy_mean_shr_neg2 = 32;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanShrNeg2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 32));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanShrNeg2 = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanShrNeg2 = function() {
  return this.setAssetTdyMeanShrNeg2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanShrNeg2 = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional decimal asset_tdy_mean_lng2 = 33;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanLng2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 33));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanLng2 = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanLng2 = function() {
  return this.setAssetTdyMeanLng2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanLng2 = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional decimal asset_tdy_mean_shr2 = 34;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanShr2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 34));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanShr2 = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanShr2 = function() {
  return this.setAssetTdyMeanShr2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanShr2 = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional decimal asset_tdy_mean_pos2 = 35;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanPos2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 35));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanPos2 = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanPos2 = function() {
  return this.setAssetTdyMeanPos2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanPos2 = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional decimal asset_tdy_mean_neg2 = 36;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMeanNeg2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 36));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMeanNeg2 = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMeanNeg2 = function() {
  return this.setAssetTdyMeanNeg2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMeanNeg2 = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional decimal asset_tdy_mean2 = 37;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyMean2 = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 37));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyMean2 = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyMean2 = function() {
  return this.setAssetTdyMean2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyMean2 = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional decimal asset_tdy_std_dev_lng_pos = 38;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDevLngPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 38));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDevLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDevLngPos = function() {
  return this.setAssetTdyStdDevLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDevLngPos = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional decimal asset_tdy_std_dev_shr_pos = 39;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDevShrPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 39));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDevShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDevShrPos = function() {
  return this.setAssetTdyStdDevShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDevShrPos = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional decimal asset_tdy_std_dev_lng_neg = 40;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDevLngNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 40));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDevLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDevLngNeg = function() {
  return this.setAssetTdyStdDevLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDevLngNeg = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional decimal asset_tdy_std_dev_shr_neg = 41;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDevShrNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 41));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDevShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDevShrNeg = function() {
  return this.setAssetTdyStdDevShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDevShrNeg = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional decimal asset_tdy_std_dev_lng = 42;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDevLng = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 42));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDevLng = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDevLng = function() {
  return this.setAssetTdyStdDevLng(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDevLng = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional decimal asset_tdy_std_dev_shr = 43;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDevShr = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 43));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDevShr = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDevShr = function() {
  return this.setAssetTdyStdDevShr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDevShr = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional decimal asset_tdy_std_dev_pos = 44;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDevPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 44));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDevPos = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDevPos = function() {
  return this.setAssetTdyStdDevPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDevPos = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional decimal asset_tdy_std_dev_neg = 45;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDevNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 45));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDevNeg = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDevNeg = function() {
  return this.setAssetTdyStdDevNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDevNeg = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional decimal asset_tdy_std_dev = 46;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getAssetTdyStdDev = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 46));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setAssetTdyStdDev = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearAssetTdyStdDev = function() {
  return this.setAssetTdyStdDev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasAssetTdyStdDev = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional tstamp midnight_ts = 47;
 * @return {?proto.assets.return.tstamp}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.getMidnightTs = function() {
  return /** @type{?proto.assets.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.tstamp, 47));
};


/**
 * @param {?proto.assets.return.tstamp|undefined} value
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
*/
proto.assets.return.asset_tdy_op_indicators.prototype.setMidnightTs = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_tdy_op_indicators} returns this
 */
proto.assets.return.asset_tdy_op_indicators.prototype.clearMidnightTs = function() {
  return this.setMidnightTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_tdy_op_indicators.prototype.hasMidnightTs = function() {
  return jspb.Message.getField(this, 47) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_trad_ret.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_trad_ret.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_trad_ret} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_trad_ret.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    tradingRet: (f = msg.getTradingRet()) && proto.assets.return.decimal.toObject(includeInstance, f),
    tradingRetBc: (f = msg.getTradingRetBc()) && proto.assets.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_trad_ret}
 */
proto.assets.return.asset_trad_ret.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_trad_ret;
  return proto.assets.return.asset_trad_ret.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_trad_ret} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_trad_ret}
 */
proto.assets.return.asset_trad_ret.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setTradingRet(value);
      break;
    case 3:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setTradingRetBc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_trad_ret.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_trad_ret.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_trad_ret} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_trad_ret.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradingRet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.assets.return.asset_trad_ret.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.asset_trad_ret} returns this
 */
proto.assets.return.asset_trad_ret.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret} returns this
 */
proto.assets.return.asset_trad_ret.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal trading_ret = 2;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret.prototype.getTradingRet = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 2));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret} returns this
*/
proto.assets.return.asset_trad_ret.prototype.setTradingRet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret} returns this
 */
proto.assets.return.asset_trad_ret.prototype.clearTradingRet = function() {
  return this.setTradingRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret.prototype.hasTradingRet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal trading_ret_bc = 3;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret.prototype.getTradingRetBc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 3));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret} returns this
*/
proto.assets.return.asset_trad_ret.prototype.setTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret} returns this
 */
proto.assets.return.asset_trad_ret.prototype.clearTradingRetBc = function() {
  return this.setTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret.prototype.hasTradingRetBc = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_open_unit_ret.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_open_unit_ret.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_open_unit_ret} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_open_unit_ret.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTs: (f = msg.getStartTs()) && proto.assets.return.tstamp.toObject(includeInstance, f),
    unitRetOpen: (f = msg.getUnitRetOpen()) && proto.assets.return.decimal.toObject(includeInstance, f),
    unitRetClose: (f = msg.getUnitRetClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    endTs: (f = msg.getEndTs()) && proto.assets.return.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_open_unit_ret}
 */
proto.assets.return.asset_open_unit_ret.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_open_unit_ret;
  return proto.assets.return.asset_open_unit_ret.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_open_unit_ret} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_open_unit_ret}
 */
proto.assets.return.asset_open_unit_ret.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.return.tstamp;
      reader.readMessage(value,proto.assets.return.tstamp.deserializeBinaryFromReader);
      msg.setStartTs(value);
      break;
    case 2:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setUnitRetOpen(value);
      break;
    case 3:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setUnitRetClose(value);
      break;
    case 4:
      var value = new proto.assets.return.tstamp;
      reader.readMessage(value,proto.assets.return.tstamp.deserializeBinaryFromReader);
      msg.setEndTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_open_unit_ret.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_open_unit_ret.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_open_unit_ret} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_open_unit_ret.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.return.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getUnitRetOpen();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getUnitRetClose();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getEndTs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.return.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required tstamp start_ts = 1;
 * @return {!proto.assets.return.tstamp}
 */
proto.assets.return.asset_open_unit_ret.prototype.getStartTs = function() {
  return /** @type{!proto.assets.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.tstamp, 1, 1));
};


/**
 * @param {!proto.assets.return.tstamp} value
 * @return {!proto.assets.return.asset_open_unit_ret} returns this
*/
proto.assets.return.asset_open_unit_ret.prototype.setStartTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_open_unit_ret} returns this
 */
proto.assets.return.asset_open_unit_ret.prototype.clearStartTs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_open_unit_ret.prototype.hasStartTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required decimal unit_ret_open = 2;
 * @return {!proto.assets.return.decimal}
 */
proto.assets.return.asset_open_unit_ret.prototype.getUnitRetOpen = function() {
  return /** @type{!proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 2, 1));
};


/**
 * @param {!proto.assets.return.decimal} value
 * @return {!proto.assets.return.asset_open_unit_ret} returns this
*/
proto.assets.return.asset_open_unit_ret.prototype.setUnitRetOpen = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_open_unit_ret} returns this
 */
proto.assets.return.asset_open_unit_ret.prototype.clearUnitRetOpen = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_open_unit_ret.prototype.hasUnitRetOpen = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal unit_ret_close = 3;
 * @return {!proto.assets.return.decimal}
 */
proto.assets.return.asset_open_unit_ret.prototype.getUnitRetClose = function() {
  return /** @type{!proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 3, 1));
};


/**
 * @param {!proto.assets.return.decimal} value
 * @return {!proto.assets.return.asset_open_unit_ret} returns this
*/
proto.assets.return.asset_open_unit_ret.prototype.setUnitRetClose = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_open_unit_ret} returns this
 */
proto.assets.return.asset_open_unit_ret.prototype.clearUnitRetClose = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_open_unit_ret.prototype.hasUnitRetClose = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required tstamp end_ts = 4;
 * @return {!proto.assets.return.tstamp}
 */
proto.assets.return.asset_open_unit_ret.prototype.getEndTs = function() {
  return /** @type{!proto.assets.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.tstamp, 4, 1));
};


/**
 * @param {!proto.assets.return.tstamp} value
 * @return {!proto.assets.return.asset_open_unit_ret} returns this
*/
proto.assets.return.asset_open_unit_ret.prototype.setEndTs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_open_unit_ret} returns this
 */
proto.assets.return.asset_open_unit_ret.prototype.clearEndTs = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_open_unit_ret.prototype.hasEndTs = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_closed_unit_ret.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_closed_unit_ret.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_closed_unit_ret} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_closed_unit_ret.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTs: (f = msg.getStartTs()) && proto.assets.return.tstamp.toObject(includeInstance, f),
    unitRetOpen: (f = msg.getUnitRetOpen()) && proto.assets.return.decimal.toObject(includeInstance, f),
    unitRetClose: (f = msg.getUnitRetClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    endTs: (f = msg.getEndTs()) && proto.assets.return.tstamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_closed_unit_ret}
 */
proto.assets.return.asset_closed_unit_ret.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_closed_unit_ret;
  return proto.assets.return.asset_closed_unit_ret.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_closed_unit_ret} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_closed_unit_ret}
 */
proto.assets.return.asset_closed_unit_ret.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.return.tstamp;
      reader.readMessage(value,proto.assets.return.tstamp.deserializeBinaryFromReader);
      msg.setStartTs(value);
      break;
    case 2:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setUnitRetOpen(value);
      break;
    case 3:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setUnitRetClose(value);
      break;
    case 4:
      var value = new proto.assets.return.tstamp;
      reader.readMessage(value,proto.assets.return.tstamp.deserializeBinaryFromReader);
      msg.setEndTs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_closed_unit_ret.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_closed_unit_ret.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_closed_unit_ret} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_closed_unit_ret.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.return.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getUnitRetOpen();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getUnitRetClose();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getEndTs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.return.tstamp.serializeBinaryToWriter
    );
  }
};


/**
 * required tstamp start_ts = 1;
 * @return {!proto.assets.return.tstamp}
 */
proto.assets.return.asset_closed_unit_ret.prototype.getStartTs = function() {
  return /** @type{!proto.assets.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.tstamp, 1, 1));
};


/**
 * @param {!proto.assets.return.tstamp} value
 * @return {!proto.assets.return.asset_closed_unit_ret} returns this
*/
proto.assets.return.asset_closed_unit_ret.prototype.setStartTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_closed_unit_ret} returns this
 */
proto.assets.return.asset_closed_unit_ret.prototype.clearStartTs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_closed_unit_ret.prototype.hasStartTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required decimal unit_ret_open = 2;
 * @return {!proto.assets.return.decimal}
 */
proto.assets.return.asset_closed_unit_ret.prototype.getUnitRetOpen = function() {
  return /** @type{!proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 2, 1));
};


/**
 * @param {!proto.assets.return.decimal} value
 * @return {!proto.assets.return.asset_closed_unit_ret} returns this
*/
proto.assets.return.asset_closed_unit_ret.prototype.setUnitRetOpen = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_closed_unit_ret} returns this
 */
proto.assets.return.asset_closed_unit_ret.prototype.clearUnitRetOpen = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_closed_unit_ret.prototype.hasUnitRetOpen = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required decimal unit_ret_close = 3;
 * @return {!proto.assets.return.decimal}
 */
proto.assets.return.asset_closed_unit_ret.prototype.getUnitRetClose = function() {
  return /** @type{!proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 3, 1));
};


/**
 * @param {!proto.assets.return.decimal} value
 * @return {!proto.assets.return.asset_closed_unit_ret} returns this
*/
proto.assets.return.asset_closed_unit_ret.prototype.setUnitRetClose = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_closed_unit_ret} returns this
 */
proto.assets.return.asset_closed_unit_ret.prototype.clearUnitRetClose = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_closed_unit_ret.prototype.hasUnitRetClose = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required tstamp end_ts = 4;
 * @return {!proto.assets.return.tstamp}
 */
proto.assets.return.asset_closed_unit_ret.prototype.getEndTs = function() {
  return /** @type{!proto.assets.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.tstamp, 4, 1));
};


/**
 * @param {!proto.assets.return.tstamp} value
 * @return {!proto.assets.return.asset_closed_unit_ret} returns this
*/
proto.assets.return.asset_closed_unit_ret.prototype.setEndTs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_closed_unit_ret} returns this
 */
proto.assets.return.asset_closed_unit_ret.prototype.clearEndTs = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_closed_unit_ret.prototype.hasEndTs = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_all_ret_bc.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_all_ret_bc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_all_ret_bc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_all_ret_bc.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    allRetBc: (f = msg.getAllRetBc()) && proto.assets.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_all_ret_bc}
 */
proto.assets.return.asset_all_ret_bc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_all_ret_bc;
  return proto.assets.return.asset_all_ret_bc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_all_ret_bc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_all_ret_bc}
 */
proto.assets.return.asset_all_ret_bc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAllRetBc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_all_ret_bc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_all_ret_bc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_all_ret_bc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_all_ret_bc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllRetBc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.assets.return.asset_all_ret_bc.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.asset_all_ret_bc} returns this
 */
proto.assets.return.asset_all_ret_bc.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_all_ret_bc} returns this
 */
proto.assets.return.asset_all_ret_bc.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_all_ret_bc.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal all_ret_bc = 2;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_all_ret_bc.prototype.getAllRetBc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 2));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_all_ret_bc} returns this
*/
proto.assets.return.asset_all_ret_bc.prototype.setAllRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_all_ret_bc} returns this
 */
proto.assets.return.asset_all_ret_bc.prototype.clearAllRetBc = function() {
  return this.setAllRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_all_ret_bc.prototype.hasAllRetBc = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_exch_ret_bc.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_exch_ret_bc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_exch_ret_bc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_exch_ret_bc.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    exchRetBc: (f = msg.getExchRetBc()) && proto.assets.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_exch_ret_bc}
 */
proto.assets.return.asset_exch_ret_bc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_exch_ret_bc;
  return proto.assets.return.asset_exch_ret_bc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_exch_ret_bc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_exch_ret_bc}
 */
proto.assets.return.asset_exch_ret_bc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setExchRetBc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_exch_ret_bc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_exch_ret_bc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_exch_ret_bc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_exch_ret_bc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExchRetBc();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.assets.return.asset_exch_ret_bc.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.asset_exch_ret_bc} returns this
 */
proto.assets.return.asset_exch_ret_bc.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.asset_exch_ret_bc} returns this
 */
proto.assets.return.asset_exch_ret_bc.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_exch_ret_bc.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal exch_ret_bc = 2;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_exch_ret_bc.prototype.getExchRetBc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 2));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_exch_ret_bc} returns this
*/
proto.assets.return.asset_exch_ret_bc.prototype.setExchRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_exch_ret_bc} returns this
 */
proto.assets.return.asset_exch_ret_bc.prototype.clearExchRetBc = function() {
  return this.setExchRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_exch_ret_bc.prototype.hasExchRetBc = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.return_analysis.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.return_analysis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.return_analysis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.return_analysis.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    market: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    currency: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    assetClass: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    tradingRetBc: (f = msg.getTradingRetBc()) && proto.assets.return.decimal.toObject(includeInstance, f),
    openTradingRetBc: (f = msg.getOpenTradingRetBc()) && proto.assets.return.decimal.toObject(includeInstance, f),
    closeTradingRetBc: (f = msg.getCloseTradingRetBc()) && proto.assets.return.decimal.toObject(includeInstance, f),
    longTradingRetBc: (f = msg.getLongTradingRetBc()) && proto.assets.return.decimal.toObject(includeInstance, f),
    shortTradingRetBc: (f = msg.getShortTradingRetBc()) && proto.assets.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.return_analysis}
 */
proto.assets.return.return_analysis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.return_analysis;
  return proto.assets.return.return_analysis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.return_analysis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.return_analysis}
 */
proto.assets.return.return_analysis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarket(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetClass(value);
      break;
    case 5:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setTradingRetBc(value);
      break;
    case 6:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setOpenTradingRetBc(value);
      break;
    case 7:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setCloseTradingRetBc(value);
      break;
    case 8:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setLongTradingRetBc(value);
      break;
    case 9:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setShortTradingRetBc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.return_analysis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.return_analysis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.return_analysis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.return_analysis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getOpenTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getCloseTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getLongTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getShortTradingRetBc();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * required string type = 1;
 * @return {string}
 */
proto.assets.return.return_analysis.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string market = 2;
 * @return {string}
 */
proto.assets.return.return_analysis.prototype.getMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.setMarket = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearMarket = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasMarket = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string currency = 3;
 * @return {string}
 */
proto.assets.return.return_analysis.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.setCurrency = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearCurrency = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string asset_class = 4;
 * @return {string}
 */
proto.assets.return.return_analysis.prototype.getAssetClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.setAssetClass = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearAssetClass = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasAssetClass = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal trading_ret_bc = 5;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.return_analysis.prototype.getTradingRetBc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 5));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.return_analysis} returns this
*/
proto.assets.return.return_analysis.prototype.setTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearTradingRetBc = function() {
  return this.setTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasTradingRetBc = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal open_trading_ret_bc = 6;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.return_analysis.prototype.getOpenTradingRetBc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 6));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.return_analysis} returns this
*/
proto.assets.return.return_analysis.prototype.setOpenTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearOpenTradingRetBc = function() {
  return this.setOpenTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasOpenTradingRetBc = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional decimal close_trading_ret_bc = 7;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.return_analysis.prototype.getCloseTradingRetBc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 7));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.return_analysis} returns this
*/
proto.assets.return.return_analysis.prototype.setCloseTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearCloseTradingRetBc = function() {
  return this.setCloseTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasCloseTradingRetBc = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal long_trading_ret_bc = 8;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.return_analysis.prototype.getLongTradingRetBc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 8));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.return_analysis} returns this
*/
proto.assets.return.return_analysis.prototype.setLongTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearLongTradingRetBc = function() {
  return this.setLongTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasLongTradingRetBc = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal short_trading_ret_bc = 9;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.return_analysis.prototype.getShortTradingRetBc = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 9));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.return_analysis} returns this
*/
proto.assets.return.return_analysis.prototype.setShortTradingRetBc = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.return_analysis} returns this
 */
proto.assets.return.return_analysis.prototype.clearShortTradingRetBc = function() {
  return this.setShortTradingRetBc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.return_analysis.prototype.hasShortTradingRetBc = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_trad_ret_close_end.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_trad_ret_close_end} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_trad_ret_close_end.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetYtdTradingRetEnd: (f = msg.getAssetYtdTradingRetEnd()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetYtdTradingRetBcEnd: (f = msg.getAssetYtdTradingRetBcEnd()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetYtdTradingRetBcLongEnd: (f = msg.getAssetYtdTradingRetBcLongEnd()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetYtdTradingRetBcShortEnd: (f = msg.getAssetYtdTradingRetBcShortEnd()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyClosedTradRetLngPos: (f = msg.getAssetTdyClosedTradRetLngPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyClosedTradRetShrPos: (f = msg.getAssetTdyClosedTradRetShrPos()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyClosedTradRetLngNeg: (f = msg.getAssetTdyClosedTradRetLngNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyClosedTradRetShrNeg: (f = msg.getAssetTdyClosedTradRetShrNeg()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyTradingRetBcClose: (f = msg.getAssetTdyTradingRetBcClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTotTradingRetClose: (f = msg.getAssetTotTradingRetClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTotTradingRetBcClose: (f = msg.getAssetTotTradingRetBcClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTotTradingRetBcPositiveClose: (f = msg.getAssetTotTradingRetBcPositiveClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyTradingRetBcLongClose: (f = msg.getAssetTdyTradingRetBcLongClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetTdyTradingRetBcShortClose: (f = msg.getAssetTdyTradingRetBcShortClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetYtdTradingRetBcClose: (f = msg.getAssetYtdTradingRetBcClose()) && proto.assets.return.decimal.toObject(includeInstance, f),
    assetYtdTradingRetBcCloseEnd: (f = msg.getAssetYtdTradingRetBcCloseEnd()) && proto.assets.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_trad_ret_close_end}
 */
proto.assets.return.asset_trad_ret_close_end.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_trad_ret_close_end;
  return proto.assets.return.asset_trad_ret_close_end.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_trad_ret_close_end} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_trad_ret_close_end}
 */
proto.assets.return.asset_trad_ret_close_end.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdTradingRetEnd(value);
      break;
    case 2:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdTradingRetBcEnd(value);
      break;
    case 3:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdTradingRetBcLongEnd(value);
      break;
    case 4:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdTradingRetBcShortEnd(value);
      break;
    case 5:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedTradRetLngPos(value);
      break;
    case 6:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedTradRetShrPos(value);
      break;
    case 7:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedTradRetLngNeg(value);
      break;
    case 8:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyClosedTradRetShrNeg(value);
      break;
    case 9:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyTradingRetBcClose(value);
      break;
    case 10:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTotTradingRetClose(value);
      break;
    case 11:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTotTradingRetBcClose(value);
      break;
    case 12:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTotTradingRetBcPositiveClose(value);
      break;
    case 13:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyTradingRetBcLongClose(value);
      break;
    case 14:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyTradingRetBcShortClose(value);
      break;
    case 15:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdTradingRetBcClose(value);
      break;
    case 16:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdTradingRetBcCloseEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_trad_ret_close_end.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_trad_ret_close_end} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_trad_ret_close_end.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetYtdTradingRetEnd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdTradingRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdTradingRetBcLongEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdTradingRetBcShortEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedTradRetLngPos();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedTradRetShrPos();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedTradRetLngNeg();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyClosedTradRetShrNeg();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyTradingRetBcClose();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotTradingRetClose();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotTradingRetBcClose();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTotTradingRetBcPositiveClose();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyTradingRetBcLongClose();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetTdyTradingRetBcShortClose();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdTradingRetBcClose();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
  f = message.getAssetYtdTradingRetBcCloseEnd();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_ytd_trading_ret_end = 1;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetYtdTradingRetEnd = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 1));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetYtdTradingRetEnd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetYtdTradingRetEnd = function() {
  return this.setAssetYtdTradingRetEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetYtdTradingRetEnd = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional decimal asset_ytd_trading_ret_bc_end = 2;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetYtdTradingRetBcEnd = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 2));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetYtdTradingRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetYtdTradingRetBcEnd = function() {
  return this.setAssetYtdTradingRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetYtdTradingRetBcEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional decimal asset_ytd_trading_ret_bc_long_end = 3;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetYtdTradingRetBcLongEnd = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 3));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetYtdTradingRetBcLongEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetYtdTradingRetBcLongEnd = function() {
  return this.setAssetYtdTradingRetBcLongEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetYtdTradingRetBcLongEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional decimal asset_ytd_trading_ret_bc_short_end = 4;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetYtdTradingRetBcShortEnd = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 4));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetYtdTradingRetBcShortEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetYtdTradingRetBcShortEnd = function() {
  return this.setAssetYtdTradingRetBcShortEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetYtdTradingRetBcShortEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional decimal asset_tdy_closed_trad_ret_lng_pos = 5;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTdyClosedTradRetLngPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 5));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTdyClosedTradRetLngPos = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTdyClosedTradRetLngPos = function() {
  return this.setAssetTdyClosedTradRetLngPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTdyClosedTradRetLngPos = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional decimal asset_tdy_closed_trad_ret_shr_pos = 6;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTdyClosedTradRetShrPos = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 6));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTdyClosedTradRetShrPos = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTdyClosedTradRetShrPos = function() {
  return this.setAssetTdyClosedTradRetShrPos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTdyClosedTradRetShrPos = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional decimal asset_tdy_closed_trad_ret_lng_neg = 7;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTdyClosedTradRetLngNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 7));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTdyClosedTradRetLngNeg = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTdyClosedTradRetLngNeg = function() {
  return this.setAssetTdyClosedTradRetLngNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTdyClosedTradRetLngNeg = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional decimal asset_tdy_closed_trad_ret_shr_neg = 8;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTdyClosedTradRetShrNeg = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 8));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTdyClosedTradRetShrNeg = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTdyClosedTradRetShrNeg = function() {
  return this.setAssetTdyClosedTradRetShrNeg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTdyClosedTradRetShrNeg = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional decimal asset_tdy_trading_ret_bc_close = 9;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTdyTradingRetBcClose = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 9));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTdyTradingRetBcClose = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTdyTradingRetBcClose = function() {
  return this.setAssetTdyTradingRetBcClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTdyTradingRetBcClose = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional decimal asset_tot_trading_ret_close = 10;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTotTradingRetClose = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 10));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTotTradingRetClose = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTotTradingRetClose = function() {
  return this.setAssetTotTradingRetClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTotTradingRetClose = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional decimal asset_tot_trading_ret_bc_close = 11;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTotTradingRetBcClose = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 11));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTotTradingRetBcClose = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTotTradingRetBcClose = function() {
  return this.setAssetTotTradingRetBcClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTotTradingRetBcClose = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional decimal asset_tot_trading_ret_bc_positive_close = 12;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTotTradingRetBcPositiveClose = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 12));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTotTradingRetBcPositiveClose = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTotTradingRetBcPositiveClose = function() {
  return this.setAssetTotTradingRetBcPositiveClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTotTradingRetBcPositiveClose = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional decimal asset_tdy_trading_ret_bc_long_close = 13;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTdyTradingRetBcLongClose = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 13));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTdyTradingRetBcLongClose = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTdyTradingRetBcLongClose = function() {
  return this.setAssetTdyTradingRetBcLongClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTdyTradingRetBcLongClose = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional decimal asset_tdy_trading_ret_bc_short_close = 14;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetTdyTradingRetBcShortClose = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 14));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetTdyTradingRetBcShortClose = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetTdyTradingRetBcShortClose = function() {
  return this.setAssetTdyTradingRetBcShortClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetTdyTradingRetBcShortClose = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional decimal asset_ytd_trading_ret_bc_close = 15;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetYtdTradingRetBcClose = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 15));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetYtdTradingRetBcClose = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetYtdTradingRetBcClose = function() {
  return this.setAssetYtdTradingRetBcClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetYtdTradingRetBcClose = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional decimal asset_ytd_trading_ret_bc_close_end = 16;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.getAssetYtdTradingRetBcCloseEnd = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 16));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
*/
proto.assets.return.asset_trad_ret_close_end.prototype.setAssetYtdTradingRetBcCloseEnd = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_trad_ret_close_end} returns this
 */
proto.assets.return.asset_trad_ret_close_end.prototype.clearAssetYtdTradingRetBcCloseEnd = function() {
  return this.setAssetYtdTradingRetBcCloseEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_trad_ret_close_end.prototype.hasAssetYtdTradingRetBcCloseEnd = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_all_ret_bc_close.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_all_ret_bc_close.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_all_ret_bc_close} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_all_ret_bc_close.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyAllRetBcClose: (f = msg.getAssetTdyAllRetBcClose()) && proto.assets.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_all_ret_bc_close}
 */
proto.assets.return.asset_all_ret_bc_close.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_all_ret_bc_close;
  return proto.assets.return.asset_all_ret_bc_close.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_all_ret_bc_close} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_all_ret_bc_close}
 */
proto.assets.return.asset_all_ret_bc_close.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetTdyAllRetBcClose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_all_ret_bc_close.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_all_ret_bc_close.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_all_ret_bc_close} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_all_ret_bc_close.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyAllRetBcClose();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_tdy_all_ret_bc_close = 1;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_all_ret_bc_close.prototype.getAssetTdyAllRetBcClose = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 1));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_all_ret_bc_close} returns this
*/
proto.assets.return.asset_all_ret_bc_close.prototype.setAssetTdyAllRetBcClose = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_all_ret_bc_close} returns this
 */
proto.assets.return.asset_all_ret_bc_close.prototype.clearAssetTdyAllRetBcClose = function() {
  return this.setAssetTdyAllRetBcClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_all_ret_bc_close.prototype.hasAssetTdyAllRetBcClose = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.asset_all_ret_bc_end.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.asset_all_ret_bc_end.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.asset_all_ret_bc_end} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_all_ret_bc_end.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetYtdAllRetBcEnd: (f = msg.getAssetYtdAllRetBcEnd()) && proto.assets.return.decimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.asset_all_ret_bc_end}
 */
proto.assets.return.asset_all_ret_bc_end.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.asset_all_ret_bc_end;
  return proto.assets.return.asset_all_ret_bc_end.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.asset_all_ret_bc_end} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.asset_all_ret_bc_end}
 */
proto.assets.return.asset_all_ret_bc_end.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.return.decimal;
      reader.readMessage(value,proto.assets.return.decimal.deserializeBinaryFromReader);
      msg.setAssetYtdAllRetBcEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.asset_all_ret_bc_end.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.asset_all_ret_bc_end.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.asset_all_ret_bc_end} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.asset_all_ret_bc_end.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetYtdAllRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.return.decimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional decimal asset_ytd_all_ret_bc_end = 1;
 * @return {?proto.assets.return.decimal}
 */
proto.assets.return.asset_all_ret_bc_end.prototype.getAssetYtdAllRetBcEnd = function() {
  return /** @type{?proto.assets.return.decimal} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.decimal, 1));
};


/**
 * @param {?proto.assets.return.decimal|undefined} value
 * @return {!proto.assets.return.asset_all_ret_bc_end} returns this
*/
proto.assets.return.asset_all_ret_bc_end.prototype.setAssetYtdAllRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.asset_all_ret_bc_end} returns this
 */
proto.assets.return.asset_all_ret_bc_end.prototype.clearAssetYtdAllRetBcEnd = function() {
  return this.setAssetYtdAllRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.asset_all_ret_bc_end.prototype.hasAssetYtdAllRetBcEnd = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.assets.return.update_payload.repeatedFields_ = [31,34,35,36];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.update_payload.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.update_payload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.update_payload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.update_payload.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetTdyOpIndicators: (f = msg.getAssetTdyOpIndicators()) && proto.assets.return.asset_tdy_op_indicators.toObject(includeInstance, f),
    assetTradRetList: jspb.Message.toObjectList(msg.getAssetTradRetList(),
    proto.assets.return.asset_trad_ret.toObject, includeInstance),
    assetOpenUnitRet: (f = msg.getAssetOpenUnitRet()) && proto.assets.return.asset_open_unit_ret.toObject(includeInstance, f),
    assetClosedUnitRet: (f = msg.getAssetClosedUnitRet()) && proto.assets.return.asset_closed_unit_ret.toObject(includeInstance, f),
    assetAllRetBcList: jspb.Message.toObjectList(msg.getAssetAllRetBcList(),
    proto.assets.return.asset_all_ret_bc.toObject, includeInstance),
    assetExchRetBcList: jspb.Message.toObjectList(msg.getAssetExchRetBcList(),
    proto.assets.return.asset_exch_ret_bc.toObject, includeInstance),
    returnAnalysisList: jspb.Message.toObjectList(msg.getReturnAnalysisList(),
    proto.assets.return.return_analysis.toObject, includeInstance),
    assetTradRetCloseEnd: (f = msg.getAssetTradRetCloseEnd()) && proto.assets.return.asset_trad_ret_close_end.toObject(includeInstance, f),
    assetAllRetBcClose: (f = msg.getAssetAllRetBcClose()) && proto.assets.return.asset_all_ret_bc_close.toObject(includeInstance, f),
    assetAllRetBcEnd: (f = msg.getAssetAllRetBcEnd()) && proto.assets.return.asset_all_ret_bc_end.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.update_payload}
 */
proto.assets.return.update_payload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.update_payload;
  return proto.assets.return.update_payload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.update_payload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.update_payload}
 */
proto.assets.return.update_payload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 30:
      var value = new proto.assets.return.asset_tdy_op_indicators;
      reader.readMessage(value,proto.assets.return.asset_tdy_op_indicators.deserializeBinaryFromReader);
      msg.setAssetTdyOpIndicators(value);
      break;
    case 31:
      var value = new proto.assets.return.asset_trad_ret;
      reader.readMessage(value,proto.assets.return.asset_trad_ret.deserializeBinaryFromReader);
      msg.addAssetTradRet(value);
      break;
    case 32:
      var value = new proto.assets.return.asset_open_unit_ret;
      reader.readMessage(value,proto.assets.return.asset_open_unit_ret.deserializeBinaryFromReader);
      msg.setAssetOpenUnitRet(value);
      break;
    case 33:
      var value = new proto.assets.return.asset_closed_unit_ret;
      reader.readMessage(value,proto.assets.return.asset_closed_unit_ret.deserializeBinaryFromReader);
      msg.setAssetClosedUnitRet(value);
      break;
    case 34:
      var value = new proto.assets.return.asset_all_ret_bc;
      reader.readMessage(value,proto.assets.return.asset_all_ret_bc.deserializeBinaryFromReader);
      msg.addAssetAllRetBc(value);
      break;
    case 35:
      var value = new proto.assets.return.asset_exch_ret_bc;
      reader.readMessage(value,proto.assets.return.asset_exch_ret_bc.deserializeBinaryFromReader);
      msg.addAssetExchRetBc(value);
      break;
    case 36:
      var value = new proto.assets.return.return_analysis;
      reader.readMessage(value,proto.assets.return.return_analysis.deserializeBinaryFromReader);
      msg.addReturnAnalysis(value);
      break;
    case 37:
      var value = new proto.assets.return.asset_trad_ret_close_end;
      reader.readMessage(value,proto.assets.return.asset_trad_ret_close_end.deserializeBinaryFromReader);
      msg.setAssetTradRetCloseEnd(value);
      break;
    case 38:
      var value = new proto.assets.return.asset_all_ret_bc_close;
      reader.readMessage(value,proto.assets.return.asset_all_ret_bc_close.deserializeBinaryFromReader);
      msg.setAssetAllRetBcClose(value);
      break;
    case 39:
      var value = new proto.assets.return.asset_all_ret_bc_end;
      reader.readMessage(value,proto.assets.return.asset_all_ret_bc_end.deserializeBinaryFromReader);
      msg.setAssetAllRetBcEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.update_payload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.update_payload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.update_payload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.update_payload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetTdyOpIndicators();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.assets.return.asset_tdy_op_indicators.serializeBinaryToWriter
    );
  }
  f = message.getAssetTradRetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      proto.assets.return.asset_trad_ret.serializeBinaryToWriter
    );
  }
  f = message.getAssetOpenUnitRet();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.assets.return.asset_open_unit_ret.serializeBinaryToWriter
    );
  }
  f = message.getAssetClosedUnitRet();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.assets.return.asset_closed_unit_ret.serializeBinaryToWriter
    );
  }
  f = message.getAssetAllRetBcList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      34,
      f,
      proto.assets.return.asset_all_ret_bc.serializeBinaryToWriter
    );
  }
  f = message.getAssetExchRetBcList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      proto.assets.return.asset_exch_ret_bc.serializeBinaryToWriter
    );
  }
  f = message.getReturnAnalysisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      proto.assets.return.return_analysis.serializeBinaryToWriter
    );
  }
  f = message.getAssetTradRetCloseEnd();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.assets.return.asset_trad_ret_close_end.serializeBinaryToWriter
    );
  }
  f = message.getAssetAllRetBcClose();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.assets.return.asset_all_ret_bc_close.serializeBinaryToWriter
    );
  }
  f = message.getAssetAllRetBcEnd();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.assets.return.asset_all_ret_bc_end.serializeBinaryToWriter
    );
  }
};


/**
 * optional asset_tdy_op_indicators asset_tdy_op_indicators_ = 30;
 * @return {?proto.assets.return.asset_tdy_op_indicators}
 */
proto.assets.return.update_payload.prototype.getAssetTdyOpIndicators = function() {
  return /** @type{?proto.assets.return.asset_tdy_op_indicators} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.asset_tdy_op_indicators, 30));
};


/**
 * @param {?proto.assets.return.asset_tdy_op_indicators|undefined} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetTdyOpIndicators = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetTdyOpIndicators = function() {
  return this.setAssetTdyOpIndicators(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_payload.prototype.hasAssetTdyOpIndicators = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated asset_trad_ret asset_trad_ret_ = 31;
 * @return {!Array<!proto.assets.return.asset_trad_ret>}
 */
proto.assets.return.update_payload.prototype.getAssetTradRetList = function() {
  return /** @type{!Array<!proto.assets.return.asset_trad_ret>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assets.return.asset_trad_ret, 31));
};


/**
 * @param {!Array<!proto.assets.return.asset_trad_ret>} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetTradRetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.assets.return.asset_trad_ret=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assets.return.asset_trad_ret}
 */
proto.assets.return.update_payload.prototype.addAssetTradRet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.assets.return.asset_trad_ret, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetTradRetList = function() {
  return this.setAssetTradRetList([]);
};


/**
 * optional asset_open_unit_ret asset_open_unit_ret_ = 32;
 * @return {?proto.assets.return.asset_open_unit_ret}
 */
proto.assets.return.update_payload.prototype.getAssetOpenUnitRet = function() {
  return /** @type{?proto.assets.return.asset_open_unit_ret} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.asset_open_unit_ret, 32));
};


/**
 * @param {?proto.assets.return.asset_open_unit_ret|undefined} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetOpenUnitRet = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetOpenUnitRet = function() {
  return this.setAssetOpenUnitRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_payload.prototype.hasAssetOpenUnitRet = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional asset_closed_unit_ret asset_closed_unit_ret_ = 33;
 * @return {?proto.assets.return.asset_closed_unit_ret}
 */
proto.assets.return.update_payload.prototype.getAssetClosedUnitRet = function() {
  return /** @type{?proto.assets.return.asset_closed_unit_ret} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.asset_closed_unit_ret, 33));
};


/**
 * @param {?proto.assets.return.asset_closed_unit_ret|undefined} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetClosedUnitRet = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetClosedUnitRet = function() {
  return this.setAssetClosedUnitRet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_payload.prototype.hasAssetClosedUnitRet = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * repeated asset_all_ret_bc asset_all_ret_bc_ = 34;
 * @return {!Array<!proto.assets.return.asset_all_ret_bc>}
 */
proto.assets.return.update_payload.prototype.getAssetAllRetBcList = function() {
  return /** @type{!Array<!proto.assets.return.asset_all_ret_bc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assets.return.asset_all_ret_bc, 34));
};


/**
 * @param {!Array<!proto.assets.return.asset_all_ret_bc>} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetAllRetBcList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 34, value);
};


/**
 * @param {!proto.assets.return.asset_all_ret_bc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assets.return.asset_all_ret_bc}
 */
proto.assets.return.update_payload.prototype.addAssetAllRetBc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 34, opt_value, proto.assets.return.asset_all_ret_bc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetAllRetBcList = function() {
  return this.setAssetAllRetBcList([]);
};


/**
 * repeated asset_exch_ret_bc asset_exch_ret_bc_ = 35;
 * @return {!Array<!proto.assets.return.asset_exch_ret_bc>}
 */
proto.assets.return.update_payload.prototype.getAssetExchRetBcList = function() {
  return /** @type{!Array<!proto.assets.return.asset_exch_ret_bc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assets.return.asset_exch_ret_bc, 35));
};


/**
 * @param {!Array<!proto.assets.return.asset_exch_ret_bc>} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetExchRetBcList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.assets.return.asset_exch_ret_bc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assets.return.asset_exch_ret_bc}
 */
proto.assets.return.update_payload.prototype.addAssetExchRetBc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.assets.return.asset_exch_ret_bc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetExchRetBcList = function() {
  return this.setAssetExchRetBcList([]);
};


/**
 * repeated return_analysis return_analysis_ = 36;
 * @return {!Array<!proto.assets.return.return_analysis>}
 */
proto.assets.return.update_payload.prototype.getReturnAnalysisList = function() {
  return /** @type{!Array<!proto.assets.return.return_analysis>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.assets.return.return_analysis, 36));
};


/**
 * @param {!Array<!proto.assets.return.return_analysis>} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setReturnAnalysisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};


/**
 * @param {!proto.assets.return.return_analysis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.assets.return.return_analysis}
 */
proto.assets.return.update_payload.prototype.addReturnAnalysis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 36, opt_value, proto.assets.return.return_analysis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearReturnAnalysisList = function() {
  return this.setReturnAnalysisList([]);
};


/**
 * optional asset_trad_ret_close_end asset_trad_ret_close_end_ = 37;
 * @return {?proto.assets.return.asset_trad_ret_close_end}
 */
proto.assets.return.update_payload.prototype.getAssetTradRetCloseEnd = function() {
  return /** @type{?proto.assets.return.asset_trad_ret_close_end} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.asset_trad_ret_close_end, 37));
};


/**
 * @param {?proto.assets.return.asset_trad_ret_close_end|undefined} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetTradRetCloseEnd = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetTradRetCloseEnd = function() {
  return this.setAssetTradRetCloseEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_payload.prototype.hasAssetTradRetCloseEnd = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional asset_all_ret_bc_close asset_all_ret_bc_close_ = 38;
 * @return {?proto.assets.return.asset_all_ret_bc_close}
 */
proto.assets.return.update_payload.prototype.getAssetAllRetBcClose = function() {
  return /** @type{?proto.assets.return.asset_all_ret_bc_close} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.asset_all_ret_bc_close, 38));
};


/**
 * @param {?proto.assets.return.asset_all_ret_bc_close|undefined} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetAllRetBcClose = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetAllRetBcClose = function() {
  return this.setAssetAllRetBcClose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_payload.prototype.hasAssetAllRetBcClose = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional asset_all_ret_bc_end asset_all_ret_bc_end_ = 39;
 * @return {?proto.assets.return.asset_all_ret_bc_end}
 */
proto.assets.return.update_payload.prototype.getAssetAllRetBcEnd = function() {
  return /** @type{?proto.assets.return.asset_all_ret_bc_end} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.asset_all_ret_bc_end, 39));
};


/**
 * @param {?proto.assets.return.asset_all_ret_bc_end|undefined} value
 * @return {!proto.assets.return.update_payload} returns this
*/
proto.assets.return.update_payload.prototype.setAssetAllRetBcEnd = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.update_payload} returns this
 */
proto.assets.return.update_payload.prototype.clearAssetAllRetBcEnd = function() {
  return this.setAssetAllRetBcEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_payload.prototype.hasAssetAllRetBcEnd = function() {
  return jspb.Message.getField(this, 39) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.update_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.update_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.update_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.update_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.return.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.return.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.update_snapshot}
 */
proto.assets.return.update_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.update_snapshot;
  return proto.assets.return.update_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.update_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.update_snapshot}
 */
proto.assets.return.update_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.return.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.assets.return.tstamp;
      reader.readMessage(value,proto.assets.return.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 6:
      var value = new proto.assets.return.update_payload;
      reader.readMessage(value,proto.assets.return.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.update_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.update_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.update_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.update_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.return.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.assets.return.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.return.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.return.update_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.return.update_type}
 */
proto.assets.return.update_snapshot.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.return.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.return.update_type} value
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_snapshot.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.return.update_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required string key = 4;
 * @return {string}
 */
proto.assets.return.update_snapshot.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.clearKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_snapshot.prototype.hasKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required tstamp event_ts = 5;
 * @return {!proto.assets.return.tstamp}
 */
proto.assets.return.update_snapshot.prototype.getEventTs = function() {
  return /** @type{!proto.assets.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.tstamp, 5, 1));
};


/**
 * @param {!proto.assets.return.tstamp} value
 * @return {!proto.assets.return.update_snapshot} returns this
*/
proto.assets.return.update_snapshot.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_snapshot.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional update_payload sigmas = 6;
 * @return {?proto.assets.return.update_payload}
 */
proto.assets.return.update_snapshot.prototype.getSigmas = function() {
  return /** @type{?proto.assets.return.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.update_payload, 6));
};


/**
 * @param {?proto.assets.return.update_payload|undefined} value
 * @return {!proto.assets.return.update_snapshot} returns this
*/
proto.assets.return.update_snapshot.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.update_snapshot} returns this
 */
proto.assets.return.update_snapshot.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_snapshot.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.end_snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.end_snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.end_snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.end_snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.end_snapshot}
 */
proto.assets.return.end_snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.end_snapshot;
  return proto.assets.return.end_snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.end_snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.end_snapshot}
 */
proto.assets.return.end_snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.end_snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.end_snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.end_snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.end_snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.return.end_snapshot.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.end_snapshot} returns this
 */
proto.assets.return.end_snapshot.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.end_snapshot} returns this
 */
proto.assets.return.end_snapshot.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.end_snapshot.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required string service = 2;
 * @return {string}
 */
proto.assets.return.end_snapshot.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.end_snapshot} returns this
 */
proto.assets.return.end_snapshot.prototype.setService = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.end_snapshot} returns this
 */
proto.assets.return.end_snapshot.prototype.clearService = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.end_snapshot.prototype.hasService = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.update_ticker.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.update_ticker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.update_ticker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.update_ticker.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    updateType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    service: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    sequence: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    key: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    eventTs: (f = msg.getEventTs()) && proto.assets.return.tstamp.toObject(includeInstance, f),
    sigmas: (f = msg.getSigmas()) && proto.assets.return.update_payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.update_ticker}
 */
proto.assets.return.update_ticker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.update_ticker;
  return proto.assets.return.update_ticker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.update_ticker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.update_ticker}
 */
proto.assets.return.update_ticker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.assets.return.update_type} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSequence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = new proto.assets.return.tstamp;
      reader.readMessage(value,proto.assets.return.tstamp.deserializeBinaryFromReader);
      msg.setEventTs(value);
      break;
    case 7:
      var value = new proto.assets.return.update_payload;
      reader.readMessage(value,proto.assets.return.update_payload.deserializeBinaryFromReader);
      msg.setSigmas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.update_ticker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.update_ticker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.update_ticker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.update_ticker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!proto.assets.return.update_type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.assets.return.tstamp.serializeBinaryToWriter
    );
  }
  f = message.getSigmas();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.assets.return.update_payload.serializeBinaryToWriter
    );
  }
};


/**
 * required uint64 subscription_id = 1;
 * @return {number}
 */
proto.assets.return.update_ticker.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.clearSubscriptionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_ticker.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * required update_type update_type = 2;
 * @return {!proto.assets.return.update_type}
 */
proto.assets.return.update_ticker.prototype.getUpdateType = function() {
  return /** @type {!proto.assets.return.update_type} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.assets.return.update_type} value
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_ticker.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * required string service = 3;
 * @return {string}
 */
proto.assets.return.update_ticker.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.setService = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.clearService = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_ticker.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * required sint64 sequence = 4;
 * @return {number}
 */
proto.assets.return.update_ticker.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.setSequence = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.clearSequence = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_ticker.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * required string key = 5;
 * @return {string}
 */
proto.assets.return.update_ticker.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.clearKey = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_ticker.prototype.hasKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * required tstamp event_ts = 6;
 * @return {!proto.assets.return.tstamp}
 */
proto.assets.return.update_ticker.prototype.getEventTs = function() {
  return /** @type{!proto.assets.return.tstamp} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.tstamp, 6, 1));
};


/**
 * @param {!proto.assets.return.tstamp} value
 * @return {!proto.assets.return.update_ticker} returns this
*/
proto.assets.return.update_ticker.prototype.setEventTs = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.clearEventTs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_ticker.prototype.hasEventTs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional update_payload sigmas = 7;
 * @return {?proto.assets.return.update_payload}
 */
proto.assets.return.update_ticker.prototype.getSigmas = function() {
  return /** @type{?proto.assets.return.update_payload} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.update_payload, 7));
};


/**
 * @param {?proto.assets.return.update_payload|undefined} value
 * @return {!proto.assets.return.update_ticker} returns this
*/
proto.assets.return.update_ticker.prototype.setSigmas = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.update_ticker} returns this
 */
proto.assets.return.update_ticker.prototype.clearSigmas = function() {
  return this.setSigmas(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.update_ticker.prototype.hasSigmas = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.alive.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.alive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.alive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.alive.toObject = function(includeInstance, msg) {
  var f, obj = {
    aliveId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.alive}
 */
proto.assets.return.alive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.alive;
  return proto.assets.return.alive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.alive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.alive}
 */
proto.assets.return.alive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setAliveId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.alive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.alive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.alive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.alive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
};


/**
 * required sint64 alive_id = 1;
 * @return {number}
 */
proto.assets.return.alive.prototype.getAliveId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.assets.return.alive} returns this
 */
proto.assets.return.alive.prototype.setAliveId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.assets.return.alive} returns this
 */
proto.assets.return.alive.prototype.clearAliveId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.alive.prototype.hasAliveId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.assets.return.feed_event.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.assets.return.feed_event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  SNAPSHOT: 1,
  TICKER: 2,
  END_SNAPSHOT: 3,
  ALIVE: 4
};

/**
 * @return {proto.assets.return.feed_event.EventTypeCase}
 */
proto.assets.return.feed_event.prototype.getEventTypeCase = function() {
  return /** @type {proto.assets.return.feed_event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.assets.return.feed_event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.assets.return.feed_event.prototype.toObject = function(opt_includeInstance) {
  return proto.assets.return.feed_event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.assets.return.feed_event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.feed_event.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.assets.return.update_snapshot.toObject(includeInstance, f),
    ticker: (f = msg.getTicker()) && proto.assets.return.update_ticker.toObject(includeInstance, f),
    endSnapshot: (f = msg.getEndSnapshot()) && proto.assets.return.end_snapshot.toObject(includeInstance, f),
    alive: (f = msg.getAlive()) && proto.assets.return.alive.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.assets.return.feed_event}
 */
proto.assets.return.feed_event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.assets.return.feed_event;
  return proto.assets.return.feed_event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.assets.return.feed_event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.assets.return.feed_event}
 */
proto.assets.return.feed_event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.assets.return.update_snapshot;
      reader.readMessage(value,proto.assets.return.update_snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 2:
      var value = new proto.assets.return.update_ticker;
      reader.readMessage(value,proto.assets.return.update_ticker.deserializeBinaryFromReader);
      msg.setTicker(value);
      break;
    case 3:
      var value = new proto.assets.return.end_snapshot;
      reader.readMessage(value,proto.assets.return.end_snapshot.deserializeBinaryFromReader);
      msg.setEndSnapshot(value);
      break;
    case 4:
      var value = new proto.assets.return.alive;
      reader.readMessage(value,proto.assets.return.alive.deserializeBinaryFromReader);
      msg.setAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.assets.return.feed_event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.assets.return.feed_event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.assets.return.feed_event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.assets.return.feed_event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.assets.return.update_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getTicker();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.assets.return.update_ticker.serializeBinaryToWriter
    );
  }
  f = message.getEndSnapshot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.assets.return.end_snapshot.serializeBinaryToWriter
    );
  }
  f = message.getAlive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.assets.return.alive.serializeBinaryToWriter
    );
  }
};


/**
 * optional update_snapshot snapshot = 1;
 * @return {?proto.assets.return.update_snapshot}
 */
proto.assets.return.feed_event.prototype.getSnapshot = function() {
  return /** @type{?proto.assets.return.update_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.update_snapshot, 1));
};


/**
 * @param {?proto.assets.return.update_snapshot|undefined} value
 * @return {!proto.assets.return.feed_event} returns this
*/
proto.assets.return.feed_event.prototype.setSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.assets.return.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.feed_event} returns this
 */
proto.assets.return.feed_event.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.feed_event.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional update_ticker ticker = 2;
 * @return {?proto.assets.return.update_ticker}
 */
proto.assets.return.feed_event.prototype.getTicker = function() {
  return /** @type{?proto.assets.return.update_ticker} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.update_ticker, 2));
};


/**
 * @param {?proto.assets.return.update_ticker|undefined} value
 * @return {!proto.assets.return.feed_event} returns this
*/
proto.assets.return.feed_event.prototype.setTicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.assets.return.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.feed_event} returns this
 */
proto.assets.return.feed_event.prototype.clearTicker = function() {
  return this.setTicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.feed_event.prototype.hasTicker = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional end_snapshot end_snapshot = 3;
 * @return {?proto.assets.return.end_snapshot}
 */
proto.assets.return.feed_event.prototype.getEndSnapshot = function() {
  return /** @type{?proto.assets.return.end_snapshot} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.end_snapshot, 3));
};


/**
 * @param {?proto.assets.return.end_snapshot|undefined} value
 * @return {!proto.assets.return.feed_event} returns this
*/
proto.assets.return.feed_event.prototype.setEndSnapshot = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.assets.return.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.feed_event} returns this
 */
proto.assets.return.feed_event.prototype.clearEndSnapshot = function() {
  return this.setEndSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.feed_event.prototype.hasEndSnapshot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional alive alive = 4;
 * @return {?proto.assets.return.alive}
 */
proto.assets.return.feed_event.prototype.getAlive = function() {
  return /** @type{?proto.assets.return.alive} */ (
    jspb.Message.getWrapperField(this, proto.assets.return.alive, 4));
};


/**
 * @param {?proto.assets.return.alive|undefined} value
 * @return {!proto.assets.return.feed_event} returns this
*/
proto.assets.return.feed_event.prototype.setAlive = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.assets.return.feed_event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.assets.return.feed_event} returns this
 */
proto.assets.return.feed_event.prototype.clearAlive = function() {
  return this.setAlive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.assets.return.feed_event.prototype.hasAlive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.assets.return.update_type = {
  SNAPSHOT: 1,
  VARIATIONS: 2
};

/**
 * @enum {number}
 */
proto.assets.return.svc_status = {
  ONLINE: 0,
  OFFLINE: 1,
  BROKEN: 2
};

goog.object.extend(exports, proto.assets.return);
