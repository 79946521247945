import React, { useEffect, useMemo, useRef, useState } from 'react'
import PortfolioTable from 'v2/components/frames/PortfolioTable'
import * as Store from 'types/store'
import { useDispatch, useSelector } from 'react-redux'
import { removeFrame } from 'store/dashboard/actions'
import { AssetFeed, PortfolioFeed } from 'types/enums.api'
import { selectOperativeSessionAssets, selectOperativeSessionSession } from 'store/pages/selectors'
import { GridItemActions } from 'types/ui'
import AddFrameModalContainer from 'v2/containers/settings/modals/AddFrameModal'
import SelectColumnsModal from 'v2/components/settings/modals/SelectColumnsModal'
import Unavailable from 'v2/components/frames/Unavailable/Unavaliable'
import { FlowControlSpeed, SessionStatus, SessionType } from 'types/enums'
import sigmaServices from 'config/sigmaServices'
import { selectAssets, selectPortfolios, selectStrategies } from 'store/resources/selectors'
import { isOffline } from 'hooks/common'
import { config } from 'core'

const {
  Portfolios: {
    ReferenceData,
    Position,
    Operations,
    Return
  },
  Assets: {
    SecurityData,
    Models,
    PositionAnalisys,
    Return: AssetReturn,
    Operations: AssetOperations,
    AnalysisStatistics,
  },
} = sigmaServices

interface Props {
  frame: Store.Frame
}

const PortfolioTableContainer: React.FC<Props> = ({ frame }) => {
  const dispatch = useDispatch()
  const resource = frame.selectedResources[0].resource as Store.Portfolio
  const { columnKeys } = frame.options
  const session = useSelector(selectOperativeSessionSession)
  const shouldGetAllAssets = [SessionType.Signals, SessionType.Trading].includes(session.type)
  const { id: sessionId } = useSelector(selectOperativeSessionSession)
  const assets = shouldGetAllAssets ? useSelector(selectAssets) : useSelector(selectOperativeSessionAssets)
  const strategies = useSelector(selectStrategies)
  const portfolios = useSelector(selectPortfolios)
  const portfolioAssets = useMemo(() => assets.filter((asset) => asset.portfolioId === resource.id), [assets])
  const [isModalVisible, setModalVisible] = useState(false)
  const [isSelectColumnsModalVisible, setSelectColumnsModalVisible] = useState(false)

  const webWorkerRef = useRef<Worker | null>(null)

  const [data, setData] = useState<{ sigmasData: Store.PortfolioTable, assetData: Store.PortfolioListAssetsTable[] }>(
    {
      sigmasData: {} as Store.PortfolioTable,
      assetData: {} as Store.PortfolioListAssetsTable[],
    }
  )

  const [lastTick, setLastTick] = useState<Date>(new Date())

  const messageReceived = () => {
    setLastTick(new Date())
  }

  useEffect(() => {
    webWorkerRef.current = new Worker(new URL('../../../../workers/assetsPortfolioSockets.web.worker.ts', import.meta.url))
    
    webWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { type, data: workerData, error } = event.data
      if (type === 'data') {
        setData(workerData)
        messageReceived()
      } else if (type === 'error') {
        console.error('Worker error:', error)
      }
    }
    
    const initMsg = {
      type: 'init',
      payload: {
        portfolioId: resource.id,
        portfolioAssets: portfolioAssets,
        sessionId,
        sigmas: [
          // Portfolio sigmas
          { feed: PortfolioFeed.ReferenceData, sigmas: [ReferenceData.PrtCartesianHeading, ReferenceData.PrtRefData] },
          { feed: PortfolioFeed.Position, sigmas: [Position.PrtCartesianPosition] },
          { feed: PortfolioFeed.Operations, sigmas: [Operations.PrtCartesianOperationsList] },
          { feed: PortfolioFeed.Return, sigmas: [Return.PrtExchRetBcList, Return.PrtAllRetBcList, Return.PrtTradRetBcList] },
          // Assets sigmas
          { feed: AssetFeed.SecurityData, sigmas: [SecurityData.AssetRefData, SecurityData.AssetSecurityTrade, SecurityData.AssetSecurityRefData] },
          { feed: AssetFeed.Models, sigmas: [Models.ModelAlgo] },
          { feed: AssetFeed.PositionAnalysis, sigmas: [PositionAnalisys.PositionAnalysis] },
          { feed: AssetFeed.Return, sigmas: [AssetReturn.AssetTradRetList, AssetReturn.AssetAllRetBcList, AssetReturn.AssetExchRetBcList] },
          { feed: AssetFeed.Operations, sigmas: [AssetOperations.OpenOperation, AssetOperations.AssetCartesianOperations] },
          { feed: AssetFeed.AnalysisStatistics, sigmas: [AnalysisStatistics.AssetPercRet] },
        ],
        flowControlSpeed: FlowControlSpeed.High,
        webSocketUrl: config.socketBaseUrl,
      },
    }
    
    console.log('Portfolio table graph', webWorkerRef.current, initMsg)
    // Send startup message (with all the setup params) to the worker
    webWorkerRef.current.postMessage(initMsg)

    return () => {
      webWorkerRef.current.postMessage({ type: 'terminate' })
      webWorkerRef.current.terminate()
    }
  }, [])

  const getHasMissingResource = () => {
    const portfolio = portfolios.find((portfolio) => portfolio.id === resource.id)
    const strategy = strategies.find((strategy) => strategy.id === portfolio?.strategyId)
    if (!portfolio || !strategy) {
      return true
    }

    return false
  }

  const onClose = () => {
    setModalVisible(false)
  }

  const onSelectColumnsModalClose = () => {
    setSelectColumnsModalVisible(false)
  }

  const onItemClick = (action: GridItemActions) => {
    switch (action) {
      case GridItemActions.Close:
        dispatch(removeFrame(frame))
        break
      case GridItemActions.Edit:
        setModalVisible(true)
        break
      case GridItemActions.ColumnSelect:
        setSelectColumnsModalVisible(true)
    }
  }

  if (getHasMissingResource()) {
    return <Unavailable onItemClick={onItemClick} title={resource.name} />
  }

  const portfolioTableOffline = (): boolean => {
    if (session.status !== SessionStatus.Running) {
      return false
    }
    return isOffline(lastTick)
  }

  return (
    <>
      <PortfolioTable
        portfolioData={data.sigmasData}
        assetData={data.assetData.length ? data.assetData : []}
        columnKeys={columnKeys}
        onItemClick={onItemClick}
        isOffline={portfolioTableOffline()}
      />
      <AddFrameModalContainer
        initialFrame={frame}
        visible={isModalVisible}
        onClose={onClose}
        initialX={0}
        initialY={0}
      />
      <SelectColumnsModal
        frame={frame}
        visible={isSelectColumnsModalVisible}
        onClose={onSelectColumnsModalClose}
        tableTypes={['portfolio', 'asset']}
      />
    </>
  )
}

export default PortfolioTableContainer
