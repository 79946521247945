import React from 'react'
import Debug from 'debug'
import { Collapse, Form, Input, InputNumber, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Store from 'types/store'
import { ColumnsType } from 'antd/lib/table'
import { CrudOperation } from 'types/enums'

const debug = new Debug('Frontend')
interface Props {
  params: Store.ModelParameter[]
  mode: CrudOperation
}

const ModelParameters: React.FC<Props> = ({ params, mode }) => {
  const { t } = useTranslation()

  const columns: ColumnsType<Store.ModelParameter> = [
    {
      title: t('table.column.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('table.column.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('table.column.value'),
      dataIndex: 'value',
      key: 'value',
      // eslint-disable-next-line react/display-name
      render: (value, { type, name }) => {
        if (mode === CrudOperation.Read) {
          return value
        }
        if (type === 'String') {
          debug('value of it', value, type)
          return (
            <Form.Item name={`modelParam.${name}`} initialValue={value}>
              <Input />
            </Form.Item>
          )
        }
        return (
          <Form.Item name={`modelParam.${name}`} rules={[{ type: 'number' }]} initialValue={Number(value)}>
            <InputNumber />
          </Form.Item>
        )
      },
    },
  ]

  return (
    <>
      <Collapse expandIconPosition={'right'}>
        <Collapse.Panel header={t('general.parameters')} key="parameters">
          <Table dataSource={params} columns={columns} pagination={false} />
        </Collapse.Panel>
      </Collapse>
    </>
  )
}

export default ModelParameters
