import { createSelector } from 'reselect'
import * as Store from 'types/store'

const resources = (state: { root: { resources } }) => state.root.resources as Store.ResourcesState

// Data Selectors
export const selectResources = createSelector(resources, (slice) => slice)
export const selectStrategies = createSelector(resources, (slice) => slice.strategies.data)
export const selectStrategiesState = createSelector(resources, (slice) => slice.strategies.state)
export const selectStrategiesErrorCode = createSelector(resources, (slice) => slice.strategies.errorCode)
export const selectPortfolios = createSelector(resources, (slice) => slice.portfolios.data)
export const selectPortfoliosState = createSelector(resources, (slice) => slice.portfolios.state)
export const selectPortfoliosErrorcode = createSelector(resources, (slice) => slice.portfolios.errorCode)
export const selectAssets = createSelector(resources, (slice) => slice.assets.data)
export const selectAssetsState = createSelector(resources, (slice) => slice.assets.state)
export const selectAssetsErrorCode = createSelector(resources, (slice) => slice.assets.errorCode)

//Select a specific asset ID from an array
export const selectAssetById = (id: number) => {
  return createSelector(selectAssets, (items) => items.find((item) => item.id === id))
}
