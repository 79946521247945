export enum GraphQlErrors {
  AccessDenied = 'invalid-jwt',
}

export enum ApiSessionType {
  Trading = 'TRD',
  Signals = 'SIG',
  Virtual = 'VRT',
  BackTest = 'BCT',
}

export enum ApiSessionStatus {
  Off = 'OFF',
  Running = 'RUN',
  StandBy = 'STB',
  Stopped = 'STP',
  Completed = 'CMP',
  Created = 'CRT',
  Broken = 'BRK',
  Updating = 'UPD',
  Terminating = 'TRM',
}

export enum SocketSessionStatus {
  Off = 'OFF',
  Running = 'RUNNING',
  StandBy = 'STAND-BY',
  Stopped = 'STOPPED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Broken = 'BROKEN',
  Updating = 'UPDATING',
  Terminating = 'TERMINATING',
}

export enum ApiAssetStatus {
  Freeze = 'FRZ',
  Auto = 'AUT',
  StandByShort = 'SBS',
  StandByLong = 'SBL',
  StandByFlat = 'SBF',
  Broken = 'BRK',
}

export type Sigma = string

export enum ResponseCode {
  BadRequest = 'BAD_REQUEST',
  Success = 'SUCCESS',
  GenericError = 'GENERIC_ERROR',
  StrategyDoesNotExist = 'STRATEGY_NOT_EXIST',
  StrategyAlreadyExist = 'STRATEGY_ALREADY_EXIST',
  PortfolioDoesNotExist = 'PORTFOLIO_NOT_EXIST',
  PortfolioAlreadyExist = 'PORTFOLIO_ALREADY_EXIST',
  PasswordContainsName = 'PASSWORD_CONTAINS_NAME',
  PasswordContainsSurname = 'PASSWORD_CONTAINS_SURNAME',
  PasswordContainsEmail = 'PASSWORD_CONTAINS_EMAIL',
  PasswordValidationError = 'PASSWORD_VALIDATION_ERROR',
  AssetDoesNotExist = 'ASSET_NOT_EXIST',
  AssetAlreadyExist = 'ASSET_ALREADY_EXIST',
  SessionDoesNotExist = 'SESSION_NOT_EXIST',
  SessionAlreadyExist = 'SESSION_ALREADY_EXIST',
  UploadError = 'UPLOAD_ERROR',
  UploadPartialError = 'UPLOAD_PARTIAL_ERROR',
  UserNotExist = 'USER_NOT_EXIST',
  UserExists = 'USER_EXISTS',
  InvalidGrant = 'INVALID_GRANT',
}

export enum AssetFeed {
  Models = 'asset_model_dictionary',
  Operations = 'asset_operations_dictionary',
  Return = 'asset_return_dictionary',
  SecurityData = 'asset_security_dictionary',
  PositionAnalysis = 'asset_position_analysis_dictionary',
  Principal = 'asset_principal_dictionary',
  AnalysisStatistics = 'asset_analysis_statistics_dictionary',
}

export enum PortfolioFeed {
  Operations = 'portfolio_operations_dictionary',
  Position = 'portfolio_position_dictionary',
  ReferenceData = 'portfolio_reference_data_dictionary',
  Return = 'portfolio_return_dictionary',
  AnalysisIndicators = 'portfolio_analysis_indicators_dictionary',
}

export enum StrategyFeed {
  Operations = 'strategy_operations_dictionary',
  Position = 'strategy_position_dictionary',
  ReferenceData = 'strategy_reference_data_dictionary',
  Return = 'strategy_return_dictionary',
  AnalysisIndicators = 'strategy_analysis_indicators_dictionary',
}

export type Feeds = AssetFeed | PortfolioFeed | StrategyFeed

export enum ChartService {
  Assets = 'assets',
  Portfolios = 'portfolios',
  Strategies = 'strategies',
}

export enum UserRoles {
  SuperAdmin = 1,
  Admin = 2,
  Trader = 3,
  ReadOnly = 4,
  Supervisor = 5,
}
