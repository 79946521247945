import React from 'react'

import AppContent from 'components/AppContent'
import LeftRail from 'components/LeftRail'
import Breadcrumb from 'containers/Breadcrumb'
import StrategyViewer from 'containers/StrategyViewer'
import TreeSelector from 'containers/StrategyViewer/TreeSelector'
import { useDispatch, useSelector } from 'react-redux'
import { selectStrategyPage } from 'store/pages/selectors'
import { loadUsedResources } from 'store/pages/strategy/actions'
import NotificationManager from 'pages/StrategiesPage/NotificationManager'
import styles from './styles.module.scss'
import { useMount } from 'react-use'
import { fetchResources } from 'store/App/actions'
import { LoadingState } from 'types/enums'
import { selectAppLoadingState } from 'store/App/selectors'
import Help from 'components/common/Help'
import { useTranslation } from 'react-i18next'

const StrategiesPage: React.FC = () => {
  const page = useSelector(selectStrategyPage)
  const appLoadingState = useSelector(selectAppLoadingState)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useMount(() => {
    dispatch(fetchResources())
    dispatch(loadUsedResources())
  })

  if (appLoadingState === LoadingState.Loading) {
    return null
  }

  const { currentAction } = page
  return (
    <>
      <LeftRail classname="strategies">
        <TreeSelector userAction={currentAction} />
      </LeftRail>
      <NotificationManager />

      <AppContent fullHeight isDisplayFlex className={styles['strategies-page']}>
        {currentAction && (
          <>
            <Breadcrumb node={currentAction.node} />
            <StrategyViewer userAction={currentAction} />
            <Help text={t('strategies.helpText')} />
          </>
        )}
      </AppContent>
    </>
  )
}

export default StrategiesPage
