import React, { useState } from 'react'
import DependsOn from 'components/DependsOn/DependsOn'
import { Col, Form, Row, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { CrudOperation } from 'types/enums'
const { Paragraph } = Typography

interface Props {
  data: string[]
  getCurrencies: (symbol: string, mic: string) => void
  mode: CrudOperation
}

const { Option } = Select

const CurrencyField: React.FC<Props> = ({ data, getCurrencies, mode }) => {
  const { t } = useTranslation()

  const [mic, setMIC] = useState(null)

  const setSMicIfDifferent = (symbol: string, newMic: string) => {
    if (mic !== newMic) {
      setMIC(newMic)
      getCurrencies(symbol, newMic)
    }
  }

  const readonly = mode === CrudOperation.Update

  return (
    <DependsOn fields={['mic', 'symbol']}>
      {(fields) => {
        const { mic, symbol } = fields

        if (mic && symbol) {
          setSMicIfDifferent(symbol, mic)
        }

        const uniqueCurrencies = data.filter((currency, index) => data.indexOf(currency) === index)

        return (
          <Row align="middle" gutter={[5, 0]}>
            <Col span={12}>
              <Paragraph>{t('assets.tradingCurrency')}</Paragraph>
            </Col>
            <Col span={12}>
              <Form.Item
                name="currency"
                rules={[{ required: true, message: t('error.requiredField') }]}
                className={readonly && 'readonly'}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showArrow={false}
                  disabled={!mic || readonly}
                >
                  {uniqueCurrencies.map((item) => {
                    return (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )
      }}
    </DependsOn>
  )
}

export default CurrencyField
