import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as K from './constants'
import Debug from 'debug'
import { ErrorCode } from 'types/enums'
import * as api from 'api/strategies'
import * as Api from 'types/api'
import { ApplicationError, isApiError, isGraphQlError } from 'core'
const debug = new Debug('Frontend')

export function* fetchRunningAssets() {
  debug('Saga fetchRunningAssets')
  try {
    const response = yield call(api.getUsedResource)
    if (isApiError(response) || isGraphQlError(response)) {
      throw new ApplicationError(ErrorCode.Api, 'fetchRunningAssets failed')
    }

    const data = response as Api.UsedResourcesResponse

    const items = data.data.application_asset.reduce(
      (obj, item) => {
        const { assets, portfolios, strategies } = obj
        assets.push(item.id)
        if (!portfolios.includes(item.portfolio_id)) {
          portfolios.push(item.portfolio_id)
        }
        if (!strategies.includes(item.portfolio.strategy_id)) {
          strategies.push(item.portfolio.strategy_id)
        }

        return {
          assets,
          portfolios,
          strategies,
        }
      },
      {
        assets: [],
        portfolios: [],
        strategies: [],
      },
    )
    yield put({ type: K.FETCH_RUNNING_ASSETS_SUCCESS, payload: items })
  } catch (error) {
    debug('fetchUsedResources Error', error)
  }
}

function* watch() {
  yield all([takeLatest(K.FETCH_RUNNING_ASSETS, fetchRunningAssets)])
}

export default watch
