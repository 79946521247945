import React, { useEffect, useState } from 'react'
import * as Api from 'types/api'
import * as api from 'api/assets'
import { jsonToBrokerSecurity } from 'services/store/mapService'
import BrokerName from 'components/StrategyViewer/forms/AssetForm/BrokerName'
import { isApiError } from 'core'
import * as Store from 'types/store'

const getBrokerSecurities = async (
  symbol: string,
  mic: string,
  currency: string,
): Promise<Store.BrokerSecurity[]> => {
  const response = await api.getBrokerSecurities(symbol, mic, currency)
  if (isApiError(response)) {
    return null
  }

  const masterSecurityResponse = response as Api.BrokerSecurityResponse

  return jsonToBrokerSecurity(masterSecurityResponse.data.application_broker_security)
}

const BrokerNameContainer: React.FC = () => {
  const [data, setData] = useState<Store.BrokerSecurity[]>([])
  const [filter, setFilter] = useState({ symbol: '', mic: '', currency: '' })

  useEffect(() => {
    const getData = async () => {
      const data = await getBrokerSecurities(filter.symbol, filter.mic, filter.currency)
      if (data) {
        setData(data)
      }
    }
    getData()
  }, [filter.currency, filter.mic, filter.symbol])

  return <BrokerName data={data} setFilter={setFilter} filter={filter} />
}

export default BrokerNameContainer
