import React from 'react'
import { Table } from 'antd'
import * as Store from 'types/store'
import { ColumnsType } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { formatGliphNumber } from 'core/formats'

interface Props {
  data: Store.ReturnAnalysis[]
}

const DLYYTDTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()
  const columns: ColumnsType = [
    {
      title: '',
      dataIndex: 'title',
    },
    {
      title: 'currency',
      dataIndex: 'value',
    },
  ]

  const values = data.reduce(
    (sum, returnAnalysis) => {
      columns[1].title = returnAnalysis.currency ?? ''
      sum.close += returnAnalysis.closeTradingRetBc || 0
      sum.open += returnAnalysis.openTradingRetBc || 0
      sum.long += returnAnalysis.valueLong || 0
      sum.short += returnAnalysis.valueShort || 0
      return sum
    },
    {
      open: 0,
      close: 0,
      long: 0,
      short: 0,
    },
  )

  const dataSource = [
    {
      title: t('table.column.open'),
      value: `${formatGliphNumber(values.open)}`,
    },
    {
      title: t('table.column.closed'),
      value: `${formatGliphNumber(values.close)}`,
    },
    {
      title: t('table.column.long'),
      value: `${formatGliphNumber(values.long)}`,
    },
    {
      title: t('table.column.short'),
      value: `${formatGliphNumber(values.short)}`,
    },
  ]

  return (
    <Table columns={columns} dataSource={dataSource} pagination={false} className='JI-RENDER-YTDTDY-table' />
    // <>
    //   <Row>
    //     <Col span={12}></Col>
    //     <Col span={12}></Col>
    //   </Row>
    // </>
  )
}

export default DLYYTDTable
