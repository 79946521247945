import { update_snapshot, update_ticker } from 'sockets/assets/asset_models_pb'
import * as Store from 'types/store'
import { convertToFloat, formatSigmaDate } from '../common'

export const mapAssetModelsSigmas = (
  feed: update_snapshot.AsObject | update_ticker.AsObject,
): Store.AssetSigmas => {
  const { sigmas, key } = feed
  if (!sigmas) {
    return null
  }

  const { modelAlgo, modelSignal, theoreticalOrder } = sigmas

  return {
    modelAlgo: modelAlgo && {
      modelDescription: modelAlgo.modelDescription,
      modelId: modelAlgo.modelId,
      modelName: modelAlgo.modelName,
      modelStatus: modelAlgo.modelStatus,
      modelVersion: modelAlgo.modelVersion,
    },
    modelSignal: modelSignal && {
      key: parseInt(key),
      price: convertToFloat(modelSignal?.price),
      quantity: convertToFloat(modelSignal?.quantity) || 0,
      sign: modelSignal?.sign,
      time: formatSigmaDate(modelSignal?.time),
    },
    theoreticalOrder: theoreticalOrder && {
      key: parseInt(key),
      price: convertToFloat(theoreticalOrder.price),
      quantity: convertToFloat(theoreticalOrder.quantity) || 0,
      sign: theoreticalOrder.sign,
      time: formatSigmaDate(theoreticalOrder.time),
    },
  } as Store.AssetSigmas
}
