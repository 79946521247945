import { AssetStatus, FrameType, SessionType, Versions } from 'types/enums'
import i18n from 'i18next'
import * as Store from 'types/store'
import { ExtendedDataNode, FrameSize } from 'types/ui'
import { createTreeFromResources } from './treeService'
import Debug from 'debug'
import classNames from 'classnames'
import { frameSizeMap } from './frameSizeMap'

const debug = Debug('Frontend')

export const CELL_WIDTH = 46
export const CELL_HEIGHT = 40

export const getFrameTitle = (frameType: FrameType) => {
  if (!frameType) {
    return null
  }

  switch (frameType) {
    case FrameType.PositionMonitoring:
      return i18n.t('frameType.positionMonitoring')
    case FrameType.MapView:
      return i18n.t('frameType.mapView')
    case FrameType.SignalsTable:
      return i18n.t('frameType.signalsTable')
    case FrameType.ReturnAnalysis:
      return i18n.t('frameType.returnAnalysis')
    case FrameType.ForwardAnalysis:
      return i18n.t('frameType.forwardAnalysis')
    case FrameType.Statistics:
      return i18n.t('frameType.statistics')
    case FrameType.StatisticsChartless:
      return i18n.t('frameType.statistics')
    case FrameType.TechnicalChart:
      return i18n.t('frameType.technical')
    case FrameType.Custom:
      return i18n.t('frameType.customFrame')

    default:
      return i18n.t('frameType.unknown')
  }
}

export const isFilteredLayout = (filtered: boolean, sessionType: SessionType) => {
  if (sessionType === SessionType.Virtual || sessionType === SessionType.BackTest) {
    return true
  }

  return filtered
}

export const filterByVersion = (layouts: Store.Layout[], version: Versions): Store.Layout[] => {
  if (!layouts) {
    return null
  }
  return layouts.filter((layout) => layout.configuration.version && layout.configuration.version === version)
}

export const filterbySessionType = (layouts: Store.Layout[], sessionType: SessionType): Store.Layout[] => {
  if (!layouts) {
    return null
  }

  return layouts.filter((layout) => {
    switch (sessionType) {
      case SessionType.BackTest:
        return layout.configuration.sessionType === SessionType.BackTest
      case SessionType.Virtual:
        return layout.configuration.sessionType === SessionType.Virtual

      default:
        return (
          layout.configuration.sessionType === SessionType.Trading ||
          layout.configuration.sessionType === SessionType.Signals
        )
    }
  })
}

export const updateLayout = (
  layout: Store.Layout,
  dashboard: Store.DashboardState,
  sessionType: SessionType,
): Store.Layout => {
  const configuration: Store.LayoutConfiguration = {
    version: Versions.V2,
    frames: dashboard.frames,
    entityFilter: dashboard.entityFilter,
    sessionType,
    position: 1,
    deviceTypes: [],
  }

  return { ...layout, ...{ configuration } }
}

export const createLayout = (
  name: string,
  dashboard: Store.DashboardState,
  sessionType: SessionType,
): Store.Layout => {
  const configuration: Store.LayoutConfiguration = {
    version: Versions.V2,
    frames: dashboard.frames,
    entityFilter: dashboard.entityFilter,
    sessionType,
    position: 1,
    deviceTypes: [],
  }

  const layout: Store.Layout = {
    id: 0,
    name,
    configuration,
    creationDate: new Date(),
    creationUser: null,
    lastUpdateUser: null,
    lastUpdateDate: new Date(),
  }

  return layout
}

export const createEmptyLayout = (name: string, sessionType: SessionType): Store.Layout => {
  const configuration: Store.LayoutConfiguration = {
    version: Versions.V2,
    frames: [],
    entityFilter: false,
    sessionType,
    position: 1,
    deviceTypes: [],
  }

  const layout: Store.Layout = {
    id: 0,
    name,
    configuration,
    creationDate: new Date(),
    creationUser: null,
    lastUpdateUser: null,
    lastUpdateDate: new Date(),
  }

  return layout
}

export const getFilteredTree = (
  entityFilter: boolean,
  assets: Store.Asset[],
  portfolios: Store.Portfolio[],
  strategies: Store.Strategy[],
  sessionAssets: Store.Asset[],
): ExtendedDataNode[] => {
  const combinedAssets = assets.map((asset) => {
    const status =
      sessionAssets.find((sessionAsset) => asset.id === sessionAsset.id)?.status || AssetStatus.NotDefined

    return {
      ...asset,
      status,
    }
  })

  const filteredAssets = entityFilter ? sessionAssets : combinedAssets

  const filteredPortfolios = portfolios.filter((portfolio) => {
    if (!entityFilter) {
      return true
    }
    return filteredAssets.some((asset) => portfolio.id === asset.portfolioId)
  })

  const filteredStrategies = strategies.filter((strategy) => {
    if (!entityFilter) {
      return true
    }
    return filteredPortfolios.some((portfolio) => portfolio.strategyId === strategy.id)
  })

  const tree = createTreeFromResources(filteredStrategies, filteredPortfolios, filteredAssets)
  debug('Tree', tree)
  return tree
}

export const confirmFrameSize = (width: number, height: number, type: FrameSize): boolean => {
  const el = frameSizeMap[type]

  if (!el) {
    return false
  }

  const w = Math.floor(width / CELL_WIDTH)
  const h = Math.floor(height / CELL_HEIGHT)

  if (h >= el.hMin && h <= el.hMax && w >= el.wMin && w <= el.wMax) {
    return true
  }

  return false
}

export const getGridItemClass = (width: number, height: number): string => {
  return classNames('frame-container', {
    'frame-container--sm': confirmFrameSize(width, height, FrameSize.Small),
    'frame-container--md': confirmFrameSize(width, height, FrameSize.Medium),
    'frame-container--lg': confirmFrameSize(width, height, FrameSize.Large),
    'frame-container--vertical': confirmFrameSize(width, height, FrameSize.Vertical),
    'frame-container--with-candlestick-x': confirmFrameSize(width, height, FrameSize.WithCandleStickX),
    'frame-container--with-candlestick-y': confirmFrameSize(width, height, FrameSize.WithCandleStickY),
    'frame-container--lg-2': confirmFrameSize(width, height, FrameSize.Large2),
    'frame-container--vertical-2': confirmFrameSize(width, height, FrameSize.Vertical2),
    'frame-container--sm-2': confirmFrameSize(width, height, FrameSize.Small2),
  })
}
