import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { LoadingState, SessionStatus } from 'types/enums'
import DashboardPlayer from 'v2/components/dashboard-players/BacktestPlayer/BacktestPlayer'
import * as Forms from 'types/form'
import { useTranslation } from 'react-i18next'
import {
  freezeBackTestOperation,
  launchBackTestOperation,
  loadFinishedById,
  stopBackTestOperation,
} from 'store/pages/operative-session/actions'

import { useSelector } from 'react-redux'
import { selectOperativeSessionSession, selectOperativeSessionState } from 'store/pages/selectors'
import { useInterval } from 'react-use'
import * as api from 'api/sessions'
import * as Api from 'types/api'
import { isApiError, isGraphQlError } from 'core'
import * as Store from 'types/store'
import moment from 'moment'

const DELAY = 30000

const checkSessionHasEnded = async (id: number): Promise<boolean> => {
  const response = await api.getSessionEndTime(id)

  if (isApiError(response) || isGraphQlError(response)) {
    return false
  }

  const session = (response as Api.SessionsEndTimeResponse).data.application_operative_session[0]
  
  return session?.end_time ? true : false
}

const isValidDate = (date: Date) => {
  return date && Object.prototype.toString.call(date) === "[object Date]"
}

const BacktestPlayerContainer: React.FC = () => {
  const { t } = useTranslation()
  const [showParams, setShowParams] = useState(true)
  const session = useSelector(selectOperativeSessionSession)
  const loadingState = useSelector(selectOperativeSessionState)
  const [[progress, backtestTime], setProgressValues] = useState<[number, number]>([0, 0])
  
  const { periodParams, startTime: sessionStartTime } = session
  const { endDate, startDate, speedup, startTime, endTime, tickByTick, dataFrequency, backTestStartDateTime, delay } = periodParams as Store.BacktestParams
  const dispatch = useDispatch()

  const dataFrequencyUnit = dataFrequency ? dataFrequency.match(/[a-z]/g) : '' // Extract unit (chars)
  const dataFrequencyValue = dataFrequency ? Number(dataFrequency.match(/\d+/g)) : null // Extract value (numbers)

  const { control, getValues, clearErrors, setError } = useForm<Forms.BacktestPlayer>({
    mode: 'onSubmit',
    defaultValues: {
      date: [startDate?.toDate(), endDate?.toDate()],
      speedup,
      tickByTick,
      dataFrequencyUnit: 's', // TODO: this is due to WIP, decisions still needs to be made for BKT params
      dataFrequencyValue
    },
  })

  const getProgressAndTime = (): [number, number] => {
    if (session.status === SessionStatus.Completed && endTime) {
      return [100, endTime.toDate().getTime()]
    }

    if (session.status === SessionStatus.Terminating) {
      return [0, 0]
    }

    if (session.status !== SessionStatus.Running && !session.isHistorical) {
      return [0, 0]
    }

    if (!sessionStartTime) {
      return [0, 0]
    }

    if (session.status === SessionStatus.Stopped && session?.lastEvt) {
      const progressPerc = ((session.lastEvt.getTime() - startDate.toDate().getTime()) / (endDate.toDate().getTime() - startDate.toDate().getTime())) * 100
      return [Math.min(Math.ceil(progressPerc), 100), Math.min(session?.lastEvt.getTime(), endTime.toDate().getTime())]
    }

    const backtestSessionStartTime = backTestStartDateTime.toDate().getTime()
    const backtestStartTime = startTime.toDate().getTime()
    const backtestEndTime = endTime.toDate().getTime()
    const now = new Date().getTime()

    const backtestTime = Math.min(Math.max((backtestStartTime + (now - (backtestSessionStartTime)) * speedup), backtestStartTime), backtestEndTime)
    const progress = ((backtestTime - backtestStartTime) / (backtestEndTime - backtestStartTime)) * 100

    if (progress < 0) {
      return [0, backtestStartTime]
    }

    return [Math.ceil(progress), backtestTime]
  }

  const checkIfSessionHasEnded = async () => {
    const [progress, backtestTime] = getProgressAndTime()
    setProgressValues([progress, backtestTime])
    const isEnded = await checkSessionHasEnded(session.id)

    if (isEnded) {
      dispatch(loadFinishedById(session.id))
    }
  }

  useEffect(() => {
    const [progress, backtestTime] = getProgressAndTime()
    setProgressValues([progress, backtestTime])
  }, [session])

  useInterval(
    () => {
      checkIfSessionHasEnded()
    },
    session.status === SessionStatus.Running ? DELAY : null,
  )

  const toggleParamsVisibility = () => setShowParams((state) => !state)

  const showErrors = (startDate, endDate, speedup, dataFrequencyUnit, dataFrequencyValue, tickByTick) => {
    if (!startDate || !endDate) {
      setError('date', { message: t('error.requiredField') })
    }
    if (!speedup) {
      setError('speedup', { message: t('error.requiredField') })
    }
    if (!tickByTick && !dataFrequencyUnit) {
      setError('dataFrequencyUnit', { message: t('error.requiredField') })
    }
    if (!tickByTick && !dataFrequencyValue) {
      setError('dataFrequencyValue', { message: t('error.requiredField') })
    }
  }

  const onClick = (status: SessionStatus) => {
    clearErrors()

    switch (status) {
      case SessionStatus.Running: {
        const { date, speedup, dataFrequencyUnit, dataFrequencyValue, tickByTick } = getValues()
        const [startDate, endDate] = date || []
        const dataFrequency = `${dataFrequencyValue}${dataFrequencyUnit}`

        const payload = {
          sessionStatus: status,
          start: startDate,
          stop: endDate,
          speedup,
          tickByTick,
          dataFrequency,
        }

        // console.log('payload', payload, getValues())

        if (!startDate || !endDate || !speedup || !tickByTick && dataFrequencyUnit === '') {
          showErrors(startDate, endDate, speedup, dataFrequencyUnit, dataFrequencyValue, tickByTick)
          setShowParams(true)
          return
        }

        dispatch(launchBackTestOperation(payload))
        break
      }
      case SessionStatus.StandBy:
        dispatch(freezeBackTestOperation())
        break
      case SessionStatus.Stopped:
        dispatch(stopBackTestOperation())
        break
    }
  }

  const backtestTimeFormated = moment(backtestTime).format('YYYY/MM/DD - HH:mm:ss')

  return (
    <DashboardPlayer
      control={control}
      onClick={onClick}
      showParams={showParams}
      toggleParamsVisibility={toggleParamsVisibility}
      loading={loadingState === LoadingState.Updating}
      progress={progress}
      backtestTime={backtestTimeFormated}
    />
  )
}

export default BacktestPlayerContainer
