import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import moment from 'moment'

const axios = getAxios()

const patchDate = (date: Date) => moment(date).format('YYYY-MM-DD')
export async function backtestRun(
  sessionId: number,
  start: Date,
  stop: Date,
  speedup: number,
  tickByTick: boolean, 
  dataFrequency?: string,
): Promise<Api.AssetCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()
  const data = {
    start: patchDate(start),
    stop: patchDate(stop),
    speedup,
    tickByTick,
    dataFrequency
  }

  return axios
    .put(config.api.backtestRun(sessionId), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function backtestStandby(sessionId: number): Promise<Api.AssetCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.backtestStandby(sessionId), null, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function backtestStop(sessionId: number): Promise<Api.AssetCreateFeed | ApiError> {
  const header = await getAuthenticationHeader()
  return axios
    .put(config.api.backtestStop(sessionId), null, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
export async function historicalDistributionStatistics(
  sessionId: number,
  assetIds: number[],
): Promise<Api.DistributionStatistics | ApiError> {
  const header = await getAuthenticationHeader()

  const payload = {
    sessionId,
    assetIds,
  }

  return axios
    .post(config.api.historicalDistributionStatistics(), payload, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function historicalIndicatorsStatistics(
  sessionId: number,
  assetIds: number[],
): Promise<Api.IndicatorStatistics | ApiError> {
  const header = await getAuthenticationHeader()

  const payload = {
    sessionId,
    assetIds,
  }

  return axios
    .post(config.api.historicalIndicatorsStatistics(), payload, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function generateBackTestReport(sessionId: number, assetIds: number[]): Promise<Api.BackTestReportResponse | ApiError> {
  const header = await getAuthenticationHeader()
  header.responseType = 'blob'

  const payload = {
    sessionId,
    assetIds,
    timeZone: 'CET',
  }

  return axios
    .post(config.api.generateBackTestReport(), payload, header)
    .then((response) => {
      const filenameHeader = response.headers['content-disposition']
      // Filename is the string within the double quotes
      const filename = filenameHeader.match(/(?<=")(.*?)(?=")/g)
      return {
        data: response.data,
        filename: filename[0],
      }
    })
    .catch((error) => getApiError(error))
}
