import React, { useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { ExtendedDataNode, TransferType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { getAllPortfolios, getAllAssets, getAllStrategies } from 'helpers/transfer/itemAddHelper'
import { TransferContext } from 'types/enums'

interface Props {
  node: ExtendedDataNode
  onClick: (node: ExtendedDataNode, type: TransferType) => void
  context: TransferContext
}

const AddStrategies: React.FC<Props> = ({ node, onClick, context }) => {
  const { t } = useTranslation()

  const getItems = () => {
    if (node.children.length === 0) {
      return []
    }
    switch (context) {
      case TransferContext.All:
        return [getAllStrategies(node), getAllPortfolios(node), getAllAssets(node)]
      case TransferContext.AtBacktest:
        return []
      default:
        return [getAllAssets(node)]
    }
  }

  const trigger: any =
    context === TransferContext.AtSessionLaunch ? ['contextMenu'] : ['click', 'contextMenu']

  const [menu, setMenu] = useState([])

  const onVisibleChange = (visible: boolean) => {
    if (visible) {
      setMenu(getItems())
    } else {
      setMenu([])
    }
  }

  const DropdownMenu = (
    <Menu>
      {menu.map((item) => (
        <Menu.Item
          key={item.key}
          onClick={() => {
            onClick(node, item.transferType)
          }}
        >
          {t(item.label)}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown
      overlay={DropdownMenu}
      trigger={trigger}
      getPopupContainer={(trigger) => trigger.parentElement}
      onVisibleChange={onVisibleChange}
    >
      <span>{node.title}</span>
    </Dropdown>
  )
}

export default AddStrategies
