import * as Api from 'types/api'
import { getAxios } from './apiService'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { TSInterval } from 'types/enums'
const axios = getAxios()

const assetFields = `{
  asset_id
  bank_account
  broker_account
  broker_name
  contract_size
  custom_code
  description
  instrument_class
  instrument_code
  last_mod_date
  last_mod_userid
  long_short
  lot_multiplier
  mic
  min_order_countervalue
  min_order_qty
  model_class_id
  model_description
  model_language
  model_name
  model_type
  name
  operative_session_id
  portfolio_id
  qty_f_countervalue
  ref_countervalue
  ref_countervalue_max
  ref_countervalue_min
  ref_qty
  ref_qty_max
  ref_qty_min
  security_id
  size_variable
  status
  symbol
  trading_currency
  version_number
}`

export async function getAssets(sessionId: number): Promise<Api.HistoricalAssetsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id: Int){
        historical_data_asset_v(where: {operative_session_id: {_eq: $id}}) ${assetFields}
    }`,
    variables: {
      id: sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getPortfolios(sessionId: number): Promise<Api.HistoricalPortfoliosResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id:Int){
      historical_data_portfolio_v(where: {operative_session_id: {_eq: $id}}) {
        max_long
        max_net_hedging
        max_short
        min_net_hedging
        name
        portfolio_id
        strategy_id
      }
    }`,
    variables: {
      id: sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getStrategies(sessionId: number): Promise<Api.HistoricalStrategiesResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($id: Int){
      historical_data_strategy_v(where: {operative_session_id: {_eq: $id}}) {
        strategy_id
        target
        name
        base_currency
      }
    }`,
    variables: {
      id: sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getDetailsViewData(
  assetId: number,
  sessionId: number,
): Promise<Api.HistoricalDetailsViewDataResponse | ApiError> {
  const header = await getAuthenticationHeader()

  const data: GraphQlQuery = {
    query: `query ($assetId: Int, $sessionId: Int){
      historical_data_asset_cartesian_graph_indicators_v(where: {asset_id: {_eq: $assetId}, operative_session_id: {_eq: $sessionId}}) {
        price
        size
        trade_ts
        bid_price
        bid_total_size
        ask_price
        ask_total_size
        jt_price
        jt_quantity
        jt_sign
        jt_time
      }
      historical_data_open_operation_v(where: {asset_id: {_eq: $assetId}, operative_session_id: {_eq: $sessionId}}) {
        tot_trading_ret
        tot_trading_ret_bc
        sign
        avg_open_price
        avg_close_price
        size
        start_ts
        end_ts
        max_price
        min_price
        tot_perc_ret
        tdy_perc_ret
        tdy_trading_ret_bc
        tdy_trading_ret
        ytd_perc_ret
        ytd_trading_ret
        ytd_trading_ret_bc
      }
      historical_data_asset_trad_ret_v(where: {asset_id: {_eq: $assetId}, operative_session_id: {_eq: $sessionId}}) {
        trading_ret
        trading_ret_bc
        type
      }
      historical_data_asset_cartesian_operations_v(where: {asset_id: {_eq: $assetId}, operative_session_id: {_eq: $sessionId}}) {
        positive_op
        negative_op
        type
      }
    }`,
    variables: {
      assetId,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getAssetSecurityRefData(
  assetId: number,
  sessionId: number,
): Promise<Api.HistoricalAssetSecurityRefDataResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($assetId: Int, $sessionId: Int){
      historical_data_asset_cartesian_graph_indicators_v(where: {asset_id: {_eq: $assetId}, operative_session_id: {_eq: $sessionId}}) {
        description
        instrument_isin
    		instrument_code
        instrument_class
        market_code
        symbol
        trading_currency
        asset_id
      }
    }`,
    variables: {
      assetId,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getAssetOpenChartData(
  assetId: number,
  sessionId: number,
  interval: TSInterval,
): Promise<Api.HistoricalAssetOpenChartDataResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($assetId: Int, $sessionId: Int, $interval: Int){
      historical_data_asset_open_unit_ret_v(where: {asset_id: {_eq: $assetId}, operative_session_id: {_eq: $sessionId}, sampling_rate: {_eq: $interval}}) {
         start_ts
         end_ts
         unit_ret_open
         unit_ret_close
       }
       historical_data_asset_open_candle_v(where: {asset_id: {_eq: $assetId}, operative_session_id: {_eq: $sessionId}, sampling_rate: {_eq: $interval}}) {
         start_ts
         end_ts
         open
         close
         high
         low
       }
       historical_data_open_operation_v(where: {asset_id: {_eq: $assetId}, operative_session_id: {_eq: $sessionId}}) {
         tot_trading_ret_bc
         ytd_trading_ret_bc
         tdy_trading_ret_bc
         tot_perc_ret
         tot_trading_ret
         min_price
         size
         max_price
         ytd_perc_ret
         tdy_perc_ret
         avg_open_price
         tdy_trading_ret
         start_ts
         end_ts
         avg_close_price
         sign
         ytd_trading_ret
       }
     }`,
    variables: {
      assetId,
      sessionId,
      interval,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getStrategyOpenChartData(
  strategyId: number,
  sessionId: number,
  interval: TSInterval,
): Promise<Api.HistoricalStrategyOpenChartDataResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($strategyId: Int, $sessionId: Int, $interval: Int){
      historical_data_stg_open_candle_v(where: {strategy_id: {_eq: $strategyId}, operative_session_id: {_eq: $sessionId}, sampling_rate: {_eq: $interval}}) {
        start_ts
        max_tdy_trading_ret_bc
        min_tdy_trading_ret_bc
        end_ts
       }

     }`,
    variables: {
      strategyId,
      sessionId,
      interval,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getStrategyCartesianGraphIndicators(
  strategyId: number,
  sessionId: number,
): Promise<Api.HistoricalStrategyGraphIndicatorsResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($strategyId: Int, $sessionId: Int){
      historical_data_strategy_cartesian_graph_indicators_v(where: {strategy_id: {_eq: $strategyId}, operative_session_id: {_eq: $sessionId}}) {
        strategy_name
        strategy_target
      }
    }`,
    variables: {
      strategyId,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getStrategyPanelContentData(
  strategyId: number,
  sessionId: number,
): Promise<Api.HistoricalPanelContentDataResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($strategyId: Int, $sessionId: Int){
      historical_data_strategy_cartesian_graph_indicators_v(where: {strategy_id: {_eq: $strategyId}, operative_session_id: {_eq: $sessionId}}) {
        countervalue_bc
        countervalue_bc_long
        countervalue_bc_short
        net_hedging
        portfolios_num
        assets_num
        active_models_num
        base_currency
      }
      historical_data_stg_trad_ret_bc_v(where: {strategy_id: {_eq: $strategyId}, operative_session_id: {_eq: $sessionId}}) {
        trading_ret_bc
        last_evt_ts
        type
      }
      historical_data_stg_cartesian_operations_v(where: {strategy_id: {_eq: $strategyId}, operative_session_id: {_eq: $sessionId}}) {
        positive_op
        negative_op
        open_op
        close_op
        open_negative_op
        close_negative_op
        open_positive_op
        close_positive_op
        operative_session_id
        strategy_id
        type
      }
    }`,
    variables: {
      strategyId,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getPortfolioHeaderData(
  portfolioId: number,
  sessionId: number,
): Promise<Api.HistoricalPortfolioHeaderDataResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($portfolioId: Int, $sessionId: Int){
      historical_data_prt_cartesian_graph_indicators_v(where: {portfolio_id: {_eq: $portfolioId}, operative_session_id: {_eq: $sessionId}}) {
        portfolio_name
        strategy_name
      }
    }`,
    variables: {
      portfolioId,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}

export async function getPortfolioPanelContentData(
  portfolioId: number,
  sessionId: number,
): Promise<Api.PortfolioHistoricalPanelContentDataResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($portfolioId: Int, $sessionId: Int){
      historical_data_prt_cartesian_graph_indicators_v(where: {portfolio_id: {_eq: $portfolioId}, operative_session_id: {_eq: $sessionId}}) {
        countervalue_bc_long
        countervalue_bc_short
        countervalue_bc
        net_hedging
        assets_num
        active_models_num
        base_currency
      }
      historical_data_prt_trad_ret_bc_v(where: {portfolio_id: {_eq: $portfolioId}, operative_session_id: {_eq: $sessionId}}) {
        trading_ret_bc
        last_evt_ts
        type
      }
      historical_data_prt_cartesian_operations_v(where: {portfolio_id: {_eq: $portfolioId}, operative_session_id: {_eq: $sessionId}}) {
        positive_op
        negative_op
        open_op
        close_op
        open_negative_op
        close_negative_op
        open_positive_op
        close_positive_op
        type
      }
    }`,
    variables: {
      portfolioId,
      sessionId,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
export async function getPortfolioOpenChartData(
  portfolioId: number,
  sessionId: number,
  interval: TSInterval,
): Promise<Api.HistoricalPortfolioOpenChartDataResponse | ApiError> {
  const header = await getAuthenticationHeader()
  const data: GraphQlQuery = {
    query: `query ($portfolioId: Int, $sessionId: Int, $interval: Int){
      historical_data_prt_open_candle_v(where: {portfolio_id: {_eq: $portfolioId}, operative_session_id: {_eq: $sessionId}, sampling_rate: {_eq: $interval}}) {
        start_ts
        max_tdy_trading_ret_bc
        min_tdy_trading_ret_bc
        end_ts
       }
     }`,
    variables: {
      portfolioId,
      sessionId,
      interval,
    },
  }

  return axios
    .post(config.api.getGraphQl(), data, header)
    .then((response) => response.data)
    .catch((error) => getApiError(error))
}
