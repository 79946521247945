import React from 'react'
import * as Store from 'types/store'
import { Col, Form, Row, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import DependsOn from 'components/DependsOn'
const { Paragraph } = Typography

interface Props {
  data: Store.BrokerSecurity[]
  setFilter: (filter: Json) => void
  filter: Json
}

const { Option } = Select

const BrokerName: React.FC<Props> = ({ data, setFilter, filter }) => {
  const { t } = useTranslation()

  return (
    <DependsOn fields={['symbol', 'mic', 'currency', 'broker']}>
      {({ symbol, mic, currency, broker }) => {
        if (currency && (filter.symbol !== symbol || filter.mic !== mic || filter.currency !== currency)) {
          setFilter(() => ({ currency, symbol, mic }))
        }

        return (
          <Row align="middle" gutter={[5, 0]}>
            <Col span={12}>
              <Paragraph>{t('assets.brokerName')}</Paragraph>
            </Col>
            <Col span={12}>
              <Form.Item shouldUpdate>
                {({ resetFields }) => (
                  <Form.Item name="broker">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      disabled={!currency}
                      allowClear
                      className={broker && 'selected'}
                      onChange={() => resetFields(['brokerAccount'])}
                    >
                      {data.map((item) => {
                        return (
                          <Option value={item.securityId} key={item.securityId}>
                            {item.brokerName}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
        )
      }}
    </DependsOn>
  )
}

export default BrokerName
