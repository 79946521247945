import React, { useContext } from 'react'
import * as Store from 'types/store'
import { GridItemActions } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { Typography, Spin } from 'antd'
import StatisticsDetail from './StatisticsDetail'

import { useWindowSize } from 'react-use'
import { CELL_HEIGHT, CELL_WIDTH, getGridItemClass } from 'services/dashboardService'

import generalStyles from './styles.module.scss'
import { CheckOutlined } from '@ant-design/icons'
import ContextMenu from 'v2/components/frames/ContextMenu'
import { CandleStickFrameContext } from 'contexts/CandleStickFrameContext'
import { getSpinnerMessage } from 'core/helpers'
interface Props {
  isSessionRunning: boolean
  indicatorData: Store.IndicatorStatistics
  onClick: (action: GridItemActions) => void
  selectedAssets: Store.Asset[]
  session: Store.Session
  isFullScreen: boolean
}

const Statistics: React.FC<Props> = ({
  onClick,
  selectedAssets,
  isSessionRunning,
  indicatorData,
  isFullScreen,
  session,
}) => {
  const { t } = useTranslation()
  const context = useContext(CandleStickFrameContext)
  const { squares } = context
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const [width, height] = isFullScreen
    ? [windowWidth, windowHeight]
    : [squares.x * CELL_WIDTH, squares.y * CELL_HEIGHT]

  const panelContent = isSessionRunning ? (
    <section className="running-session-spin-container">
      <ContextMenu
        isFullscreen={isFullScreen}
        onMenuItemClick={onClick}
        items={[{ key: GridItemActions.Close, label: t('contextMenu.close') }]}
      />
      <Spin
        tip={getSpinnerMessage(session.status)}
        spinning={isSessionRunning}
        size="large"
        wrapperClassName="session-running"
      ></Spin>
    </section>
  ) : (
    <section className={generalStyles['statistic']}>
      <div className={getGridItemClass(width, height)}>
        <div className="frame-header">
          <div className="frame-header__top-row">
            <Typography.Title level={3} className="title" ellipsis={{ tooltip: true }}>
              {t('operativeSession.viewStatistics')}
            </Typography.Title>

            <ContextMenu
              isFullscreen={isFullScreen}
              onMenuItemClick={onClick}
              items={[
                { key: GridItemActions.Edit, label: t('contextMenu.edit') },
                {
                  key: GridItemActions.FullScreen,
                  label: t('contextMenu.fullscreen'),
                  icon: isFullScreen ? <CheckOutlined /> : null,
                },
                { key: GridItemActions.Download, label: t('contextMenu.generateReport') },
                !isFullScreen && { key: GridItemActions.Close, label: t('contextMenu.close') },
              ]}
            />
          </div>
        </div>

        <div className="frame-content">
          <div className="statistic-table">
            <StatisticsDetail data={indicatorData} selectedAssets={selectedAssets} />
          </div>
        </div>
      </div>
    </section>
  )

  return panelContent
}

export default Statistics
