import React from 'react'
import { Dropdown, Menu, Popover } from 'antd'
import { ExtendedDataNode, TransferType } from 'types/ui'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { getMainItem, getAllAssets, getAllPortfolios } from 'helpers/transfer/itemRemoveHelper'
import { AssetStatus, TransferContext } from 'types/enums'
import * as Store from 'types/store'
import colors from 'config/colors'

interface Props {
  node: ExtendedDataNode
  onClick: (node: ExtendedDataNode, type: TransferType) => void
  context: TransferContext
}

const RemoveStrategy: React.FC<Props> = ({ node, onClick, context }) => {
  const { t } = useTranslation()

  const items1 = [getMainItem(node), getAllPortfolios(node), getAllAssets(node)].filter((el) => el !== null)
  const items2 = [getAllAssets(node)].filter((el) => el !== null)
  const items = context === TransferContext.All ? items1 : items2
  const trigger: any =
    context === TransferContext.AtSessionLaunch ? ['contextMenu'] : ['click', 'contextMenu']

  const isBroken =
    node.children.length > 0 &&
    node.children.some(
      (portfolio) =>
        portfolio.children.length > 0 &&
        portfolio.children.some((asset) => (asset.resource as Store.Asset).status === AssetStatus.Broken),
    )

  const DropdownMenu = (
    <Menu>
      {items.map((item) => (
        <Menu.Item
          key={item.key}
          onClick={() => {
            onClick(node, item.transferType)
          }}
        >
          {t(item.label)}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={DropdownMenu} trigger={trigger} getPopupContainer={(trigger) => trigger.parentElement}>
      <span className={node.selected && 'selected-item'}>
        {node.selected && <CheckOutlined style={{ marginRight: 5 }} />}
        {isBroken && (
          <Popover content={t('error.selectedBrokenAsset')} trigger="hover">
            <ExclamationCircleOutlined style={{ color: colors.red }} className="mr-5" />
          </Popover>
        )}
        {node.title}
      </span>
    </Dropdown>
  )
}

export default RemoveStrategy
