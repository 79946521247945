import React, { useEffect, useState } from 'react'
import * as Api from 'types/api'
import * as api from 'api/assets'
import { jsonToBrokerAccounts } from 'services/store/mapService'
import BrokerAccount from 'components/StrategyViewer/forms/AssetForm/BrokerAccount'
import { isApiError } from 'core'
import * as Store from 'types/store'

const getBrokerAccounts = async (broker: string): Promise<Store.BrokerAccount[]> => {
  const response = await api.getBrokerAccounts(broker)
  if (isApiError(response)) {
    return null
  }

  const masterSecurityResponse = response as Api.BrokerAccountResponse

  return jsonToBrokerAccounts(masterSecurityResponse.data.application_broker_account)
}

const BrokerAccountContainer: React.FC = () => {
  const [data, setData] = useState<Store.BrokerAccount[]>([])
  const [broker, setBroker] = useState('')

  useEffect(() => {
    const getData = async () => {
      const data = await getBrokerAccounts(broker)
      if (data) {
        setData(data)
      }
    }
    getData()
  }, [broker])

  const checkAndSetBroker = (newBroker) => {
    if (newBroker != broker) {
      setBroker(newBroker)
    }
  }

  return <BrokerAccount data={data} setBroker={checkAndSetBroker} />
}

export default BrokerAccountContainer
